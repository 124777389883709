import axios from 'axios';
import router from '@/router';

export default {
  state: {
    code: null,
    password: {
      type: 'text',
      name: "password",
      value: null,
      error: null,
      status: 'default',
    },
    confirm: {
      type: 'text',
      name: "confirm",
      value: null,
      error: null,
      status: 'default',
    },
    validation: false,
  },
  getters: {
    getResetPassword: state => state.password,
    getResetConfirm: state => state.confirm,
    getResetValidation: state => state.validation,
  },
  mutations: {
    handleResetChanges(state, payload) {
      if (payload.event === 'change' || payload.event === 'paste' || payload.event === 'input') {
        let module = {
          state: state,
          payload: payload,
        }

        this.commit("handleValidation", module);
      }
    },
    setResetCode(state, payload) {
      state.code = payload;
    }
  },
  actions: {
    sendResetChanges(context) {
      context.commit("showPreloader");

      axios
        .post("https://api.airwet.app/api/auth/reset-password", {
          code: context.state.code,
          password: context.state.password.value,
          passwordConfirmation: context.state.confirm.value,
        })
        .then((response) => {
          context.commit("hidePreloader");

          if (response.status === 200) {
            router.push("/login");

            let payload = {
              type: "password",
            };

            this.commit("createPopup", payload);
          }
        })
        .catch((error) => context.commit("handleError", error));
    }
  },
}