<template>
  <input
    :class="{
      singleinput: true,
      singleinput_valid: this.status === 'valid',
      singleinput_invalid: this.status === 'invalid',
    }"
    v-bind:status="status"
    type="tel"
    @focus="focusHandler"
  />
</template>

<script>
  export default {
    name: "Singleinput",
    props: {
      status: String,
    },
    methods: {
      focusHandler(event) {
        event.target.value = "";
      },
    },
  };
</script>

<style lang="scss">
  .singleinput {
    height: 36px;
    width: 36px;
    font-family: "Avenir";
    font-size: 12px;
    background: rgba(0, 0, 0, 0.0001);
    border: 1px solid transparent;
    border-radius: 6px;
    background-color: $colorMainLight;
    box-shadow: $shadowLight;
    color: #222325;
    text-align: center;
    -webkit-text-fill-color: #222325;

    &:disabled {
      border: 1px solid #dcdcdc;
      box-shadow: none;
      -webkit-text-fill-color: #222325;
      opacity: 1;
    }

    &:focus {
      background-color: #fff;
    }

    &::placeholder {
      color: #9b9b9b;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      background-color: $colorMainLight;
      box-shadow: $shadowLight;
    }

    &_valid {
      border-color: #41c6eb;
    }

    &_invalid {
      border-color: #ff8068;
    }
  }
</style>
