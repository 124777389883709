<template>
  <div class="system__tasks">
    <div class="system__tasks-data">
      <perfect-scrollbar>
        <table class="system__tasks-table">
          <thead class="system__tasks-thead">
            <tr class="system__tasks-row">
              <th class="system__tasks-th">
                <div class="system__tasks-wrapper">
                  <div class="system__tasks-content">
                    Срок
                    <button class="system__tasks-sort">
                      <span class="system__tasks-up"></span>
                      <span class="system__tasks-down"></span>
                    </button>
                  </div>
                </div>
              </th>
              <th class="system__tasks-th">
                <div class="system__tasks-wrapper">
                  <div class="system__tasks-content">Номер задачи</div>
                </div>
              </th>
              <th class="system__tasks-th">
                <div class="system__tasks-wrapper">
                  <div class="dropdown">
                    <div class="dropdown__field">Тип задачи</div>
                    <div class="dropdown__placeholder" style="display: none">
                      Тип задачи
                    </div>
                    <ul class="dropdown__list" style="display: none"></ul>
                    <button class="dropdown__button">
                      <span class="dropdown__icon"></span>
                    </button>
                  </div>
                </div>
              </th>
              <th class="system__tasks-th">
                <div class="system__tasks-wrapper">
                  <div class="system__tasks-content">Комментарий</div>
                </div>
              </th>
              <th class="system__tasks-th">
                <div class="system__tasks-wrapper">
                  <div class="system__tasks-content">
                    Исполнитель
                    <button class="system__tasks-sort">
                      <span class="system__tasks-up"></span
                      ><span class="system__tasks-down"></span>
                    </button>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="system__tasks-tbody">
            <tr
              class="system__tasks-row"
              v-for="task in tasks"
              :id="task.id"
              :key="task.id"
            >
              <td class="system__tasks-td">
                <div class="system__tasks-wrapper">{{ task.period }}</div>
              </td>
              <td class="system__tasks-td">
                <div class="system__tasks-wrapper">{{ task.number }}</div>
              </td>
              <td class="system__tasks-td">
                <div class="system__tasks-wrapper">{{ task.type }}</div>
              </td>
              <td class="system__tasks-td">
                <div class="system__tasks-wrapper">
                  {{ task.comment }}
                </div>
              </td>
              <td class="system__tasks-td">
                <div class="system__tasks-wrapper">Иванов Иван</div>
              </td>
            </tr>
          </tbody>
        </table>
      </perfect-scrollbar>
    </div>
    <Pagination @click="handlePagination" v-if="false" />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  import Pagination from "../layout/Pagination";

  export default {
    name: "SystemTasks",
    components: {
      Pagination,
    },
    computed: {
      ...mapGetters({
        tasks: "getSystemTasks",
      }),
    },
    methods: {
      ...mapActions([
        "fetchSystemTasks",
      ]),
      handlePagination(event) {
        if (
          event.target.closest(".pagination__button") ||
          event.target.closest(".pagination__page")
        ) {
          this.fetchSystemTasks();
        }
      },
    },
    created() {
      this.fetchSystemTasks();
    },
  };
</script>

<style lang="scss" scoped>
  .system {
    &__tasks {
      &-data {
        @include base;
      }

      .ps {
        width: 100%;
        padding: 0 10px 10px;
      }

      &-table {
        width: 100%;
        min-width: 1000px;
        border-collapse: collapse;
      }

      &-thead {
        .system__tasks-wrapper {
          min-height: 36px;
          border-right: 0;
        }
      }

      &-tbody {
        .system__tasks-row {
          &:hover {
            cursor: pointer;
            outline: 2px solid #41c6eb;
          }
        }
      }

      &-row {
        background-color: $colorMainLight;
      }

      &-th,
      &-td {
        padding: 8px 0;
        border-top: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        text-align: left;

        &:last-of-type {
          .system__tasks-wrapper {
            border-right: 0;
          }
        }
      }

      &-th {
        border: 0;
        padding: 10px 5px;

        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
        }
      }

      &-td {
        .system__tasks-wrapper {
          padding-left: 19px;
        }

        &:first-of-type {
          .system__tasks-wrapper {
            padding-left: 15px;
          }
        }
      }

      &-wrapper {
        border-right: 1px solid #dddddd;
        font-weight: 400;
        font-size: 12px;
        color: #222325;
        display: flex;
        align-items: center;
      }

      &-content {
        @include base;

        padding: 7px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 36px;
        width: 100%;
        text-align: center;
      }

      &-sort {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 15px;
        width: 11px;
        cursor: pointer;
        margin-left: 20px;
      }

      &-up {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 7px;
        height: 7px;
        border: solid #bcbcbc;
        border-width: 1.5px 0 0 1.5px;
        transform: rotate(45deg);
      }

      &-down {
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 7px;
        height: 7px;
        border: solid #bcbcbc;
        border-width: 1.5px 0 0 1.5px;
        transform: rotate(225deg);
      }
    }
  }
</style>