export default {
  state: {
    systems: [{
        id: 1,
        params: [{
            id: 1,
            title: 'Объект',
            value: 'Дом',
          },
          {
            id: 2,
            title: 'Период',
            value: 'Весь период',
          },
          {
            id: 3,
            title: 'Расход воды',
            value: '231.188',
            unit: 'Литр',
          },
          {
            id: 4,
            title: 'Расход Электроэнергии',
            value: '780.301',
            unit: 'КВт',
          },
        ],
        chart: {
          series: [{
              name: "Расход воды",
              data: [28, 29, 33, 22, 38, 32, 33, 28, 18, 30, 32, 29],
            },
            {
              name: "Расход электроэнергии",
              data: [89, 66, 69, 76, 71, 90, 87, 95, 92, 78, 90, 88],
            },
          ],
          options: {
            chart: {
              fontFamily: "",
              height: 350,
              type: "line",
              dropShadow: {
                enabled: true,
                color: "#000",
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2,
              },
              toolbar: {
                show: false,
              },
              animations: {
                enabled: false,
                easing: "easeinout",
                speed: 200,
                animateGradually: {
                  enabled: false,
                  delay: 400,
                },
                dynamicAnimation: {
                  enabled: true,
                  speed: 350,
                },
              },
            },
            colors: ["#41c6eb", "#fd8008"],
            stroke: {
              curve: "smooth",
              width: 2,
            },
            title: {
              align: "left",
            },
            grid: {
              borderColor: "#e7e7e7",
              row: {
                colors: ["transparent"],
                opacity: 0.5,
              },
            },
            markers: {
              size: 4,
            },
            xaxis: {
              categories: [
                "Январь",
                "Февраль",
                "Март",
                "Апрель",
                "Май",
                "Июнь",
                "Июль",
                "Август",
                "Сентябрь",
                "Октябрь",
                "Ноябрь",
                "Декабрь",
              ],
            },
            yaxis: {
              min: 5,
              max: 100,
            },
            legend: {
              position: "top",
              horizontalAlign: "left",
              floating: true,
              offsetY: 0,
              offsetX: -10,
            },
          },
        },
      },
      {
        id: 2,
        params: [{
            id: 1,
            title: 'Объект',
            value: 'Офис',
          },
          {
            id: 2,
            title: 'Период',
            value: 'Весь период',
          },
          {
            id: 3,
            title: 'Расход воды',
            value: '23.34',
            unit: 'Литр',
          },
          {
            id: 4,
            title: 'Расход Электроэнергии',
            value: '120.2',
            unit: 'КВт',
          },
        ],
        chart: {
          series: [{
              name: "Расход воды",
              data: [28, 29, 33, 22, 38, 32, 33, 28, 18, 30, 32, 29],
            },
            {
              name: "Расход электроэнергии",
              data: [89, 66, 69, 76, 71, 90, 87, 95, 92, 78, 90, 88],
            },
          ],
          options: {
            chart: {
              fontFamily: "",
              height: 350,
              type: "line",
              dropShadow: {
                enabled: true,
                color: "#000",
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2,
              },
              toolbar: {
                show: false,
              },
              animations: {
                enabled: false,
                easing: "easeinout",
                speed: 200,
                animateGradually: {
                  enabled: false,
                  delay: 400,
                },
                dynamicAnimation: {
                  enabled: true,
                  speed: 350,
                },
              },
            },
            colors: ["#41c6eb", "#fd8008"],
            stroke: {
              curve: "smooth",
              width: 2,
            },
            title: {
              align: "left",
            },
            grid: {
              borderColor: "#e7e7e7",
              row: {
                colors: ["transparent"],
                opacity: 0.5,
              },
            },
            markers: {
              size: 4,
            },
            xaxis: {
              categories: [
                "Январь",
                "Февраль",
                "Март",
                "Апрель",
                "Май",
                "Июнь",
                "Июль",
                "Август",
                "Сентябрь",
                "Октябрь",
                "Ноябрь",
                "Декабрь",
              ],
            },
            yaxis: {
              min: 5,
              max: 100,
            },
            legend: {
              position: "top",
              horizontalAlign: "left",
              floating: true,
              offsetY: 0,
              offsetX: -10,
            },
          },
        },
      },
    ],
    blocks: [{
        id: 1,
        type: 'simple',
        name: 'system',
        title: 'Выбрать объект',
        value: '',
        status: 'default',
        error: false,
        errorMessage: 'Неверный формат',
        categories: [{
          id: 1,
          name: '1',
          title: 'Дом'
        }, {
          id: 2,
          name: '2',
          title: 'Офис'
        }, ],
      },
      {
        id: 2,
        type: 'simple',
        name: 'period',
        title: 'Выбрать период',
        value: '',
        status: 'default',
        error: false,
        errorMessage: 'Неверный формат',
        categories: [{
          id: 1,
          name: '1',
          title: 'Весь период'
        }, {
          id: 2,
          name: '2',
          title: '6 месяцев'
        }],
      },
      {
        id: 3,
        type: 'simple',
        name: 'params',
        title: 'Выбрать тип показателей',
        value: '',
        status: 'default',
        error: false,
        errorMessage: 'Неверный формат',
        categories: [{
          id: 1,
          name: '1',
          title: 'Все показатели'
        }, {
          id: 2,
          name: '2',
          title: 'Расход воды'
        }, {
          id: 3,
          name: '3',
          title: 'Расход Электроэнергии'
        }, ],
      },
    ],
    activeSystem: {
      id: 1,
      params: [{
          id: 1,
          title: 'Объект',
          value: 'Дом',
          unit: null,
        },
        {
          id: 2,
          title: 'Период',
          value: 'Весь период',
          unit: null,
        },
        {
          id: 3,
          title: 'Расход воды',
          value: '231.188',
          unit: 'Литр',
        },
        {
          id: 4,
          title: 'Расход Электроэнергии',
          value: '780.301',
          unit: 'КВт',
        },
      ],
      chart: {
        series: [{
            name: "Расход воды",
            data: [28, 29, 33, 22, 38, 32, 33, 28, 18, 30, 32, 29],
          },
          {
            name: "Расход электроэнергии",
            data: [89, 66, 69, 76, 71, 90, 87, 95, 92, 78, 90, 88],
          },
        ],
        options: {
          chart: {
            fontFamily: "",
            height: 350,
            type: "line",
            dropShadow: {
              enabled: true,
              color: "#000",
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2,
            },
            toolbar: {
              show: false,
            },
            animations: {
              enabled: false,
              easing: "easeinout",
              speed: 200,
              animateGradually: {
                enabled: false,
                delay: 400,
              },
              dynamicAnimation: {
                enabled: true,
                speed: 350,
              },
            },
          },
          colors: ["#41c6eb", "#fd8008"],
          stroke: {
            curve: "smooth",
            width: 2,
          },
          title: {
            align: "left",
          },
          grid: {
            borderColor: "#e7e7e7",
            row: {
              colors: ["transparent"],
              opacity: 0.5,
            },
          },
          markers: {
            size: 4,
          },
          xaxis: {
            categories: [
              "Январь",
              "Февраль",
              "Март",
              "Апрель",
              "Май",
              "Июнь",
              "Июль",
              "Август",
              "Сентябрь",
              "Октябрь",
              "Ноябрь",
              "Декабрь",
            ],
          },
          yaxis: {
            min: 5,
            max: 100,
          },
          legend: {
            position: "top",
            horizontalAlign: "left",
            floating: true,
            offsetY: 0,
            offsetX: -10,
          },
        },
      },
    },
    prepareSystem: {
      params: [],
      series: 'all',
    },
    validation: false,
  },
  getters: {
    getReportsBlocks: state => state.blocks,
    getReportsParams: state => state.activeSystem.params,
    getReportsChart: state => state.activeSystem.chart,
    getReportsValidation: state => state.validation,
  },
  mutations: {
    handleReportsHeader(state, payload) {
      if (payload.param === 'generate') {
        state.activeSystem.params = [...state.prepareSystem.params];

        if (state.prepareSystem.series === 'water') {
          payload.chart.showSeries("Расход воды");
          payload.chart.hideSeries("Расход электроэнергии");
        }

        if (state.prepareSystem.series === 'energy') {
          payload.chart.hideSeries("Расход воды");
          payload.chart.showSeries("Расход электроэнергии");
        }

        if (state.prepareSystem.series === 'all') {
          payload.chart.showSeries("Расход воды");
          payload.chart.showSeries("Расход электроэнергии");
        }

        state.blocks.forEach(block => {
          block.value = '';
          block.status = 'default';
          state.validation = false;
        });
      } else {
        state.blocks.forEach(block => {
          if (block.name == payload.param) {
            block.categories.forEach(category => {
              if (category.name == payload.name) {
                block.value = category.title;

                if (payload.value !== '') {
                  block.status = 'valid';
                  block.error = false;
                } else {
                  block.status = 'invalid';
                  block.error = true;
                }
              }
            });
          }
        });

        if (payload.param === 'system') {
          let obj = {
            id: payload.id,
            title: 'Объект',
            value: payload.value,
            unit: null,
          };

          state.prepareSystem.params[0] = obj;
        }

        if (payload.param === 'period') {
          let obj = {
            id: payload.id,
            title: 'Период',
            value: payload.value,
            unit: null,
          };

          state.prepareSystem.params[1] = obj;
        }

        if (payload.param === 'params') {
          if (payload.name == 1) {
            let obj1 = {
              id: 3,
              title: 'Расход воды',
              value: '231.188',
              unit: 'Литр',
            };

            let obj2 = {
              id: 4,
              title: 'Расход Электроэнергии',
              value: '780.301',
              unit: 'КВт',
            };

            state.prepareSystem.params[2] = obj1;
            state.prepareSystem.params[3] = obj2;
            state.prepareSystem.series = 'all';
          }

          if (payload.name == 2) {
            let obj = {
              id: 3,
              title: 'Расход воды',
              value: '231.188',
              unit: 'Литр',
            };

            state.prepareSystem.params[2] = obj;
            state.prepareSystem.series = 'water';
          }

          if (payload.name == 3) {
            let obj = {
              id: 3,
              title: 'Расход электроэнергии',
              value: '780.301',
              unit: 'КВт',
            };

            state.prepareSystem.params[2] = obj;
            state.prepareSystem.series = 'energy';
          }
        }
      }

      state.blocks.forEach(block => {
        if (block.status === 'valid') {
          state.validation = true
        } else state.validation = false;
      });
    },
  },
}