<template>
  <div
    :class="{
      picker: true,
      picker_valid: this.status === 'valid',
      picker_invalid: this.status === 'invalid',
    }"
    :data-paint="paint"
  >
    <div class="picker__field" v-on:click="toggleList">{{ value }}</div>
    <ul class="picker__list" v-show="listVisible">
      <template v-if="period === 'month'">
        <li
          class="picker__item"
          v-for="item in months"
          :key="item.id"
          v-on:click="selectItem"
        >
          {{ item.value }}
        </li>
      </template>
      <template v-if="period === 'year'">
        <li
          class="picker__item"
          v-for="item in years"
          :key="item.id"
          v-on:click="selectItem"
        >
          {{ item.value }}
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "Picker",
    props: {
      type: String,
      paint: String,
      status: String,
      period: String,
      value: String,
    },
    data() {
      return {
        listVisible: false,
        className: {
          common: "picker__icon",
          active: "picker__icon picker__icon_rotate",
        },
        months: [
          {
            id: 1,
            value: "01",
          },
          {
            id: 2,
            value: "02",
          },
          {
            id: 3,
            value: "03",
          },
          {
            id: 4,
            value: "04",
          },
          {
            id: 5,
            value: "05",
          },
          {
            id: 6,
            value: "06",
          },
          {
            id: 7,
            value: "07",
          },
          {
            id: 8,
            value: "08",
          },
          {
            id: 9,
            value: "09",
          },
          {
            id: 10,
            value: "10",
          },
          {
            id: 11,
            value: "11",
          },
          {
            id: 12,
            value: "12",
          },
        ],
        years: [
          {
            id: 1,
            value: new Date().getFullYear(),
          },
          {
            id: 2,
            value: new Date().getFullYear() + 1,
          },
          {
            id: 3,
            value: new Date().getFullYear() + 2,
          },
          {
            id: 4,
            value: new Date().getFullYear() + 3,
          },
        ],
      };
    },
    methods: {
      toggleList() {
        this.listVisible = !this.listVisible;
      },
      selectItem() {
        this.listVisible = false;
      },
    },
  };
</script>

<style lang="scss">
  .picker {
    width: 100%;
    height: 36px;
    position: relative;
    border: 1px solid transparent;
    border-radius: 6px;
    box-shadow: $shadowLight;
    background-color: $colorMainLight;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_valid {
      border-color: #41c6eb;
    }

    &_invalid {
      border-color: #ff8068;
    }

    &[data-paint="blue"] {
      .picker__field-text {
        color: #41c6eb;
        font-weight: 500;
      }
    }

    &[data-paint="orange"] {
      .picker__field-text {
        color: #f5b142;
        font-weight: 500;
      }
    }

    &__field {
      width: 100%;
      cursor: pointer;
      z-index: $zIndexPicker - 10;
      font-size: 12px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 5px;
    }

    &__list {
      position: absolute;
      top: 23px;
      left: -1px;
      margin: 0;
      padding: 0 13px;
      list-style: none;
      width: calc(100% + 2px);
      background-color: $colorMainLight;
      z-index: $zIndexPicker + 10;
      box-shadow: 4px 4px 4px #dcdcdc, -4px 4px 4px #f7f7f9eb;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      padding-top: 10px;
      height: 100px;
      overflow-y: scroll;

      &_invisible {
        display: none;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-bottom: 1px solid #d8d8d8;
      padding: 8px 0;
      font-size: 12px;
      color: #2b1a0a;
      text-transform: uppercase;

      &:hover {
        color: #41c6eb;
      }

      &:first-of-type {
        border-top: 1px solid #d8d8d8;
      }

      &:last-of-type {
        border-bottom: 0;
      }
    }

    &__wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .picker__title {
        font-weight: 500;
      }
    }
  }
</style>
