<template>
  <label class="chevron" :data-orient="orient">
    <span class="chevron__checkmark"></span>
  </label>
</template>

<script>
  export default {
    name: "Chevron",
    props: {
      orient: String,
    },
  };
</script>

<style lang="scss">
  .chevron {
    position: relative;
    background: none;
    padding: 0;
    border: 0;
    height: 20px;
    width: 20px;
    cursor: pointer;

    &[data-orient="down"] {
      .chevron__checkmark {
        border-color: #bcbcbc;
        border-style: solid;
        top: 2px;
        transform: rotate(45deg);
      }
    }

    &[data-orient="up"] {
      .chevron__checkmark {
        border-color: #222325;
        border-style: solid;
        top: 8px;
        transform: rotate(225deg);
      }
    }

    &__checkmark {
      position: absolute;
      left: 4px;
      width: 12px;
      height: 12px;
      border-width: 0 2px 2px 0;
    }
  }
</style>
