<template>
  <div class="viewer" v-if="viewer.visible">
    <div class="viewer__window">
      <div class="viewer__header">
        <div class="viewer__header-name">{{ viewer.name }}</div>
        <div class="viewer__header-control">
          <Button
            :type="'text'"
            :value="'Закрыть'"
            :paint="'gray'"
            @click="handleClick"
          />
          <Button
            :type="'text'"
            :value="'Скачать'"
            :paint="'blue'"
            @click="handleClick"
          />
        </div>
      </div>
      <div class="viewer__body"></div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from "vuex";
  import Button from "../components/Button";
  export default {
    name: "Viewer",
    components: {
      Button,
    },
    computed: {
      ...mapGetters({
        viewer: "getCommonViewer",
      }),
    },
    methods: {
      ...mapMutations(["destroyViewer"]),
      handleClick() {
        this.destroyViewer();
        document.body.style.overflow = "auto";
      },
    },
  };
</script>

<style lang="scss" scoped>
  .viewer {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(240, 240, 240, 0.8);
    z-index: $zIndexViewer;
    opacity: 1;

    &__window {
      position: relative;
      top: 50px;
      width: 800px;
      margin: 0 auto;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #bcbcbc;
      padding-bottom: 20px;

      &-name {
        font-weight: 500;
        font-size: 14px;
        color: #2b1a0a;
      }

      &-control {
        display: flex;

        button {
          &:last-of-type {
            margin-left: 10px;
          }
        }
      }
    }

    &__body {
      font-size: 14px;
      margin: 20px 0;
      color: #2b1a0a;
      border: 1px solid #bcbcbc;
      background-color: $colorMainLight;
      text-align: center;
      height: calc(100vh - 150px);
      overflow-y: scroll;
    }

    &__image {
      width: 100%;
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
</style>
