import axios from "axios";
import router from "@/router";

export default {
	state: {
		position: {
			type: "simple",
			name: "position",
			alias: null,
			value: null,
			status: "default",
			error: false,
			errorMessage: "Неверный формат",
			categories: [
				{
					id: 1,
					name: "engineer",
					title: "Инженер",
				},
				{
					id: 2,
					name: "manager",
					title: "Менеджер",
				},
			],
		},
		lastName: {
			name: "lastName",
			value: null,
			pattern: /^[а-яА-ЯёЁ]{1,30}$/,
			status: "default",
			error: false,
			errorMessage: "Неверный формат",
			type: "text",
		},
		firstName: {
			name: "firstName",
			value: null,
			pattern: /^[а-яА-ЯёЁ]{1,30}$/,
			status: "default",
			error: false,
			errorMessage: "Неверный формат",
			type: "text",
		},
		middleName: {
			name: "middleName",
			value: null,
			pattern: /^[а-яА-ЯёЁ]{1,30}$/,
			status: "default",
			error: false,
			errorMessage: "Неверный формат",
			type: "text",
		},
		password: {
			name: "password",
			value: null,
			pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,
			status: "default",
			error: false,
			errorMessage: "Не менее 6 символов, строчные и заглавные буквы, цифры",
			type: "text",
		},
		confirm: {
			name: "confirm",
			value: null,
			pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,
			status: "default",
			error: false,
			errorMessage: "Не менее 6 символов, строчные и заглавные буквы, цифры",
			type: "text",
		},
		validation: false,
	},
	getters: {
		getExtendPosition: (state) => state.position,
		getExtendLastName: (state) => state.lastName,
		getExtendFirstName: (state) => state.firstName,
		getExtendMiddleName: (state) => state.middleName,
		getExtendPassword: (state) => state.password,
		getExtendConfirm: (state) => state.confirm,
		getExtendValidation: (state) => state.validation,
	},
	mutations: {
		handleExtendChanges(state, payload) {
			if (payload.event === "change" || payload.event === "paste" || payload.event === "input") {
				if (payload.element === "dropdown") {
					state[payload.name].value = payload.value;
					state[payload.name].alias = payload.alias;
				}
				state[payload.name].value = payload.value;

				if (payload.name === "confirm") {
					if (payload.value.match(state[payload.name].pattern) && payload.value === state.password.value) {
						state[payload.name].status = "valid";
						state[payload.name].error = false;
					} else {
						state[payload.name].status = "invalid";
						state[payload.name].error = true;
						state[payload.name].errorMessage = "Пароли не совпадают";
					}
				} else {
					if (payload.value.match(state[payload.name].pattern)) {
						state[payload.name].status = "valid";
						state[payload.name].error = false;
					} else {
						state[payload.name].status = "invalid";
						state[payload.name].error = true;
					}
				}

				state.validation = true;

				if (
					state.lastName.status !== "valid" ||
					state.firstName.status !== "valid" ||
					state.middleName.status !== "valid" ||
					state.password.status !== "valid" ||
					state.confirm.status !== "valid"
				) {
					state.validation = false;
				}
			}
		},
	},
	actions: {
		sendExtendInfo(context) {
			let acronym = "";

			if (context.state.firstName.value !== null && context.state.lastName.value !== null) {
				acronym = `${context.state.firstName.value.substr(0, 1)}${context.state.lastName.value.substr(0, 1)}`;
			}

			context.commit("showPreloader");

			axios
				.post("https://api.airwet.app/api/auth/local/register", {
					username: localStorage.getItem("register__email-value").substring(0, localStorage.getItem("register__email-value").indexOf("@")),
					email: localStorage.getItem("register__email-value"),
					phone: localStorage.getItem("register__phone-value"),
					acronym: acronym,
					position: context.state.position.alias,
					last_name: context.state.lastName.value,
					first_name: context.state.firstName.value,
					middle_name: context.state.middleName.value,
					password: context.state.confirm.value,
				})
				.then((response) => {
					context.commit("hidePreloader");

					if (response.status === 200) {
						localStorage.removeItem("register__email-value");
						localStorage.removeItem("register__phone-value");
						router.push("/login");

						let payload = {
							type: "register",
						};

						this.commit("createPopup", payload);
					}
				})
				.catch((error) => {
					context.commit("handleError", error);
				});
		},
	},
};
