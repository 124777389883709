<template>
	<button class="button" :title="title" :data-style="style" :data-paint="paint" :data-action="action">
		<img v-if="type === 'icon'" class="button__icon" :src="require(`../assets/images/${value}.svg`)" />
		<template v-if="type === 'action' && value === 'add'">
			<span class="button__action" data-color="blue">
				<font-awesome-icon icon="fa-plus" />
			</span>
		</template>
		<template v-if="type === 'action' && value === 'delete'">
			<span class="button__action" data-color="red">
				<font-awesome-icon icon="fa-xmark" />
			</span>
		</template>
		<template v-if="type === 'action' && value === 'download'">
			<span class="button__action" data-color="grey">
				<font-awesome-icon icon="fa-arrows-down-to-line" />
			</span>
		</template>
		<template v-if="type === 'action' && value === 'upload'">
			<span class="button__action" data-color="grey">
				<font-awesome-icon icon="fa-arrows-up-to-line" />
			</span>
		</template>
		<template v-if="type === 'action' && value === 'edit'">
			<span class="button__action" data-color="grey">
				<font-awesome-icon icon="fa-pen-to-square" />
			</span>
		</template>
		<template v-if="type === 'action' && value === 'reset'">
			<span class="button__action" data-color="grey">
				<font-awesome-icon icon="fa-arrows-rotate" />
			</span>
		</template>
		<span class="button__text" v-if="type === 'text'">{{ value }}</span>
	</button>
</template>

<script>
export default {
	name: "Button",
	props: {
		value: String,
		type: String,
		title: String,
		style: String,
		paint: String,
		action: String,
	},
};
</script>

<style lang="scss">
.button {
	height: 36px;
	min-width: 36px;
	padding: 0;
	border-radius: 6px;
	box-shadow: $shadowLight;
	border: 0;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
	font-size: 18px;

	&[data-style="small"] {
		height: 26px;
		min-width: 26px;
		font-size: 15px;
		border-radius: 4px;
	}

	&[data-paint="gray"] {
		background-color: $colorMainLight;
		color: #222325;

		@media (hover: hover) {
			&:hover {
				background-color: #fff;
			}
		}

		&:active {
			background-color: #fff;
			box-shadow: none;
		}
	}

	&[data-paint="blue"] {
		background-color: #41c6eb;
		color: #fff;

		@media (hover: hover) {
			&:hover {
				background-color: #2bb1d6;
			}
		}

		&:active {
			background-color: #2bb1d6;
			box-shadow: none;
		}

		&:disabled {
			background-color: $colorMainLight;
			cursor: auto;
			pointer-events: none;
			color: #9b9b9b;

			.button__action {
				opacity: 0.5;
			}
		}
	}

	&:disabled {
		cursor: auto;
		pointer-events: none;
		color: #9b9b9b;

		.button__action {
			opacity: 0.5;
		}
	}

	&__icon {
		padding: 0 4px;
		width: 25px;
	}

	&__action {
		&[data-color="blue"] {
			color: #41c6eb;
		}

		&[data-color="red"] {
			color: #ff8168;
		}

		&[data-color="grey"] {
			color: #787878;
		}
	}

	&__text {
		min-width: 134px;
		padding: 0 28px;
		font-family: "Avenir";
		font-size: 12px;
	}
}
</style>
