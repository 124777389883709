<template>
	<div
		:class="{
			timepicker: true,
			timepicker_valid: this.status === 'valid',
			timepicker_invalid: this.status === 'invalid',
		}"
		:data-paint="paint"
		v-click-outside="hideList"
	>
		<div class="timepicker__field" v-on:click="toggleList">
			<span class="timepicker__hour">{{ hour }}</span>
			:
			<span class="timepicker__minute">{{ minute }}</span>
		</div>
		<ul v-if="current === 'hours'" class="timepicker__list" v-show="listVisible" data-unit="hour">
			<perfect-scrollbar>
				<li class="timepicker__item" v-for="item in hours" :key="item.id" v-on:click="selectItem">
					{{ item.title }}
				</li>
			</perfect-scrollbar>
		</ul>
		<ul v-if="current === 'minutes'" class="timepicker__list" v-show="listVisible" data-unit="minute">
			<perfect-scrollbar>
				<li class="timepicker__item" v-for="item in minutes" :key="item.id" v-on:click="selectItem">
					{{ item.title }}
				</li>
			</perfect-scrollbar>
		</ul>
	</div>
</template>

<script>
export default {
	name: "Timepicker",
	props: {
		type: String,
		paint: String,
		status: String,
		hour: String,
		minute: String,
	},
	data() {
		return {
			listVisible: false,
			className: {
				common: "timepicker__icon",
				active: "timepicker__icon timepicker__icon_rotate",
			},
			hours: [],
			minutes: [],
			current: "minutes",
		};
	},
	methods: {
		toggleList(event) {
			if (event.target.closest(".timepicker__hour")) {
				this.current = "hours";
			} else if (event.target.closest(".timepicker__minute")) {
				this.current = "minutes";
			} else return;

			this.listVisible = !this.listVisible;
		},
		hideList() {
			this.listVisible = false;
		},
		selectItem() {
			this.listVisible = false;
		},
	},
	mounted() {
		for (let i = 0; i < 24; i++) {
			let obj = {
				id: i,
				title: i < 10 ? `0${i}` : `${i}`,
			};

			this.hours.push(obj);
		}

		for (let i = 0; i < 60; i++) {
			let obj = {
				id: i,
				title: i < 10 ? `0${i}` : `${i}`,
			};

			this.minutes.push(obj);
		}
	},
};
</script>

<style lang="scss">
.timepicker {
	width: 100%;
	position: relative;
	border: 1px solid transparent;
	box-shadow: $shadowLight;
	border-radius: 6px;
	background-color: $colorMainLight;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&_valid {
		border-color: #41c6eb;
	}

	&_invalid {
		border-color: #ff8068;
	}

	&[data-paint="blue"] {
		.timepicker__field-text {
			color: #41c6eb;
			font-weight: 500;
		}
	}

	&[data-paint="orange"] {
		.timepicker__field-text {
			color: #f5b142;
			font-weight: 500;
		}
	}

	&__field {
		width: 100%;
		cursor: pointer;
		z-index: $zIndexTimepicker - 10;
		font-size: 12px;
		position: relative;
		display: flex;
		align-items: center;
		padding: 6px 14px;
	}

	&__hour,
	&__minute {
		&:hover {
			color: #41c6eb;
		}
	}

	&__list {
		position: absolute;
		top: 23px;
		left: -1px;
		margin: 0;
		padding: 0 5px 5px;
		list-style: none;
		width: calc(100% + 2px);
		background-color: inherit;
		z-index: $zIndexTimepicker + 10;
		box-shadow: 4px 4px 4px #dcdcdc, -4px 4px 4px #f7f7f9eb;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		padding-top: 10px;

		&[data-unit="hour"] {
			.timepicker__item {
				justify-content: center;
			}
		}

		&[data-unit="minute"] {
			.timepicker__item {
				justify-content: center;
			}
		}

		&_invisible {
			display: none;
		}
	}

	.ps {
		height: 200px;
		padding: 0;
	}

	&__item {
		display: flex;
		align-items: center;
		cursor: pointer;
		border-bottom: 1px solid #d8d8d8;
		padding: 8px 5px;
		font-size: 12px;
		color: inherit;
		text-transform: uppercase;
		text-align: center;

		&:hover {
			color: #41c6eb;
		}

		&:first-of-type {
			border-top: 1px solid #d8d8d8;
		}

		&:last-of-type {
			border-bottom: 0;
		}
	}

	&__wrapper {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.timepicker__title {
			font-weight: 500;
		}
	}
}
</style>
