import axios from "axios";

export default {
	state: {
		navbar: {},
		about: {
			extended: localStorage.getItem("objects__about-extended") === "true" ? true : false || false,
			cancellation: true,
			validation: false,
		},
		zones: [],
		backup: {},
	},
	getters: {
		getObjectsNavbar: (state) => state.navbar,
		getObjectsAbout: (state) => state.about,
		getObjectsZones: (state) => state.zones,
	},
	mutations: {
		toggleObjectsSection(state, payload) {
			state[payload].extended = !state[payload].extended;

			localStorage.setItem(`objects__${payload}-extended`, state[payload].extended);
		},
		setObjectsActiveZone(state, payload) {
			state.zones.forEach((zone, index) => {
				if (payload.id !== zone.id) {
					zone.current = false;
					zone.cancellation = true;
					zone.validation = false;
					zone.extended = false;

					if (state.backup.zones && zone.id === state.backup.zones[index].id) {
						zone.hum = state.backup.zones[index].hum;
						zone.temp = state.backup.zones[index].temp;
						zone.name = state.backup.zones[index].name;
						zone.status = "default";
						state.backup.zones[index] = {};
					}
				}
			});
			state.zones.forEach((zone) => (zone.id === payload.id ? (zone.current = true) : false));
		},
		extendObjectsZone(state, payload) {
			state.zones.forEach((zone) => {
				if (zone.id === payload.id) zone.extended = true;
			});
		},
		collapseObjectsZone(state, payload) {
			state.zones.forEach((zone) => {
				if (zone.id === payload.id) zone.extended = !payload.extended;
				if (zone.extended) zone.current = true;
			});
		},

		updateObjectsNavbar(state, payload) {
			state.navbar = payload;
		},
		updateObjectsAbout(state, payload) {
			state.about.id = payload.id;
			state.about.contract = payload.contract;
			state.about.from_user = payload.from_user;
			state.about.active_user = payload.active_user;
			state.about.active_srv = payload.active_srv;
			state.about.errors = payload.errors;
			state.about.date = payload.date;
			state.about.mileage = payload.mileage;
			state.about.pump_hours = payload.pump_hours;
			state.about.type = payload.type;
			state.about.system_type = payload.system_type;
			state.about.system_architecture = payload.system_architecture;
			state.about.name = {
				element: "input",
				name: "name",
				value: payload.name,
				pattern: /^[а-яА-ЯёЁa-zA-Z0-9\s]{2,30}$/,
				status: "default",
				error: false,
				errorText: "Неверный формат",
			};
			state.about.address = payload.address;
			state.about.date_start = payload.date_start;
			state.about.date_install = payload.date_install;
			state.about.date_warranty = payload.date_warranty;
			state.about.date_maintenance_plan = payload.date_maintenance_plan;
			state.about.documents = payload.documents;
			state.about.vacation = null;

			if (payload.modes && payload.modes.length) {
				state.about.vacation = payload.modes.find((mode) => mode.type === "vacation");
				if (state.about.vacation) state.about.vacation.changed = false;
			}

			if (payload.state === 0) {
				state.about.state = {
					value: "disabled",
					text: "выключена",
				};
			}

			if (payload.state === 1) {
				state.about.state = {
					value: "enabled",
					text: "включена",
				};
			}

			if (payload.state === 2) {
				state.about.state = {
					value: "error",
					text: "ошибка",
				};
			}

			if (payload.state === 3) {
				state.about.state = {
					value: "active",
					text: "работает",
				};
			}

			if (payload.state === 4) {
				state.about.state = {
					value: "inactive",
					text: "не работает",
				};
			}
		},
		updateObjectsZones(state, payload) {
			state.zones = JSON.parse(JSON.stringify(payload.zones));

			state.zones.forEach((zone) => {
				zone.level = "user";
				zone.system_architecture = payload.system_architecture;

				zone.name = {
					value: JSON.parse(JSON.stringify(zone.name)),
					status: "default",
				};

				zone.programs.forEach((program) => {
					program.periods.forEach((period) => {
						let start = period.start.split(":", 2);

						period.start = {
							hour: +start[0],
							minute: +start[1],
						};
					});

					let days = [
						{ num: 1, name: "ПН", activity: false },
						{ num: 2, name: "ВТ", activity: false },
						{ num: 3, name: "СР", activity: false },
						{ num: 4, name: "ЧТ", activity: false },
						{ num: 5, name: "ПТ", activity: false },
						{ num: 6, name: "СБ", activity: false },
						{ num: 7, name: "ВС", activity: false },
					];

					program.days = days.map((elem) => {
						elem.activity = false;
						let programDay = program.days.find((d) => elem.num == d);

						if (programDay) elem.activity = true;

						return elem;
					});
				});

				zone.regime = {
					extended: false,
					name: "program",
					type: "weekdays",
					list: {
						value: "Будни",
						categories: [],
					},
					channels: payload.control_channels.filter((channel) => zone[channel]),
					channel: payload.control_channels[0],
				};

				zone.regime.programs = zone.programs.filter((item) => item.channel === zone.regime.channel);
				zone.regime.program = zone.programs.find((item) => item.channel === zone.regime.channel);
				zone.regime.id = zone.regime.program.id;

				zone.regime.list.categories = zone.regime.programs.map((item) => {
					let obj = {
						id: item.id,
						name: item.type,
						title: item.name,
					};

					return obj;
				});

				zone.regime.active_srv = zone.regime.program.active_srv;

				zone.regime.periods = zone.regime.program.periods;
				zone.regime.days = zone.regime.program.days;

				zone.regime.periods.forEach((period) => {
					period.status = "default";

					if (zone.regime.channel === "hum") period.unit = "%";
					if (zone.regime.channel === "temp") period.unit = "°";
					if (zone.regime.channel === "carb") period.unit = "PPM";
				});

				payload.control_channels.forEach((channel) => {
					if (zone[channel] !== null) {
						zone[channel].status = "default";
						zone[channel].control = false;

						zone[channel].min = {
							value: zone[channel].min,
							name: "min",
							status: "default",
							limit: payload.board[`${[channel]}_min`],
						};

						zone[channel].max = {
							value: zone[channel].max,
							name: "max",
							status: "default",
							limit: payload.board[`${[channel]}_max`],
						};

						zone[channel].gys = {
							value: zone[channel].gys,
							name: "gys",
							status: "default",
							limit: payload.board[`gys_${[channel]}`],
						};

						if (channel === "hum") {
							zone.hum.unit = "%";
							zone.hum.default = 45;

							zone.hum.t_open = {
								value: zone.hum.t_open,
								name: "t_open",
								status: "default",
							};

							zone.hum.t_close = {
								value: zone.hum.t_close,
								name: "t_close",
								status: "default",
							};

							let device = {
								dropdown: {
									name: "device",
									value: null,
									alias: null,
									status: "default",
									categories: [
										{
											id: 1,
											name: "s",
											title: "Датчик",
										},
										{
											id: 2,
											name: "gt",
											title: "Гигростат с тачсрином",
										},
										{
											id: 3,
											name: "gw",
											title: "Гигростат с колесом",
										},
									],
								},
								input: {
									name: "address",
									value: null,
									pattern: /^[а-яА-ЯёЁa-zA-Z0-9?!,.\s]{2,250}$/,
									status: "default",
									error: false,
									errorMessage: "Неверный формат",
								},
								button: {
									value: "add",
									disabled: true,
								},
							};

							let nozzle = {
								dropdown: {
									name: "nozzle",
									value: null,
									alias: null,
									status: "default",
									categories: [
										{
											id: 1,
											name: "01L",
											title: "01L",
										},
										{
											id: 2,
											name: "02L",
											title: "02L",
										},
									],
								},
								input: {
									name: "quan",
									value: null,
									pattern: /^[0-9]{1,5}$/,
									status: "default",
									error: false,
									errorMessage: "Неверный формат",
								},
								button: {
									value: "add",
									disabled: true,
								},
							};

							zone.device = JSON.parse(JSON.stringify(device));
							zone.nozzle = JSON.parse(JSON.stringify(nozzle));
						}

						if (channel === "temp") {
							zone.temp.unit = "°";
							zone.temp.default = 22;
						}

						if (channel === "carb") {
							zone.carb.unit = "PPM";
							zone.carb.default = 600;
						}
					}
				});

				zone.current = false;
				zone.extended = false;
				zone.cancellation = true;
				zone.validation = false;
			});
		},

		handleObjectsChanges(state, payload) {
			function isEmpty(obj) {
				for (let key in obj) {
					return false;
				}
				return true;
			}

			if (payload.event === "change" || payload.event === "paste" || payload.event === "input") {
				if (payload.section === "about") {
					if (isEmpty(state.backup.about)) {
						state.backup.about = JSON.parse(JSON.stringify(state.about));
					}

					state.about.extended = true;
					state.about.cancellation = false;
					state.about.validation = true;

					if (payload.element === "input") {
						if (payload.name === "name") {
							state.about[payload.name].value = payload.value;
						}

						if (payload.value.match(this.state.common.patterns[payload.name])) {
							state.about[payload.name].status = "valid";
							state.about[payload.name].error = false;
						} else {
							state.about[payload.name].status = "invalid";
							state.about[payload.name].error = true;
						}
					}

					if (payload.element === "switch") {
						if (payload.name === "active_user") {
							state.about[payload.name] = payload.value;
						}
						if (payload.name === "vacation") {
							state.about[payload.name].active_user = payload.value;
							state.about[payload.name].changed = true;
						}
					}

					if (state.about.name.status === "invalid") {
						state.about.validation = false;
					}
				}
			}
		},
		handleObjectsZone(state, payload) {
			function isEmpty(obj) {
				for (let key in obj) {
					return false;
				}
				return true;
			}

			state.zones.forEach((element, index) => {
				if (element.id === payload.id) {
					element.current = true;

					if (isEmpty(state.backup.zones)) {
						state.backup.zones = JSON.parse(JSON.stringify(state.zones));
					} else if (isEmpty(state.backup.zones[index])) {
						state.backup.zones[index] = JSON.parse(JSON.stringify(state.zones[index]));
					}
				}
			});

			if (payload.event === "change" || payload.event === "input") {
				state.zones.forEach((zone, index) => {
					if (zone.id == payload.id) {
						let ignor = false;

						if (isEmpty(state.backup.zones[index])) {
							state.backup.zones[index] = JSON.parse(JSON.stringify(state.zones[index]));
						}

						if (payload.element === "input") {
							if (payload.name === "name") {
								zone[payload.name].value = payload.value;

								if (payload.value.match(/^[а-яА-ЯёЁa-zA-Z0-9\s-/\\]{2,30}$/))
									zone[payload.name].status = "valid";
								else zone[payload.name].status = "invalid";
							}
						}

						if (payload.element === "switch") {
							if (payload.name === "active_user") {
								if (payload.param !== null) {
									zone[payload.param].active_user = payload.value;

									if (zone[payload.param].active_user === false) {
										zone[payload.param].setting = zone[payload.param].default;
										zone[payload.param].status = "default";
									}
								}
							}

							if (payload.name === "regime_active_user") {
								if (payload.param !== null) {
									zone.programs.forEach((program) => {
										if (program.id === payload.regime) {
											program.changed = true;
											program.active_user = payload.value;
											zone.regime.program = program;
										}
									});

									zone.regime.active_user = zone.regime.program.active_user;
								}
							}
						}

						if (payload.element === "slider") {
							zone[payload.param][payload.name] = payload.value;
						}

						if (payload.element === "timepicker") {
							zone.programs.forEach((program) => {
								if (program.id === payload.regime) {
									program.changed = true;

									program.periods.forEach((period) => {
										if (period.name === payload.period) {
											period.start[payload.unit] = payload.value;
										}
									});

									zone.regime.periods = program.periods;
								}
							});
						}

						if (payload.element === "checkbox") {
							state.zones.forEach((zone) => {
								if (zone.id === payload.id) {
									zone.programs.forEach((program) => {
										program.changed = true;

										if (program.id === payload.regime) {
											zone.regime.days.forEach((day) => {
												if (day.num === payload.day) day.activity = payload.value;
											});
										} else {
											program.days.forEach((day) => {
												if (day.num === payload.day) day.activity = !payload.value;
											});
										}
									});
								}
							});
						}

						if (!ignor) {
							zone.cancellation = false;
							zone.validation = true;
							if (zone.name.status === "invalid") zone.validation = false;

							if (zone.hum) {
								if (
									zone.hum.status === "invalid" ||
									zone.hum.min.status === "invalid" ||
									zone.hum.max.status === "invalid" ||
									zone.hum.t_open.status === "invalid" ||
									zone.hum.t_close.status === "invalid"
								) {
									zone.validation = false;
								}
							}

							if (zone.temp) {
								if (
									zone.temp.status === "invalid" ||
									zone.temp.min.status === "invalid" ||
									zone.temp.max.status === "invalid"
								) {
									zone.validation = false;
								}
							}

							if (zone.carb) {
								if (
									zone.carb.status === "invalid" ||
									zone.carb.min.status === "invalid" ||
									zone.carb.max.status === "invalid"
								) {
									zone.validation = false;
								}
							}
						}
					}
				});
			}

			if (payload.event === "click") {
				if (payload.element === "tab") {
					state.zones.forEach((zone) => {
						if (zone.id === payload.id) {
							zone.programs.forEach((program) => {
								if (program.channel === payload.channel && program.type === zone.regime.type) {
									zone.regime.program = program;
									zone.regime.id = program.id;

									zone.regime.channel = program.channel;
									zone.regime.periods = program.periods;
									zone.regime.days = program.days;

									zone.regime.periods.forEach((period) => {
										if (payload.channel === "hum") period.unit = "%";
										if (payload.channel === "temp") period.unit = "°";
										if (payload.channel === "carb") period.unit = "PPM";
									});
								}
							});

							zone.regime.active_srv = zone.regime.program.active_srv;
						}
					});
				}
				if (payload.element === "section") {
					let zone = state.zones.find((zone) => zone.id === payload.id);
					zone.regime.extended = !zone.regime.extended;
				}
			}
		},

		resetObjectsState(state) {
			if (state.zones && state.zones.length) {
				state.zones.forEach((zone, index) => {
					if (state.backup.zones && state.backup.zones.length > 0) {
						if (zone.id === state.backup.zones[index].id) {
							zone.hum = state.backup.zones[index].hum;
							zone.temp = state.backup.zones[index].temp;
							zone.name = state.backup.zones[index].name;
							zone.carb = state.backup.zones[index].carb;
							zone.programs = state.backup.zones[index].programs;
							zone.regime = state.backup.zones[index].regime;
							zone.status = "default";
							state.backup.zones[index] = {};
						}
					}

					zone.current = false;
					zone.cancellation = true;
					zone.validation = false;
					zone.extended = false;
					zone.regime.extended = false;
				});
			}
		},
		cancelObjectsChanges(state, payload) {
			if (payload.param === "about") {
				state.about.cancellation = true;
				state.about.validation = false;
				state.about.name.value = state.backup.about.name.value;
				state.about.name.status = "default";
				state.about.name.error = false;
				state.about.active_user = state.backup.about.active_user;
				state.about.vacation = state.backup.about.vacation;
				state.backup.about = {};
			}

			if (payload.param === "zone") {
				state.zones.forEach((element, index) => {
					if (element.id == payload.id) {
						element.hum = state.backup.zones[index].hum;
						element.temp = state.backup.zones[index].temp;
						element.name = state.backup.zones[index].name;
						element.name.status = "default";
						element.cancellation = true;
						element.validation = false;
						element.extended = false;
						state.backup.zones[index] = {};
					}
				});
			}
		},
		clearObjectsSection(state, payload) {
			state[payload].cancellation = true;
			state[payload].validation = false;
			state.backup[payload] = {};
		},
		clearObjectsState(state) {
			state.navbar = {};
			state.about = {
				extended: false,
				cancellation: true,
				validation: false,
			};
			state.zones = {};
			state.backup = {};
		},
		updateObjectsZonesSocket(state, payload) {
			if (state.zones !== undefined && state.zones.length > 0) {
				state.zones.forEach((zone, index) => {
					if (zone.id === payload.zones[index].id && !zone.current) {
						zone.hum.cur = payload.zones[index].hum.cur;
						zone.hum.set = payload.zones[index].hum.set;
						zone.hum.activity = payload.zones[index].hum.activity;

						zone.temp.cur = payload.zones[index].temp.cur;
						zone.temp.set = payload.zones[index].temp.set;
						zone.temp.activity = payload.zones[index].temp.activity;
					}
				});
			}
		},
	},
	actions: {
		fetchObjects(context, payload) {
			context.commit("showPreloader");
			context.commit("clearObjectsState");

			let userID = localStorage.getItem("authorization__user-id");

			axios
				.get(`https://api.airwet.app/api/getObjects/${userID}`, this.state.common.headers)
				.then((response) => {
					if (response.data.length > 0) {
						context.commit("destroyNotification");
						context.commit("updateObjectsNavbar", response.data);

						response.data.forEach((element, index) => {
							if (element.id == payload) {
								element.isActive = true;
								context.commit("updateObjectsAbout", response.data[index]);
								context.commit("updateObjectsZones", response.data[index]);
								context.commit("updateObjectsZones", response.data[index]);
							}
						});
					} else {
						let payload = {
							visible: true,
							title: "Уведомление!",
							text: `
              В настоящий момент в вашей учетной записи нет прикрепленных
              объектов. Попробуйте проверить позднее или свяжитесь с
              техподдержкой.
              `,
						};

						context.commit("createNotification", payload);
					}

					context.commit("hidePreloader");
				})
				.catch((error) => context.commit("handleError", error));
		},
		sendObjectsChanges(context, payload) {
			context.commit("showPreloader");

			if (payload === "about") {
				let system = {
					data: {
						name: context.state.about.name.value,
						active_user: context.state.about.active_user,
					},
				};

				axios
					.put(
						`https://api.airwet.app/api/updateSystem/${context.state.about.id}`,
						system,
						this.state.common.headers
					)
					.then((response) => {
						if (context.state.about.vacation.changed) {
							let mode = {
								data: {
									active_user: context.state.about.vacation.active_user,
								},
							};

							axios
								.put(
									`https://api.airwet.app/api/modes/${context.state.about.vacation.id}`,
									mode,
									this.state.common.headers
								)
								.then(() => {
									axios
										.get(
											`https://api.airwet.app/api/getSystem/${context.state.about.id}`,
											this.state.common.headers
										)
										.then((response) => {
											context.commit("updateObjectsAbout", response.data);
											context.commit("clearObjectsSection", payload);
											context.commit("hidePreloader");
										});
								});
						} else {
							context.commit("updateObjectsAbout", response.data);
							context.commit("clearObjectsSection", payload);
							context.commit("hidePreloader");
						}
					})
					.catch((error) => context.commit("handleError", error));
			}
		},
		changeObjectsZone(context, payload) {
			context.commit("showPreloader");

			let zone = {
				name: null,
				event: null,
				carb: {
					id: null,
					active_user: null,
					set: null,
				},
				hum: {
					id: null,
					active_user: null,
					set: null,
				},
				temp: {
					id: null,
					active_user: null,
					set: null,
				},
				programs: [],
				initiator: "web",
			};

			context.state.zones.forEach((elem) => {
				if (elem.id === payload.zone) {
					zone.name = elem.name.value;
					zone.event = "user_config";
					zone.programs = elem.programs;

					if (elem.carb !== null) {
						zone.carb.id = elem.carb.id;
						zone.carb.active_user = elem.carb.active_user;
						zone.carb.set = elem.carb.set;
						zone.carb.cur = elem.carb.cur;
					} else zone.carb = null;

					if (elem.hum !== null) {
						zone.hum.id = elem.hum.id;
						zone.hum.active_user = elem.hum.active_user;
						zone.hum.set = elem.hum.set;
						zone.hum.cur = elem.hum.cur;
					} else zone.hum = null;

					if (elem.temp !== null) {
						zone.temp.id = elem.temp.id;
						zone.temp.active_user = elem.temp.active_user;
						zone.temp.set = elem.temp.set;
						zone.temp.cur = elem.temp.cur;
					} else zone.temp = null;
				}
			});

			axios
				.put(
					`https://api.airwet.app/api/zones/${payload.zone}`,
					{
						data: zone,
					},
					this.state.common.headers
				)
				.then(() => {
					axios
						.get(
							`https://api.airwet.app/api/zones/?populate=devs,nozzles,hum,temp&filters[from_system][id][$eq]=${payload.system}`,
							this.state.common.headers
						)
						.then(() => {
							let programs = zone.programs.filter((program) => program.changed);

							if (programs.length) {
								programs.forEach((program) => {
									program.periods = program.periods.map((period) => {
										let obj = {
											id: period.id,
											name: period.name,
											set: period.set,
											start: `${period.start.hour < 10 ? "0" + period.start.hour : period.start.hour}:${
												period.start.minute < 10 ? "0" + period.start.minute : period.start.minute
											}`,
											title: period.title,
										};

										return obj;
									});

									program.days = program.days
										.filter((day) => day.activity === true)
										.map((day) => (day = day.num));

									let prog = {
										data: {
											active_user: program.active_user,
											hum: program.hum,
											carb: program.carb,
											temp: program.temp,
											periods: program.periods,
											days: program.days,
										},
									};

									axios
										.put(`https://api.airwet.app/api/programs/${program.id}`, prog, this.state.common.headers)
										.then(() => {
											axios
												.get(
													`https://api.airwet.app/api/getSystem/${context.state.about.id}`,
													this.state.common.headers
												)
												.then((response) => {
													context.commit("updateObjectsZones", response.data);
													context.commit("hidePreloader");
												});
										})
										.catch((error) => context.commit("handleError", error));
								});
							} else {
								axios
									.get(
										`https://api.airwet.app/api/getSystem/${context.state.about.id}`,
										this.state.common.headers
									)
									.then((response) => {
										context.commit("updateObjectsZones", response.data);
										context.commit("hidePreloader");
									});
							}

							context.state.zones.forEach((elem, index) => {
								if (elem.id === payload.zone) {
									context.state.backup.zones[index] = {};
								}
							});
						});
				})
				.catch((error) => context.commit("handleError", error));
		},
	},
};
