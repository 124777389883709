<template>
	<div class="extend">
		<div class="extend__wrapper">
			<img class="extend__logo" src="../assets/images/logo.svg" alt="" />
			<div class="extend__title">Регистрация</div>
			<form class="extend__form" @submit.prevent>
				<div class="extend__form-body">
					<div class="extend__form-block">
						<div class="extend__form-title">Должность</div>
						<Dropdown
							:type="position.type"
							:name="position.name"
							:items="position.categories"
							:value="position.value"
							:status="position.status"
							@click="handleChange($event, 'dropdown')"
						/>
						<span class="extend__form-error" v-if="position.error">{{ position.errorMessage }}</span>
					</div>
					<div class="extend__form-block">
						<div class="extend__form-title">Фамилия</div>
						<Input
							:type="lastName.type"
							:name="lastName.name"
							:status="lastName.status"
							:value="lastName.value"
							@focus="handleChange($event, 'input')"
							@paste="handleChange($event, 'input')"
							@input="handleChange($event, 'input')"
							@change="handleChange($event, 'input')"
						/>
						<span class="extend__form-error" v-if="lastName.error">{{ lastName.errorMessage }}</span>
					</div>
					<div class="extend__form-block">
						<div class="extend__form-title">Имя</div>
						<Input
							:type="firstName.type"
							:name="firstName.name"
							:status="firstName.status"
							:value="firstName.value"
							@focus="handleChange($event, 'input')"
							@paste="handleChange($event, 'input')"
							@input="handleChange($event, 'input')"
							@change="handleChange($event, 'input')"
						/>
						<span class="extend__form-error" v-if="firstName.error">{{ firstName.errorMessage }}</span>
					</div>
					<div class="extend__form-block">
						<div class="extend__form-title">Отчество</div>
						<Input
							:type="middleName.type"
							:name="middleName.name"
							:status="middleName.status"
							:value="middleName.value"
							@focus="handleChange($event, 'input')"
							@paste="handleChange($event, 'input')"
							@input="handleChange($event, 'input')"
							@change="handleChange($event, 'input')"
						/>
						<span class="extend__form-error" v-if="middleName.error">{{ middleName.errorMessage }}</span>
					</div>
					<div class="extend__form-block">
						<div class="extend__form-title">Укажите пароль</div>
						<Input
							:type="password.type"
							:name="password.name"
							:status="password.status"
							:value="password.value"
							readonly
							@touchstart="handleChange($event, 'input')"
							@focus="handleChange($event, 'input')"
							@paste="handleChange($event, 'input')"
							@input="handleChange($event, 'input')"
							@change="handleChange($event, 'input')"
						/>
						<span class="extend__form-error" v-if="password.error">{{ password.errorMessage }}</span>
					</div>
					<div class="extend__form-block">
						<div class="extend__form-title">Подтвердите пароль</div>
						<Input
							:type="confirm.type"
							:name="confirm.name"
							:status="confirm.status"
							:value="confirm.value"
							readonly
							@touchstart="handleChange($event, 'input')"
							@focus="handleChange($event, 'input')"
							@paste="handleChange($event, 'input')"
							@input="handleChange($event, 'input')"
							@change="handleChange($event, 'input')"
						/>
						<span class="extend__form-error" v-if="confirm.error">{{ confirm.errorMessage }}</span>
					</div>
				</div>
				<div class="extend__form-footer">
					<Button
						:type="'text'"
						:value="'Продолжить'"
						:paint="'gray'"
						:disabled="!validation"
						@click="handleFormSumbit"
					/>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import Button from "../components/Button";
import Dropdown from "../components/Dropdown";
import Input from "../components/Input";

export default {
	name: "Extend",
	components: {
		Button,
		Dropdown,
		Input,
	},
	computed: {
		...mapGetters({
			position: "getExtendPosition",
			lastName: "getExtendLastName",
			firstName: "getExtendFirstName",
			middleName: "getExtendMiddleName",
			password: "getExtendPassword",
			confirm: "getExtendConfirm",
			validation: "getExtendValidation",
		}),
	},
	methods: {
		...mapMutations(["handleExtendChanges"]),
		...mapActions(["logoutAuthorization", "sendExtendInfo"]),
		handleChange(event, element) {
			let payload = {};

			if (element === "dropdown") {
				if (event.target.closest(".dropdown__item")) {
					payload = {
						name: event.target.closest(".dropdown").getAttribute("name"),
						value: event.target.closest(".dropdown__item").querySelector(".dropdown__title").innerHTML,
						alias: event.target.closest(".dropdown__item").getAttribute("data-name"),
						event: "change",
					};
				} else return false;
			}

			if (element === "input") {
				if (event.type === "focus" || event.type === "touchstart") event.target.removeAttribute("readonly");

				payload = {
					event: event.type,
					name: event.target.getAttribute("name"),
					value: event.target.value,
				};
			}

			payload.element = element;

			this.handleExtendChanges(payload);
		},
		handleFormSumbit() {
			this.sendExtendInfo();
		},
	},
	created() {
		//this.logoutAuthorization();
	},
};
</script>

<style lang="scss" scoped>
.extend {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	padding-bottom: 20px;

	@include mediaquery(md) {
		min-height: calc(100vh - 20px);
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		@include mediaquery(sm) {
			width: 400px;
		}
	}

	&__logo {
		width: 50px;
		margin-bottom: 24px;

		@include mediaquery(sm) {
			margin-bottom: 44px;
		}
	}

	&__title {
		width: 100%;
		margin-bottom: 20px;
		text-transform: uppercase;
		text-align: center;
		font-size: 24px;
	}

	&__form {
		width: 100%;
		border-radius: 6px;
		background-color: #f0f0f0;
		box-shadow: 4px 4px 4px #dcdcdc, -4px -4px 4px #f7f7f9eb;
		padding: 20px;

		@include mediaquery(sm) {
			padding: 46px 40px 25px;
		}

		&-block {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			margin-bottom: 10px;
			position: relative;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		&-subblock {
			display: flex;
			align-items: center;
		}

		&-title {
			font-size: 9px;
			margin-bottom: 6px;
			color: #9b9b9b;
		}

		&-error {
			position: absolute;
			left: 0;
			top: 100%;
			font-size: 9px;
			color: #ff8068;
		}

		&-link {
			font-size: 12px;
			color: #41c6eb;
		}

		&-input {
			height: 30px;
			background: rgba(0, 0, 0, 0.0001);
			box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.106113), inset -5px -5px 9px rgba(255, 255, 255, 0.5);
			border: 0;
			border-radius: 4px;
			width: 100%;
			font-size: 9px;
			color: #969696;
			padding-left: 13px;
		}

		&-footer {
			display: flex;
			flex-direction: column;
			margin-top: 20px;

			button {
				width: 100%;
			}
		}
	}
}
</style>
