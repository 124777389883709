<template>
  <div class="pagination" v-if="pagination.total > pagination.limit">
    <button
      class="pagination__button"
      :data-type="'prev'"
      :disabled="!pagination.prev"
      @click="handlePagination"
    >
      <span class="pagination__button-icon"></span>
    </button>
    <ul class="pagination__pages">
      <li
        v-for="page in pagination.pages"
        :class="[
          page.current
            ? 'pagination__page pagination__page_current'
            : 'pagination__page',
        ]"
        :data-id="page.id"
        :key="page.id"
        @click="handlePagination"
      >
        {{ page.value }}
      </li>
    </ul>
    <button
      class="pagination__button"
      :data-type="'next'"
      :disabled="!pagination.next"
      @click="handlePagination"
    >
      <span class="pagination__button-icon"></span>
    </button>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from "vuex";

  export default {
    name: "Pagination",
    computed: {
      ...mapGetters({
        pagination: "getCommonPagination",
      }),
    },
    methods: {
      ...mapMutations(["changePagination"]),
      handlePagination(event) {
        let payload = {};

        if (event.target.closest(".pagination__button")) {
          payload.type = event.target.closest("button").getAttribute("data-type");
        }

        if (event.target.closest(".pagination__page")) {
          payload.page = +event.target
            .closest(".pagination__page")
            .getAttribute("data-id");
        }

        this.changePagination(payload);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    &__button {
      @include base;

      position: relative;
      height: 36px;
      min-width: 36px;
      padding: 0;
      border: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        .pagination__button-icon {
          transform: rotate(135deg);
        }
      }

      &:last-child {
        .pagination__button-icon {
          transform: rotate(-45deg);
        }
      }

      &:hover {
        .pagination__button-icon {
          border-color: #bcbcbc;
        }
      }

      &:disabled {
        cursor: auto;
        pointer-events: none;

        .pagination__button-icon {
          border-color: #9b9b9b;
        }
      }

      &-icon {
        width: 7px;
        height: 7px;
        border: solid #000;
        border-width: 0 2px 2px 0;
      }
    }

    &__pages {
      margin: 0;
      padding: 0 10px;
      list-style: none;
      display: flex;
      align-items: center;
    }

    &__page {
      font-size: 14px;
      margin: 0 5px;
      color: #9b9b9b;
      cursor: pointer;

      &:hover {
        color: #474747;
      }

      &_current {
        color: #000;
      }
    }
  }
</style>
