<template>
	<div class="system__settings">
		<section class="config">
			<div class="config__header" @click="toggleSection('config')">
				<div class="config__wrapper">
					<div class="config__name">Конфигурация локальной системы</div>
				</div>
				<div class="config__control">
					<div class="config__info">
						<div class="config__mileage">{{ config.pump_hours }} М/ч</div>
						<div :class="`config__state config__state_${config.state.value}`">
							{{ config.state.text }}
						</div>
					</div>
					<button class="config__button" @click.stop="toggleSection('config')">
						<span
							:class="[
								config.extended ? 'config__button-icon config__button-icon_rotate' : 'config__button-icon',
							]"
						></span>
					</button>
				</div>
			</div>
			<div class="config__body" v-show="config.extended">
				<div class="config__blocks">
					<div class="config__main">
						<div class="config__block" v-for="param in config.params" :data-id="param.id" :key="param.id">
							<div class="config__title">{{ param.title }}</div>
							<Dropdown
								:type="param.type"
								:name="param.name"
								:items="param.categories"
								:value="param.value"
								:status="param.status"
								@click="handleChanges($event, param.element)"
							/>
						</div>
					</div>
					<div class="config__soft">
						<div class="config__title">Версия ПО</div>
						<div class="config__params">
							<div class="config__param">Центральный Блок 1.0</div>
							<div class="config__param">Гигростаты 2.1.6</div>
							<div class="config__param">Блок Управления Насосом 2.1</div>
							<div class="config__param">Датчики 18.9</div>
						</div>
					</div>
				</div>
				<div class="config__footer">
					<Button
						:type="'text'"
						:value="'Отмена'"
						:paint="'gray'"
						:disabled="config.cancellation"
						@click="cancelChanges($event, 'config')"
					/>
					<Button
						:type="'text'"
						:value="'Сохранить изменения'"
						:paint="'blue'"
						:disabled="!config.validation"
						@click="sendChanges('config')"
					/>
				</div>
			</div>
		</section>
		<div class="system__left">
			<section class="state">
				<div class="state__header" @click="toggleSection('state')">
					<div class="state__wrapper">
						<div class="state__name">Состояние системы</div>
					</div>
					<div class="state__control">
						<button class="state__button" @click.stop="toggleSection('state')">
							<span
								:class="[
									state.extended ? 'state__button-icon state__button-icon_rotate' : 'state__button-icon',
								]"
							></span>
						</button>
					</div>
				</div>
				<div class="state__body" v-show="state.extended">
					<div class="state__params">
						<div class="state__block">
							<div class="state__title">Входное давление (Бар)</div>
							<Input :type="'text'" :value="state.p_in" :disabled="true" />
						</div>
						<div class="state__block">
							<div class="state__title">Выходное давление (Бар)</div>
							<Input :type="'text'" :value="state.p_out" :disabled="true" />
						</div>
						<div class="state__block">
							<div class="state__title">Входной клапан</div>
							<Input :type="'text'" :value="state.valve_in === true ? 'Открыт' : 'Закрыт'" :disabled="true" />
						</div>
						<div class="state__block">
							<div class="state__title">Дренажный клапан</div>
							<Input :type="'text'" :value="state.valve_drain === true ? 'Открыт' : 'Закрыт'" :disabled="true" />
						</div>
						<div class="state__block">
							<div class="state__title">Текущие обороты насоса</div>
							<Input :type="'text'" :value="state.pump_rpm" :disabled="true" />
						</div>
					</div>
					<template v-if="state.zones.length > 0">
						<div class="state__subtitle">Состояние работы по Зонам/Этажам</div>
						<div class="state__zones">
							<div class="state__zone" v-for="block in state.zones" :id="block.id" :key="block.id">
								<div class="state__zone-name">Зона {{ block.num }} - {{ block.name }}</div>
								<div class="state__zone-params">
									<template v-if="block.hum">
										<div class="state__zone-param">
											Влажность
											<span
												:class="
													block.hum.activity == true
														? 'state__zone-status'
														: 'state__zone-status state__zone-status_error'
												"
												>{{ block.hum.activity == true ? "Работает" : "Не работает" }}</span
											>
										</div>
									</template>
									<template v-if="block.temp">
										<div class="state__zone-param">
											Температура
											<span
												:class="
													block.temp.activity == true
														? 'state__zone-status'
														: 'state__zone-status state__zone-status_error'
												"
												>{{ block.temp.activity == true ? "Работает" : "Не работает" }}</span
											>
										</div>
									</template>
									<template v-if="block.carb">
										<div class="state__zone-param">
											CO2
											<span
												:class="
													block.carb.activity == true
														? 'state__zone-status'
														: 'state__zone-status state__zone-status_error'
												"
												>{{ block.carb.activity == true ? "Работает" : "Не работает" }}</span
											>
										</div>
									</template>
								</div>
								<div class="state__zone-modes" v-if="false /*about.system_architecture === 'Кольцевая'*/">
									<div class="state__zone-mode" v-if="block.timer_work !== null">
										<span class="state__zone-work">Работа</span>
										<Input :type="'text'" :value="block.timer_work" :disabled="true" />
									</div>
									<div class="state__zone-mode" v-if="block.timer_pause !== null">
										<span class="state__zone-pause">Пауза</span>
										<Input :type="'text'" :value="block.timer_pause" :disabled="true" />
									</div>
								</div>
							</div>
						</div>
					</template>
					<div class="state__subtitle">Текущее состояние платы</div>
					<div class="state__board">
						<div class="state__board-status">
							{{ state.status }}
						</div>
						<template v-if="state.status === 'Активный режим' || state.status === 'Пауза'">
							<Input :type="'text'" :value="state.timer" :disabled="true" />
						</template>
					</div>
					<div class="state__subtitle">Текущее состояние сети</div>
					<div class="state__network">
						<template v-if="state.network.status === 0">
							<div class="state__network-status state__network-status_disconnected">Соединение отсутствует</div>
						</template>
						<template v-else-if="state.network.status === 1">
							<div class="state__network-status state__network-status_connected">Соединение установлено</div>
						</template>
						<template v-else-if="state.network.status === 2">
							<div class="state__network-status state__network-status_connecting">
								Соединение устанавливается
							</div>
						</template>
						<span :class="`state__network-indicator state__network-indicator_${state.network.indicator}`"></span>
					</div>
					<template v-if="Array.isArray(state.errors) && state.errors.length > 0">
						<div class="state__subtitle">Сообщения об ошибках системы</div>
						<div class="state__errors">
							<div
								class="state__error"
								v-for="(error, index) in state.errors"
								:key="index"
								:data-error="error.code"
							>
								<div class="state__error-wrapper">
									<span class="state__error-dot"></span>
									<div class="state__error-info">
										<div class="state__error-date">
											{{ convertDate(error.time, "full") }}
										</div>
										<div class="state__error-text">{{ error.text }}</div>
									</div>
								</div>
								<Button
									:type="'action'"
									:title="'Сбросить ошибку'"
									:value="'reset'"
									:paint="'gray'"
									@click="resetErrors"
								/>
							</div>
						</div>
					</template>
				</div>
			</section>
			<section class="features">
				<div class="features__header" @click="toggleSection('features')">
					<div class="features__wrapper">
						<div class="features__name">Параметры системы</div>
					</div>
					<div class="features__control">
						<button class="features__button" @click.stop="toggleSection('features')">
							<span
								:class="[
									features.extended
										? 'features__button-icon features__button-icon_rotate'
										: 'features__button-icon',
								]"
							></span>
						</button>
					</div>
				</div>
				<div class="features__body" v-show="features.extended">
					<div class="features__blocks">
						<div
							:class="[feature.invisible ? 'features__block features__block_invisible' : 'features__block']"
							v-for="feature in features.params"
							:id="feature.id"
							:key="feature.id"
						>
							<div class="features__title">{{ feature.title }}</div>
							<Dropdown
								:type="feature.type"
								:name="feature.name"
								:items="feature.categories"
								:value="feature.value"
								:status="feature.status"
								@click="handleChanges($event, 'dropdown')"
								v-if="feature.dropdown"
							/>
							<Input
								:type="'text'"
								:name="feature.name"
								:value="feature.value"
								:status="feature.status"
								@input="handleChanges($event, 'input')"
								@change="handleChanges($event, 'input')"
								v-else
							/>
							<span class="features__error" v-if="feature.error">{{ feature.error }}</span>
						</div>
					</div>
					<div class="features__footer">
						<Button
							:type="'text'"
							:value="'Отмена'"
							:paint="'gray'"
							:disabled="features.cancellation"
							@click="cancelChanges($event, 'features')"
						/>
						<Button
							:type="'text'"
							:value="'Сохранить изменения'"
							:paint="'blue'"
							:disabled="!features.validation"
							@click="sendChanges('features')"
						/>
					</div>
				</div>
			</section>
			<section class="modes">
				<div class="modes__header" @click="toggleSection('modes')">
					<div class="modes__wrapper">
						<div class="modes__name">Режимы системы</div>
					</div>
					<div class="modes__control">
						<button class="modes__button" @click.stop="toggleSection('modes')">
							<span
								:class="[
									modes.extended ? 'modes__button-icon modes__button-icon_rotate' : 'modes__button-icon',
								]"
							></span>
						</button>
					</div>
				</div>
				<div class="modes__body" v-show="modes.extended">
					<ul class="modes__list">
						<li class="modes__item mode" :id="mode.id" v-for="mode in modes.list" :key="mode.id">
							<div class="mode__header" @click.stop="toggleSubSection(mode)">
								<div class="mode__wrapper">
									<div class="mode__name">{{ mode.name }}</div>
								</div>
								<div class="mode__control">
									<Switch
										:status="mode.active_srv"
										:name="'active_srv'"
										paint="blue"
										@change="handleChanges($event, 'switch')"
									/>
									<button class="mode__button" @click.stop="toggleSubSection(mode)">
										<span
											:class="[
												mode.extended ? 'mode__button-icon mode__button-icon_rotate' : 'mode__button-icon',
											]"
										></span>
									</button>
								</div>
							</div>
							<div class="mode__body" v-show="mode.extended">
								<div class="mode__settings" v-if="mode.type == 'self_check'">
									<div class="mode__commissioning">
										<Button
											:type="'text'"
											:name="'commissioning_start'"
											:value="'Запуск пусконаладки'"
											:paint="'blue'"
											:disabled="mode.commissioning.active"
											@click="handleChanges($event, 'button')"
										/>
										<Button
											:type="'text'"
											:name="'commissioning_stop'"
											:value="'Прервать выполнение'"
											:paint="'blue'"
											:disabled="!mode.commissioning.active"
											@click="handleChanges($event, 'button')"
										/>
									</div>
									<div class="mode__subtitle">Статус процедуры</div>
									<div class="mode__status">{{mode.commissioning.status}}</div>
								</div>
								<template v-if="mode.type == 'self_check'">
									<div
										:class="
											mode.commissioning.initialized == true
												? 'mode__subjects'
												: 'mode__subjects mode__subjects_inactive'
										"
									>
										<div class="mode__screen"></div>
										<div class="mode__main">
											<div class="mode__column">
												<div class="mode__title">Дни режима</div>
												<div class="mode__days">
													<div class="mode__day" v-for="(day, index) in mode.days" :key="index">
														<Checkbox
															:name="'days'"
															:status="day.activity"
															:data-day="day.num"
															@change="handleChanges($event, 'checkbox')"
														/>
														<span class="mode__day-name">{{ day.name }}</span>
													</div>
												</div>
											</div>
											<div class="mode__column">
												<div class="mode__title">Время режима</div>
												<div class="mode__blocks">
													<div
														class="mode__block mode__block_start"
														v-for="item in mode.start"
														:key="item.id"
														:data-id="item.id"
													>
														<div class="mode__stage">Старт</div>
														<div class="mode__subwrapper">
															<div class="mode__picker">
																<Timepicker
																	:hour="convertModesTime(item.hour, 'hour')"
																	:minute="convertModesTime(item.minute, 'minute')"
																	data-stage="start"
																	@click="handleChanges($event, 'timepicker')"
																/>
															</div>
															<Button
																:name="'add_mode_start'"
																:type="'action'"
																:value="'add'"
																:paint="'gray'"
																v-if="item.id == 0"
																@click="handleChanges($event, 'button')"
															/>
															<Button
																:name="'delete_mode_start'"
																:type="'action'"
																:value="'delete'"
																:paint="'gray'"
																v-else
																@click="handleChanges($event, 'button')"
															/>
														</div>
													</div>
													<div class="mode__block">
														<div class="mode__stage">Время дельты</div>
														<div class="mode__input">
															<Input
																:type="'text'"
																:name="'t_deadzone'"
																:value="mode.t_deadzone.value"
																:status="mode.t_deadzone.status"
																@input="handleChanges($event, 'input')"
																@change="handleChanges($event, 'input')"
															/>
														</div>
													</div>
													<div class="mode__block">
														<div class="mode__stage">Ручной запуск СД</div>
														<Switch
															:status="control.manual_control == true ? control.valve_in : false"
															:name="'valve_in'"
															paint="blue"
															@change="handleChanges($event, 'switch')"
														/>
													</div>
												</div>
											</div>
										</div>
										<div class="mode__zones" v-if="mode.zones && mode.zones.length">
											<div class="mode__zone" v-for="zone in mode.zones" :data-id="zone.id" :key="zone.id">
												<div class="mode__title">{{ zone.name }}</div>
												<div class="mode__set">
													<Input
														:type="'text'"
														:name="'t_drain'"
														:value="zone.t_drain.value"
														:status="zone.t_drain.status"
														@input="handleChanges($event, 'input')"
														@change="handleChanges($event, 'input')"
													/>
													<Switch
														:status="zone.active"
														:name="'zone_active'"
														paint="blue"
														@change="handleChanges($event, 'switch')"
													/>
												</div>
											</div>
										</div>
									</div>
								</template>
								<template v-else>
									<div
										:class="
											mode.active_srv == true ? 'mode__subjects' : 'mode__subjects mode__subjects_inactive'
										"
									>
										<div class="mode__screen"></div>
										<div class="mode__main">
											<div class="mode__column">
												<div class="mode__title">Дни режима</div>
												<div class="mode__days">
													<div class="mode__day" v-for="(day, index) in mode.days" :key="index">
														<Checkbox
															:name="'days'"
															:status="day.activity"
															:data-day="day.num"
															@change="handleChanges($event, 'checkbox')"
														/>
														<span class="mode__day-name">{{ day.name }}</span>
													</div>
												</div>
											</div>
											<div class="mode__column">
												<div class="mode__title">Время режима</div>
												<div class="mode__blocks">
													<div
														class="mode__block mode__block_start"
														v-for="item in mode.start"
														:key="item.id"
														:data-id="item.id"
													>
														<div class="mode__stage">Старт</div>
														<div class="mode__subwrapper">
															<div class="mode__picker">
																<Timepicker
																	:hour="convertModesTime(item.hour, 'hour')"
																	:minute="convertModesTime(item.minute, 'minute')"
																	data-stage="start"
																	@click="handleChanges($event, 'timepicker')"
																/>
															</div>
															<Button
																:name="'add_mode_start'"
																:type="'action'"
																:value="'add'"
																:paint="'gray'"
																v-if="item.id == 0"
																@click="handleChanges($event, 'button')"
															/>
															<Button
																:name="'delete_mode_start'"
																:type="'action'"
																:value="'delete'"
																:paint="'gray'"
																v-else
																@click="handleChanges($event, 'button')"
															/>
														</div>
													</div>
													<div class="mode__block">
														<div class="mode__stage">Длительность</div>
														<div class="mode__input">
															<Input
																:type="'text'"
																:name="'duration'"
																:value="mode.duration.value"
																:status="mode.duration.status"
																@input="handleChanges($event, 'input')"
																@change="handleChanges($event, 'input')"
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="mode__zones" v-if="mode.zones && mode.zones.length">
											<div class="mode__zone" v-for="zone in mode.zones" :data-id="zone.id" :key="zone.id">
												<div class="mode__title">{{ zone.name }}</div>
												<div class="mode__set">
													<Switch
														:status="zone.active"
														:name="'zone_active'"
														paint="blue"
														@change="handleChanges($event, 'switch')"
													/>
												</div>
											</div>
										</div>
									</div>
								</template>
							</div>
						</li>
					</ul>
					<div class="modes__footer">
						<Button
							:type="'text'"
							:value="'Отмена'"
							:paint="'gray'"
							:disabled="modes.cancellation"
							@click="cancelChanges($event, 'modes')"
						/>
						<Button
							:type="'text'"
							:value="'Сохранить изменения'"
							:paint="'blue'"
							:disabled="!modes.validation"
							@click="sendChanges('modes')"
						/>
					</div>
				</div>
			</section>
		</div>
		<div class="system__right">
			<section class="control">
				<div class="control__header" @click="toggleSection('control')">
					<div class="control__wrapper">
						<div class="control__name">Ручное управление</div>
					</div>
					<div class="control__control">
						<button class="control__button" @click.stop="toggleSection('control')">
							<span
								:class="[
									control.extended
										? 'control__button-icon control__button-icon_rotate'
										: 'control__button-icon',
								]"
							></span>
						</button>
					</div>
				</div>
				<div class="control__body" v-show="control.extended">
					<div class="control__state">
						<span
							:class="
								control.manual_control == true
									? 'control__state-status'
									: 'control__state-status control__state-status_error'
							"
						>
							Ручное управление
							{{ control.manual_control == true ? "включено" : "выключено" }}</span
						>
						<Switch
							:status="control.manual_control"
							:name="'manual_control'"
							paint="blue"
							@change="handleChanges($event, 'switch')"
						/>
					</div>
					<div
						:class="
							control.manual_control == true
								? 'control__subjects'
								: 'control__subjects control__subjects_inactive'
						"
					>
						<div class="control__screen"></div>
						<div class="control__zones">
							<div class="control__block" v-for="block in control.zones" :data-id="block.id" :key="block.id">
								<div class="control__title">Зона {{ block.num }} - {{ block.name }}</div>
								<div class="control__params">
									<template v-if="block.hum">
										<div class="control__param" data-param="hum">
											Влажность
											<Switch
												:status="control.manual_control == true ? block.hum.activity : false"
												:name="'activity'"
												paint="blue"
												@change="handleChanges($event, 'switch')"
											/>
										</div>
									</template>
									<template v-if="block.temp">
										<div class="control__param" data-param="temp">
											Температура
											<Switch
												:status="control.manual_control == true ? block.temp.activity : false"
												:name="'activity'"
												paint="blue"
												@change="handleChanges($event, 'switch')"
											/>
										</div>
									</template>
									<template v-if="block.carb">
										<div class="control__param" data-param="carb">
											CO2
											<Switch
												:status="control.manual_control == true ? block.carb.activity : false"
												:name="'activity'"
												paint="blue"
												@change="handleChanges($event, 'switch')"
											/>
										</div>
									</template>
								</div>
							</div>
						</div>
						<div class="control__main">
							<div class="control__block" data-id="valve_in">
								<div class="control__title">Входной клапан</div>
								<Switch
									:status="control.manual_control == true ? control.valve_in : false"
									:name="'valve_in'"
									paint="blue"
									@change="handleChanges($event, 'switch')"
								/>
							</div>
							<div class="control__block" data-id="valve_drain">
								<div class="control__title">Дренажный клапан</div>
								<Switch
									:status="control.manual_control == true ? control.valve_drain : false"
									:name="'valve_drain'"
									paint="blue"
									@change="handleChanges($event, 'switch')"
								/>
							</div>
							<div class="control__block" data-id="pump">
								<div class="control__title">Насос</div>
								<Switch
									:status="control.manual_control == true ? control.pump : false"
									:name="'pump'"
									paint="blue"
									@change="handleChanges($event, 'switch')"
								/>
							</div>
							<div class="control__block" data-id="driver">
								<div class="control__title">Драйвер</div>
								<Switch
									:status="control.manual_control == true ? control.driver : false"
									:name="'driver'"
									paint="blue"
									@change="handleChanges($event, 'switch')"
								/>
							</div>
						</div>
					</div>
					<div class="control__footer">
						<Button
							:type="'text'"
							:value="'Отмена'"
							:paint="'gray'"
							:disabled="control.cancellation"
							@click="cancelChanges($event, 'control')"
						/>
						<Button
							:type="'text'"
							:value="'Сохранить изменения'"
							:paint="'blue'"
							:disabled="!control.validation"
							@click="sendChanges('control')"
						/>
					</div>
				</div>
			</section>
			<section class="firmware">
				<div class="firmware__header" @click="toggleSection('firmware')">
					<div class="firmware__wrapper">
						<div class="firmware__name">Программное обеспечение</div>
					</div>
					<div class="firmware__control">
						<button class="firmware__button" @click.stop="toggleSection('firmware')">
							<span
								:class="[
									firmware.extended
										? 'firmware__button-icon firmware__button-icon_rotate'
										: 'firmware__button-icon',
								]"
							></span>
						</button>
					</div>
				</div>
				<div class="firmware__body" v-show="firmware.extended">
					<div
						:class="
							firmware.processing == true ? 'firmware__content firmware__content_inactive' : 'firmware__content'
						"
					>
						<div class="firmware__screen"></div>
						<img class="firmware__preloader" src="../assets/images/preloader.svg" />
						<div class="firmware__version">Текущая версия прошивки - {{ firmware.version }}</div>
						<div class="firmware__main">
							<div class="firmware__column">
								<div class="firmware__title">Выбрать прошивку</div>
								<Dropdown
									:type="firmware.list.type"
									:name="firmware.list.name"
									:items="firmware.list.categories"
									:value="firmware.list.value"
									:status="firmware.list.status"
									@click="handleChanges($event, firmware.list.element)"
								/>
							</div>
							<div class="firmware__column">
								<div class="firmware__upload">
									<div class="firmware__title">Загрузить файл прошивки</div>
									<div class="firmware__file">
										<div class="firmware__file-text">
											{{
												firmware.file !== null
													? `${firmware.file.name} (${(firmware.file.size / 1000000).toFixed(2)} Мб)`
													: "..."
											}}
										</div>
										<label class="firmware__file-label" @change.stop="handleChanges($event, 'file')">
											<input class="firmware__file-input" type="file" name="file" />
											<span class="firmware__file-action">
												<font-awesome-icon icon="fa-plus" />
											</span>
										</label>
										<Button
											:type="'action'"
											:value="'upload'"
											:disabled="!firmware.file"
											@click="sendChanges('firmware_upload')"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="firmware__warning" v-if="firmware.processing == true">
						{{ firmware.warning }}
					</div>
					<div class="firmware__footer">
						<Button
							:type="'text'"
							:value="'Отмена'"
							:paint="'gray'"
							:disabled="firmware.cancellation"
							@click="cancelChanges($event, 'firmware')"
						/>
						<Button
							:type="'text'"
							:value="'Сохранить изменения'"
							:paint="'blue'"
							:disabled="!firmware.validation"
							@click="sendChanges('firmware_update')"
						/>
					</div>
				</div>
			</section>
			<section class="logs">
				<div class="logs__header" @click="toggleSection('logs')">
					<div class="logs__wrapper">
						<div class="logs__name">Системный журнал</div>
					</div>
					<div class="logs__control">
						<button class="logs__button" @click.stop="toggleSection('logs')">
							<span
								:class="[logs.extended ? 'logs__button-icon logs__button-icon_rotate' : 'logs__button-icon']"
							></span>
						</button>
					</div>
				</div>
				<div class="logs__body" v-show="logs.extended">
					<div class="logs__content">
						<div class="logs__load">
							<div class="logs__subhead">Загрузить записи</div>
							<div class="logs__blocks">
								<div class="logs__block">
									<div class="logs__title">С даты</div>
									<div
										ref="date_logs_start"
										name="date_logs_start"
										@change="handleChanges($event, 'datepicker')"
										@click.stop="false"
									>
										<Datepicker
											v-model="date_logs_start"
											:format="format"
											autoApply
											hideInputIcon
										></Datepicker>
									</div>
								</div>
								<div class="logs__block">
									<div class="logs__title">До даты</div>
									<div
										ref="date_logs_end"
										name="date_logs_end"
										@change="handleChanges($event, 'datepicker')"
										@click.stop="false"
									>
										<Datepicker v-model="date_logs_end" :format="format" autoApply hideInputIcon></Datepicker>
									</div>
								</div>
							</div>
						</div>
						<ul class="logs__list" v-if="logs.list && logs.list.length">
							<perfect-scrollbar>
								<li class="logs__item" v-for="log in logs.list" :key="log.id">
									<div :class="`logs__type logs__type_${log.level}`">
										<span class="logs__date"> {{ convertLogsDate(log.time) }} </span>
										{{ log.level }}
									</div>
									<div class="logs__message">{{ log.message }}</div>
								</li>
							</perfect-scrollbar>
						</ul>
						<div class="logs__empty" v-else-if="!logs.processing">Записи отсутствуют</div>
					</div>
					<div class="logs__loading" v-if="logs.processing">
						<div class="logs__warning">{{ logs.warning }}</div>
						<Button
							:name="'logs_abort'"
							:type="'text'"
							:value="'Прервать загрузку'"
							:paint="'blue'"
							:disabled="logs.abort"
							v-if="!logs.abort"
							@click="handleChanges($event, 'button')"
						/>
					</div>
					<div class="logs__footer">
						<Button
							:type="'text'"
							:value="'Отмена'"
							:paint="'gray'"
							:disabled="logs.cancellation"
							@click="cancelChanges($event, 'logs')"
						/>
						<Button
							:type="'text'"
							:value="'Сохранить изменения'"
							:paint="'blue'"
							:disabled="!logs.validation"
							@click="sendChanges('logs')"
						/>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import Datepicker from "@vuepic/vue-datepicker";

import Button from "../components/Button";
import Checkbox from "../components/Checkbox";
import Dropdown from "../components/Dropdown";
import Input from "../components/Input";
import Switch from "../components/Switch";
import Timepicker from "../components/Timepicker";

export default {
	name: "SystemSettings",
	data() {
		return {
			format: "dd.MM.yyyy",
		};
	},
	components: {
		Datepicker,
		Button,
		Checkbox,
		Dropdown,
		Input,
		Switch,
		Timepicker,
	},
	computed: {
		...mapGetters({
			about: "getSystemAbout",
			config: "getSystemConfig",
			state: "getSystemState",
			control: "getSystemControl",
			modes: "getSystemModes",
			features: "getSystemFeatures",
			firmware: "getSystemFirmware",
			logs: "getSystemLogs",
		}),
		date_logs_start: {
			get() {
				if (this.$store.state.system.logs.date_logs_start.value !== null) {
					return new Date(this.$store.state.system.logs.date_logs_start.value * 1000);
				} else return null;
			},
			set(value) {
				let event = new Event("change");
				event.value = Math.round(Date.parse(value) / 1000);

				this.$refs.date_logs_start.dispatchEvent(event);
			},
		},
		date_logs_end: {
			get() {
				if (this.$store.state.system.logs.date_logs_end.value !== null) {
					return new Date(this.$store.state.system.logs.date_logs_end.value * 1000);
				} else return null;
			},
			set(value) {
				let event = new Event("change");
				event.value = Math.round(Date.parse(value) / 1000);

				this.$refs.date_logs_end.dispatchEvent(event);
			},
		},
	},
	methods: {
		...mapMutations([
			"toggleSystemSubSection",
			"toggleSystemSection",
			"handleSystemChanges",
			"cancelSystemChanges",
			"updateSystemState",
			"updateSystemControl",
			"updateSystemFirmware",
			"resetSystemsState",
			"createPopup",
		]),
		...mapActions(["resetSystemErrors", "sendSystemChanges"]),
		toggleSubSection(section) {
			this.toggleSystemSubSection(section);
		},
		toggleSection(section) {
			this.toggleSystemSection(section);
		},
		convertDate(timestamp, type) {
			if (timestamp !== null) {
				let newDate = new Date(timestamp * 1000);
				let year = newDate.getFullYear();
				let month = newDate.getMonth() + 1;
				if (month < 10) month = "0" + month;
				let day = newDate.getDate();
				if (day < 10) day = "0" + day;
				let hour = newDate.getHours();
				if (hour < 10) hour = "0" + hour;
				let min = newDate.getMinutes();
				if (min < 10) min = "0" + min;
				let sec = newDate.getSeconds();
				if (sec < 10) sec = "0" + sec;

				if (type && type === "full") return `${day}.${month}.${year} ${hour}:${min}:${sec}`;
				else return `${day}.${month}.${year}`;
			}
		},
		convertLogsDate(date) {
			let newDate = new Date(date);
			let year = newDate.getFullYear();
			let month = newDate.getMonth() + 1;
			if (month < 10) month = "0" + month;
			let day = newDate.getDate();
			if (day < 10) day = "0" + day;
			let hour = newDate.getHours();
			if (hour < 10) hour = "0" + hour;
			let min = newDate.getMinutes();
			if (min < 10) min = "0" + min;
			let sec = newDate.getSeconds();
			if (sec < 10) sec = "0" + sec;

			return `${day}.${month}.${year} ${hour}:${min}:${sec}`;
		},
		convertModesTime(date, param) {
			if (param === "day") {
				let days = ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"];
				let day = days[date];

				return day;
			} else {
				if (date < 10) date = "0" + date;

				return date;
			}
		},
		resetErrors(event) {
			let payload = {
				error: +event.target.closest(".state__error").getAttribute("data-error"),
			};

			this.resetSystemErrors(payload);
		},
		notifyLogs(transfer) {
			let payload = {
				type: "logs",
				params: {
					transfer: transfer,
					text: "",
				},
			};

			if (transfer === "abort") {
				payload.params.text = "Процесс загрузки прерван, идет формирование логов.";
			}
			if (transfer === "succeed") {
				payload.params.text = "Идет формирование логов.";
			}
			if (transfer === "failed") {
				payload.params.text = "Видимо, что-то пошло не так, попробуйте еще раз.";
			}

			this.createPopup(payload);
		},
		handleChanges(event, element) {
			let payload = {};

			if (element === "datepicker") {
				let val = null;
				let newDate = new Date(event.value * 1000);
				let year = newDate.getFullYear();
				let month = newDate.getMonth() + 1;
				if (month < 10) month = "0" + month;
				let day = newDate.getDate();
				if (day < 10) day = "0" + day;

				val = `${day}.${month}.${year}`;

				payload = {
					event: event.type,
					name: event.target.getAttribute("name"),
					value: event.value,
					val: val,
				};
			}

			if (element === "input") {
				payload = {
					event: event.type,
					name: event.target.getAttribute("name"),
					value: event.target.value,
				};

				if (payload.name === "t_drain") {
					payload.zone = +event.target.closest(".mode__zone").getAttribute("data-id");
				}

				if (payload.name === "phone") {
					if (event.type === "paste") {
						let value = event.clipboardData.getData("text/plain");

						let pattern = /(\+7|8)\s?[\s(]?(\d{3})[\s)]?\s?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;
						let phoneNumbers = value.match(pattern);

						if (phoneNumbers !== null) {
							let correctNumber = phoneNumbers[0].replace(pattern, "+7 ($2) $3-$4-$5");

							event.target.value = correctNumber;
						}
					} else if (event.type === "keydown" || event.type === "input") {
						let keyCode;
						event.keyCode && (keyCode = event.keyCode);

						if (event.target.selectionStart < 3) {
							event.target.value = `+7 ${event.data}`;
						}

						let mask = "+7 (___) ___-__-__";
						let index = 0;
						let def = mask.replace(/\D/g, "");
						let val = event.target.value.replace(/\D/g, "");
						let newVal = mask.replace(/[_\d]/g, (arg) =>
							index < val.length ? val.charAt(index++) || def.charAt(index) : arg
						);

						index = newVal.indexOf("_");

						if (index !== -1) {
							index < 5 && (index = 3);
							newVal = newVal.slice(0, index);
						}

						let reg = mask
							.substr(0, event.target.value.length)
							.replace(/_+/g, (arg) => `\\d{1,${arg.length}}`)
							.replace(/[+()]/g, "\\$&");

						reg = new RegExp(`^${reg}$`);

						if (
							!reg.test(event.target.value) ||
							event.target.value.length < 5 ||
							(keyCode > 47 && keyCode < 58)
						) {
							event.target.value = newVal;
						}
					}

					payload.value = event.target.value;
				}
			}

			if (element === "dropdown") {
				if (event.target.closest(".dropdown__item")) {
					payload = {
						id: +event.target.closest(".dropdown__item").getAttribute("data-name"),
						name: event.target.closest(".dropdown").getAttribute("name"),
						value: event.target.closest(".dropdown__item").querySelector(".dropdown__title").innerHTML,
						alias: +event.target.closest(".dropdown__item").getAttribute("data-name"),
						event: "change",
					};
				} else return false;
			}

			if (element === "switch") {
				if (event.target.closest(".control__block") !== null) {
					payload = {
						value: event.target.checked,
						name: event.target.getAttribute("name"),
						id:
							event.target.closest(".control__block").getAttribute("data-id") !== null
								? +event.target.closest(".control__block").getAttribute("data-id")
								: null,
						event: "change",
						param:
							event.target.closest(".control__param") !== null
								? event.target.closest(".control__param").getAttribute("data-param")
								: null,
					};
				} else if (event.target.closest(".mode__zone")) {
					payload = {
						value: event.target.checked,
						name: event.target.getAttribute("name"),
						zone: +event.target.closest(".mode__zone").getAttribute("data-id"),
						event: "change",
					};
				} else {
					payload = {
						value: event.target.checked,
						name: event.target.getAttribute("name"),
						event: "change",
					};
				}
			}

			if (element === "checkbox") {
				payload = {
					event: "change",
					name: event.target.closest(".checkbox").getAttribute("name"),
					day: +event.target.closest(".checkbox").getAttribute("data-day"),
					value: event.target.checked,
				};
			}

			if (element === "daypicker") {
				if (event.target.closest(".daypicker__item")) {
					payload = {
						event: "change",
						type: "day",
						stage: event.target.closest(".daypicker").getAttribute("data-stage"),
						value: +event.target.closest(".daypicker__item").getAttribute("data-id"),
						num: +event.target.closest(".daypicker__item").getAttribute("data-num"),
					};
				}
			}

			if (element === "timepicker") {
				if (event.target.closest(".timepicker__item")) {
					payload = {
						event: "change",
						type: "time",
						startID: +event.target.closest(".mode__block_start").getAttribute("data-id"),
						stage: event.target.closest(".timepicker").getAttribute("data-stage"),
						unit: event.target.closest(".timepicker__list").getAttribute("data-unit"),
						value: parseInt(event.target.closest(".timepicker__item").innerHTML),
					};
				}
			}

			if (element === "file") {
				payload = {
					value: event.target.files[0],
					name: event.target.getAttribute("name"),
					event: "change",
				};
			}

			if (element === "button") {
				if (event.target.closest("section").className == "modes") {
					if (event.target.closest(".mode__commissioning")) {
						payload = {
							name: event.target.closest(".button").getAttribute("name"),
							event: "change",
						};
					} else {
						payload = {
							name: event.target.closest(".button").getAttribute("name"),
							startId: +event.target.closest(".mode__block_start").getAttribute("data-id"),
							event: "change",
						};
					}
				} else {
					payload = {
						value: true,
						name: event.target.getAttribute("name"),
						event: "change",
					};
				}
			}

			payload.element = element;
			payload.section = event.target.closest("section").className;

			if (payload.section === "modes") payload.id = +event.target.closest(".mode").id;

			this.handleSystemChanges(payload);
		},
		cancelChanges(event, param) {
			let payload = {
				param: param,
			};

			if (param === "zone") {
				payload.id = +event.target.closest(".zone").getAttribute("data-id");
			}

			this.cancelSystemChanges(payload);
		},
		sendChanges(payload) {
			this.sendSystemChanges(payload);
		},
	},
	created() {
		this.$soketio.on(`system/${this.$route.params.id}/state`, (data) => {
			if (this.$route.params.id == data.id) this.updateSystemState(data);
		});

		this.$soketio.on(`system/${this.$route.params.id}/control`, (data) => {
			if (this.$route.params.id == data.id && this.control.cancellation) {
				this.updateSystemControl(data);
			}
		});

		this.$soketio.on(`system/${this.$route.params.id}/firmware`, (data) => {
			if (this.$route.params.id == data.id) {
				this.updateSystemFirmware(data);
			}
		});

		this.$soketio.on(`system/${this.$route.params.id}/logs`, (data) => {
			if (data.transfer) this.notifyLogs(data.transfer);
		});
	},
};
</script>

<style lang="scss" scoped>
.system {
	&__settings {
		display: flex;
		flex-wrap: wrap;
	}

	&__left {
		width: 100%;
		margin-top: 15px;

		@include mediaquery(md) {
			width: 50%;
			padding-right: 8px;
		}
	}

	&__right {
		width: 100%;
		margin-top: 15px;

		@include mediaquery(md) {
			width: 50%;
			padding-left: 8px;
		}
	}

	.config,
	.state,
	.control,
	.modes,
	.features,
	.firmware,
	.logs {
		@include base;

		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 20px;

		&__header {
			display: flex;
			justify-content: space-between;
			width: 100%;
			cursor: pointer;
		}

		&__wrapper {
			display: flex;
			align-items: center;
			width: 100%;
		}

		&__name {
			font-size: 12px;
			font-weight: 500;
			color: #222325;
			text-transform: uppercase;
		}

		&__notification {
			margin-left: 10px;
			display: flex;
			align-items: center;

			&-date {
				font-size: 9px;
				font-weight: 500;
				background-color: #41c6eb;
				color: #fff;
				border-radius: 10px;
				padding: 3px 10px;
				margin-right: 5px;
			}

			&-text {
				font-size: 10px;
				color: #41c6eb;
			}
		}

		&__control {
			display: flex;
			align-items: center;
		}

		&__button {
			position: relative;
			background: none;
			padding: 0;
			border: 0;
			height: 11px;
			width: 11px;
			cursor: pointer;
			margin-left: 10px;

			&-icon {
				position: absolute;
				left: 2px;
				top: 0px;
				width: 7px;
				height: 7px;
				border: solid #bcbcbc;
				border-width: 0 2px 2px 0;
				transform: rotate(45deg);

				&_rotate {
					border-color: #222325;
					top: 3px;
					transform: rotate(225deg);
				}
			}
		}

		&__footer {
			display: flex;
			justify-content: flex-end;
			margin-top: 20px;

			button {
				&:first-of-type {
					margin-right: 10px;
				}
			}
		}
	}

	.config {
		&__main {
			display: flex;
			flex-wrap: wrap;
			margin-top: 15px;

			@include mediaquery(sm) {
				margin-left: -5px;
				margin-right: -5px;
				margin-bottom: -5px;
			}

			@include mediaquery(md) {
				width: auto;
				margin-top: -5px;
				margin-left: -5px;
				margin-right: -5px;
			}
		}

		&__soft {
			width: 100%;
			margin-top: 20px;

			@include mediaquery(md) {
				width: 50%;
			}

			@include mediaquery(lg) {
				width: 40%;
			}

			@include mediaquery(xl) {
				width: 30%;
			}
		}

		&__wrapper {
			display: flex;
			width: 65%;

			@include mediaquery(md) {
				width: auto;
				flex-direction: row;
				align-items: center;
			}
		}

		&__info {
			display: flex;
			flex-direction: column;
			align-items: flex-end;

			@include mediaquery(md) {
				flex-direction: row;
				align-items: center;
			}
		}

		&__mileage {
			font-size: 12px;
			font-weight: 500;
		}

		&__state {
			@include state;

			@include mediaquery(md) {
				margin: 0 0 0 10px;
			}
		}

		&__control {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			width: 35%;
			margin-left: 10px;

			@include mediaquery(sm) {
				width: 25%;
			}
		}

		&__params {
			display: flex;
			flex-wrap: wrap;
		}

		&__password {
			display: flex;
			flex-wrap: wrap;
			margin-left: 10px;
		}

		&__param {
			width: 49%;
			margin-bottom: 5px;
			font-size: 10px;
			color: #222325;
		}

		&__blocks {
			margin-top: 20px;
		}

		&__block {
			width: 100%;
			margin-bottom: 10px;

			&:last-of-type {
				margin-bottom: 0;
			}

			@include mediaquery(sm) {
				width: calc(50% - 10px);
				margin: 5px;
			}

			@include mediaquery(lg) {
				width: calc(33.333333% - 10px);
				margin: 5px;
			}

			@include mediaquery(xl) {
				width: calc(16.666666% - 10px);
			}
		}

		&__title {
			@include title;
		}

		&__footer {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;

			button {
				width: 100%;

				&:first-of-type {
					margin-bottom: 10px;
				}
			}

			@include mediaquery(sm) {
				flex-direction: row;

				button {
					width: auto;
					margin-bottom: 0;

					&:first-of-type {
						margin-right: 10px;
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.state {
		margin-bottom: 15px;

		&__params {
			display: flex;
			flex-wrap: wrap;
			margin-top: 15px;

			@include mediaquery(sm) {
				margin-left: -5px;
				margin-right: -5px;
				margin-bottom: -5px;
			}
		}

		&__block {
			width: 100%;
			margin-bottom: 10px;

			@include mediaquery(sm) {
				margin: 5px;
				width: calc(50% - 10px);
			}

			@include mediaquery(md) {
				width: 100%;
			}

			@include mediaquery(lg) {
				margin: 5px;
				width: calc(50% - 10px);
			}
		}

		&__title {
			@include title;
		}

		&__subtitle {
			font-size: 12px;
			font-weight: 500;
			color: #222325;
			margin: 18px 0 15px;
		}

		&__zones {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;

			@include mediaquery(sm) {
				margin-left: -5px;
				margin-right: -5px;
				margin-bottom: 0;
			}

			@include mediaquery(md) {
				margin: 0;
			}

			@include mediaquery(lg) {
				margin-left: -5px;
				margin-right: -5px;
			}
		}

		&__zone {
			@include base;

			width: 100%;
			margin-bottom: 10px;
			padding: 10px;
			display: flex;
			flex-wrap: wrap;

			&:last-of-type {
				margin-bottom: 0;
			}

			@include mediaquery(sm) {
				width: calc(50% - 10px);
				margin: 5px;
			}

			@include mediaquery(md) {
				width: 100%;
				margin: 0;
				margin-bottom: 10px;
			}

			@include mediaquery(lg) {
				width: calc(50% - 10px);
				margin: 5px;
			}

			&-name {
				width: 100%;
				font-size: 12px;
				font-weight: 500;
				color: #2b1a0a;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				text-align: center;
			}

			&-status {
				font-size: 9px;
				font-weight: 500;
				background-color: #41c6eb;
				color: #fff;
				border-radius: 10px;
				padding: 3px 10px;
				margin-left: 10px;
				min-width: 75px;
				text-align: center;

				&_error {
					background-color: #ff8068;
				}
			}

			&-params {
				width: 100%;
				display: flex;
				flex-direction: column;
				margin-top: 10px;
			}

			&-param {
				display: flex;
				justify-content: space-between;
				margin-bottom: 10px;

				&:last-of-type {
					margin-bottom: 0;
				}
			}

			&-modes {
				margin-top: 10px;
				width: 100%;
			}

			&-mode {
				@include base;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 10px;
				padding: 8px;

				&:last-of-type {
					margin-bottom: 0;
				}

				input {
					width: 75px;
					height: 26px;
				}
			}

			&-work,
			&-pause {
				font-size: 9px;
				font-weight: 500;
				background-color: #41c6eb;
				color: #fff;
				border-radius: 10px;
				padding: 3px 10px;
				margin-right: 10px;
				min-width: 50px;
				text-align: center;
			}
		}

		&__board {
			@include base;

			width: 100%;
			min-height: 46px;
			margin-bottom: 10px;
			padding: 10px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			&:last-of-type {
				margin-bottom: 0;
			}

			&-status {
				font-size: 9px;
				font-weight: 500;
				background-color: #41c6eb;
				color: #fff;
				border-radius: 10px;
				padding: 3px 10px;
				margin-right: 10px;
			}

			input {
				width: 75px;
				height: 26px;
			}
		}

		&__network {
			@include base;

			width: 100%;
			min-height: 46px;
			margin-bottom: 10px;
			padding: 10px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			&:last-of-type {
				margin-bottom: 0;
			}

			&-status {
				font-size: 9px;
				font-weight: 500;
				color: #fff;
				border-radius: 10px;
				padding: 3px 10px;
				margin-right: 10px;

				&_connecting {
					background-color: #ccc;
				}

				&_connected {
					background-color: #41c6eb;
				}

				&_disconnected {
					background-color: #ff8068;
				}
			}

			&-indicator {
				width: 13px;
				min-width: 13px;
				height: 13px;
				border-radius: 50%;

				&_default {
					background-color: #26ba11;
				}

				&_blink {
					background-color: #31f015;
				}

				&_off {
					background-color: #d2151c;
				}
			}
		}

		&__errors {
			display: flex;
			flex-wrap: wrap;
		}

		&__error {
			@include base;

			padding: 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 10px;
			width: 100%;

			&:last-of-type {
				margin-bottom: 0;
			}

			&-wrapper {
				display: flex;
				align-items: center;
			}

			&-dot {
				background-color: #ff5e3f;
				width: 4px;
				height: 4px;
				display: inline-block;
				border-radius: 50%;
				margin-right: 9px;
			}

			&-date {
				font-size: 9px;
				color: #000000;
			}

			&-text {
				font-size: 12px;
				color: #ff5e3f;
				text-transform: uppercase;
			}
		}
	}

	.control {
		margin-bottom: 15px;

		&__state {
			@include base;

			width: 100%;
			min-height: 46px;
			margin-top: 18px;
			padding: 15px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&:last-of-type {
				margin-bottom: 0;
			}

			&-status {
				font-size: 9px;
				font-weight: 500;
				background-color: #41c6eb;
				color: #fff;
				border-radius: 10px;
				padding: 3px 10px;

				&_error {
					background-color: #ff8068;
				}
			}
		}

		&__subjects {
			position: relative;

			&_inactive {
				.control__screen {
					z-index: 100;
				}

				.control__block {
					opacity: 0.2;
				}

				.control__head {
					opacity: 0.2;
				}
			}
		}

		&__screen {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: -1;
		}

		&__zones {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			margin-top: 10px;
			padding-bottom: 30px;

			@include mediaquery(sm) {
				margin-left: -5px;
				margin-right: -5px;
			}

			@include mediaquery(md) {
				margin: 0;
				margin-top: 10px;
			}

			@include mediaquery(lg) {
				margin-left: -5px;
				margin-right: -5px;
			}

			@include mediaquery(xl) {
				.control__block {
					width: calc(50% - 10px);
				}
			}

			.control__block {
				flex-wrap: wrap;
			}

			.control__title {
				width: 100%;
				font-size: 12px;
				font-weight: 500;
				color: #2b1a0a;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				text-align: center;
			}

			.control__params {
				width: 100%;
				display: flex;
				flex-direction: column;
				margin-top: 10px;
			}

			.control__param {
				display: flex;
				justify-content: space-between;
				margin-bottom: 10px;

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}

		&__main {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			padding-top: 30px;
			border-top: 1px solid #e5e5e5;

			@include mediaquery(sm) {
				margin-left: -5px;
				margin-right: -5px;
				justify-content: space-between;
			}

			@include mediaquery(md) {
				margin: 0;
			}

			@include mediaquery(lg) {
				margin-left: -5px;
				margin-right: -5px;
			}
		}

		&__block {
			@include base;

			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px 15px;
			width: 100%;
			margin-bottom: 10px;

			&:last-of-type {
				margin-bottom: 0;
			}

			@include mediaquery(sm) {
				width: calc(50% - 10px);
				margin: 5px;
			}

			@include mediaquery(md) {
				width: 100%;
				margin: 0;
				margin-bottom: 10px;
			}

			@include mediaquery(lg) {
				width: calc(50% - 10px);
				margin: 5px;
			}

			.switch {
				margin-left: 10px;
			}
		}

		&__title {
			font-size: 12px;
			color: #2b1a0a;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&__footer {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;

			button {
				width: 100%;

				&:first-of-type {
					margin-bottom: 10px;
				}
			}

			@include mediaquery(sm) {
				flex-direction: row;

				button {
					width: auto;
					margin-bottom: 0;

					&:first-of-type {
						margin-right: 10px;
						margin-bottom: 0;
					}
				}
			}

			@include mediaquery(md) {
				flex-direction: column;

				button {
					width: 100%;

					&:first-of-type {
						margin-bottom: 10px;
					}
				}
			}

			@include mediaquery(lg) {
				flex-direction: row;

				button {
					width: auto;
					margin-bottom: 0;

					&:first-of-type {
						margin-right: 10px;
						margin-bottom: 0;
					}
				}
			}
		}

		&__head {
			display: flex;
			justify-content: space-between;
			font-size: 12px;
			color: #2b1a0a;
			padding: 15px;
			padding-bottom: 5px;
			margin-top: 5px;
			margin-bottom: 15px;
			border-bottom: 1px solid #dcdcdc;

			&-wrapper {
				display: flex;

				input {
					width: 75px;
					height: 26px;

					&:first-of-type {
						margin-right: 10px;
					}
				}
			}

			&-time1,
			&-time2 {
				width: 75px;
			}

			&-time1 {
				margin-right: 10px;
			}
		}
	}

	.modes {
		margin-top: 15px;

		&__body {
			margin-top: 10px;
		}

		&__list {
			padding: 0;
			margin: 0;
		}

		&__footer {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;

			button {
				width: 100%;

				&:first-of-type {
					margin-bottom: 10px;
				}
			}

			@include mediaquery(sm) {
				flex-direction: row;

				button {
					width: auto;
					margin-bottom: 0;

					&:first-of-type {
						margin-right: 10px;
						margin-bottom: 0;
					}
				}
			}

			@include mediaquery(md) {
				flex-direction: column;

				button {
					width: 100%;

					&:first-of-type {
						margin-bottom: 10px;
					}
				}
			}

			@include mediaquery(lg) {
				flex-direction: row;

				button {
					width: auto;
					margin-bottom: 0;

					&:first-of-type {
						margin-right: 10px;
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.mode {
		@include base;

		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 10px;
		margin-bottom: 10px;

		&:last-of-type {
			margin-bottom: 0;
		}

		&__header {
			display: flex;
			justify-content: space-between;
			width: 100%;
			cursor: pointer;
		}

		&__wrapper {
			display: flex;
			align-items: center;
			width: 100%;
		}

		&__name {
			font-size: 12px;
			font-weight: 500;
			color: #222325;
			text-transform: uppercase;
		}

		&__control {
			display: flex;
			align-items: center;
		}

		&__button {
			position: relative;
			background: none;
			padding: 0;
			border: 0;
			height: 11px;
			width: 11px;
			cursor: pointer;
			margin-left: 10px;

			&-icon {
				position: absolute;
				left: 2px;
				top: 0px;
				width: 7px;
				height: 7px;
				border: solid #bcbcbc;
				border-width: 0 2px 2px 0;
				transform: rotate(45deg);

				&_rotate {
					border-color: #222325;
					top: 3px;
					transform: rotate(225deg);
				}
			}
		}

		&__subjects {
			position: relative;

			&_inactive {
				.mode__screen {
					z-index: 100;
				}

				.mode__main,
				.mode__settings,
				.mode__zones {
					opacity: 0.2;
				}
			}
		}

		&__settings {
			display: flex;
			flex-direction: column;
			margin-top: 10px;
		}

		&__commissioning {
			display: flex;
			flex-direction: column;

			@include mediaquery(sm) {
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
			}

			@include mediaquery(md) {
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
			}

			@include mediaquery(lg) {
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
			}

			button {
				width: 100%;
				margin-bottom: 10px;

				&:last-of-type {
					margin-bottom: 0;
				}

				@include mediaquery(sm) {
					width: calc(50% - 5px);
					margin-bottom: 0;
				}

				@include mediaquery(md) {
					width: 100%;
					margin-bottom: 10px;
				}

				@include mediaquery(lg) {
					width: calc(50% - 5px);
					margin-bottom: 0;
				}
			}
		}

		&__subtitle {
			font-size: 12px;
			font-weight: 500;
			color: #222325;
			margin: 18px 0 15px;
			text-align: left;
			width: 100%;
		}

		&__status {
			@include base;

			width: 100%;
			min-height: 46px;
			padding: 10px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 9px;
			border-radius: 10px;
		}

		&__screen {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: -1;
		}

		&__main {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding-top: 20px;
		}

		&__column {
			display: flex;
			flex-wrap: wrap;
			width: 100%;

			&:first-of-type {
				margin-bottom: 10px;
			}

			@include mediaquery(xl) {
				width: calc(50% - 5px);

				&:first-of-type {
					flex-direction: column;
					margin-bottom: 0;
				}
			}
		}

		&__title {
			font-size: 12px;
			text-transform: uppercase;
			margin-bottom: 12px;
		}

		&__days {
			display: flex;
			width: 100%;
		}

		&__day {
			display: flex;
			flex-direction: column;
			margin-right: 10px;
			text-align: center;

			&:last-of-type {
				margin-right: 0;
			}

			&-name {
				margin-top: 7px;
				font-size: 12px;
			}
		}

		&__blocks {
			@include base;
			width: 100%;
		}

		&__block {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px;

			&_start {
				padding-top: 5px;
				padding-bottom: 5px;

				&:first-of-type {
					padding-top: 10px;
				}
			}
		}

		&__subwrapper {
			display: flex;
			align-items: center;

			button {
				height: 28px;
				margin-left: 10px;
			}
		}

		&__stage {
			font-weight: 500;
			font-size: 12px;
			color: #222325;
			text-transform: uppercase;
		}

		&__input,
		&__picker {
			width: 60px;
		}

		&__zones {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			margin-top: 10px;

			@include mediaquery(sm) {
				margin-left: -5px;
				margin-right: -5px;
			}

			@include mediaquery(md) {
				margin: 0;
				margin-top: 10px;
			}

			@include mediaquery(lg) {
				margin-left: -5px;
				margin-right: -5px;
				margin-bottom: -5px;
			}
		}

		&__zone {
			@include base;

			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px 15px;
			width: 100%;
			margin-bottom: 10px;

			&:last-of-type {
				margin-bottom: 0;
			}

			@include mediaquery(sm) {
				width: calc(50% - 10px);
				margin: 5px;
			}

			@include mediaquery(md) {
				width: 100%;
				margin: 0;
				margin-bottom: 10px;
			}

			@include mediaquery(lg) {
				width: calc(50% - 10px);
				margin: 5px;
			}

			.mode__title {
				font-size: 12px;
				font-weight: 500;
				color: #2b1a0a;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin-bottom: 0;
			}

			.switch {
				margin-left: 10px;
			}
		}

		&__set {
			display: flex;
			align-items: center;

			input {
				width: 60px;
			}
		}
	}

	.features {
		&__blocks {
			display: flex;
			flex-wrap: wrap;
			margin-top: 15px;

			@include mediaquery(sm) {
				margin-left: -5px;
				margin-right: -5px;
				margin-bottom: -5px;
			}
		}

		&__block {
			width: 100%;
			margin-bottom: 10px;
			position: relative;

			&_invisible {
				display: none;
			}

			@include mediaquery(sm) {
				width: calc(50% - 10px);
				margin: 5px;
			}

			@include mediaquery(md) {
				width: 100%;
			}

			@include mediaquery(lg) {
				width: calc(50% - 10px);
				margin: 5px;
			}
		}

		&__title {
			@include title;
		}

		&__error {
			position: absolute;
			left: 0;
			top: 100%;
			font-size: 9px;
			color: #ff8068;
		}

		&__footer {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;

			button {
				width: 100%;

				&:first-of-type {
					margin-bottom: 10px;
				}
			}

			@include mediaquery(sm) {
				flex-direction: row;

				button {
					width: auto;
					margin-bottom: 0;

					&:first-of-type {
						margin-right: 10px;
						margin-bottom: 0;
					}
				}
			}

			@include mediaquery(md) {
				flex-direction: column;

				button {
					width: 100%;

					&:first-of-type {
						margin-bottom: 10px;
					}
				}
			}

			@include mediaquery(lg) {
				flex-direction: row;

				button {
					width: auto;
					margin-bottom: 0;

					&:first-of-type {
						margin-right: 10px;
						margin-bottom: 0;
					}
				}
			}
		}

		&__error {
			position: absolute;
			left: 0;
			top: 100%;
			font-size: 9px;
			color: #ff8068;
		}
	}

	.firmware {
		&__content {
			position: relative;

			&_inactive {
				.firmware__screen {
					z-index: $zIndexScreen;
				}
				.firmware__preloader {
					display: block;
				}

				.firmware__version,
				.firmware__title,
				.firmware__file,
				.dropdown {
					opacity: 0.2;
				}
			}
		}

		&__screen {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: -1;
		}

		&__preloader {
			position: absolute;
			width: 80px;
			height: 80px;
			left: calc(50% - 40px);
			top: calc(50% - 40px);
			z-index: $zIndexScreen + 1;
			display: none;
		}

		&__version {
			font-size: 12px;
			font-weight: 500;
			color: #222325;
			margin: 18px 0 15px;
		}

		&__main {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		&__column {
			display: flex;
			flex-wrap: wrap;
			width: 100%;

			&:first-of-type {
				margin-bottom: 10px;
			}

			@include mediaquery(sm) {
				width: calc(50% - 5px);

				&:first-of-type {
					margin-bottom: 0;
				}
			}

			@include mediaquery(md) {
				width: 100%;

				&:first-of-type {
					margin-bottom: 10px;
				}
			}

			@include mediaquery(lg) {
				width: calc(50% - 5px);

				&:first-of-type {
					margin-bottom: 0;
				}
			}
		}

		&__title {
			font-size: 12px;
			margin-bottom: 4px;
			margin-left: 5px;
			color: inherit;
		}

		&__upload {
			display: flex;
			flex-direction: column;
			width: 100%;
		}

		&__file {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			font-size: 18px;

			&-text {
				height: 36px;
				line-height: 36px;
				width: calc(100% - 46px);
				padding-left: 12px;
				padding-right: 10px;
				font-family: "Avenir";
				font-size: 12px;
				border: 1px solid transparent;
				border-radius: 6px;
				background-color: $colorMainLight;
				box-shadow: $shadowLight;
				color: #222325;

				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&-input {
				position: absolute;
				width: 0;
				height: 0;
				opacity: 0;
				overflow: hidden;
				z-index: -1;
			}

			&-label {
				margin: 0 10px;
			}

			&-action {
				@include base;

				display: flex;
				align-items: center;
				justify-content: center;
				height: 36px;
				width: 36px;
				color: #41c6eb;
				cursor: pointer;
			}
		}

		&__warning {
			@include base;
			margin-top: 10px;

			padding: 10px 20px;
			display: flex;
			flex-direction: column;
			width: 100%;
			color: #ff8068;
		}

		&__footer {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;

			button {
				width: 100%;

				&:first-of-type {
					margin-bottom: 10px;
				}
			}

			@include mediaquery(sm) {
				flex-direction: row;

				button {
					width: auto;
					margin-bottom: 0;

					&:first-of-type {
						margin-right: 10px;
						margin-bottom: 0;
					}
				}
			}

			@include mediaquery(md) {
				flex-direction: column;

				button {
					width: 100%;

					&:first-of-type {
						margin-bottom: 10px;
					}
				}
			}

			@include mediaquery(lg) {
				flex-direction: row;

				button {
					width: auto;
					margin-bottom: 0;

					&:first-of-type {
						margin-right: 10px;
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.logs {
		margin-top: 15px;

		&__load {
			width: 100%;
			display: flex;
			flex-direction: column;
			margin-top: 10px;
		}

		&__subhead {
			font-size: 12px;
			font-weight: 500;
			color: #222325;
			margin: 0 0 15px;
		}

		&__blocks {
			display: flex;
			justify-content: space-between;
		}

		&__block {
			width: calc(50% - 5px);
		}

		&__title {
			@include title;
		}

		&__list {
			list-style: none;
			padding: 0;
		}

		.ps {
			max-height: 200px;
			padding: 0 10px;
		}

		&__item {
			padding: 5px;
			border-bottom: 1px solid #dcdcdc;
			font-size: 12px;
			font-weight: 400;

			&:first-of-type {
				border-top: 1px solid #dcdcdc;
			}
		}

		&__type {
			display: inline-block;
			text-transform: uppercase;
			min-width: 180px;

			&_notice {
				color: #3cb371;
			}

			&_emerg,
			&_crit,
			&_error {
				color: #ff7156;
			}

			&_alert,
			&_warn {
				color: #ff8c00;
			}

			&_info,
			&_debug {
				color: #1e90ff;
			}
		}

		&__warning {
			@include base;
			margin-top: 10px;

			padding: 10px 20px;
			display: flex;
			flex-direction: column;
			width: 100%;
			color: #ff8068;
		}

		&__empty {
			padding: 15px 0 0;
			text-align: center;
			font-weight: 500;
			font-size: 12px;
			color: #222325;
			text-transform: uppercase;
		}

		&__loading {
			button {
				margin-top: 10px;
				width: 100%;
			}
		}

		&__footer {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;

			button {
				width: 100%;

				&:first-of-type {
					margin-bottom: 10px;
				}
			}

			@include mediaquery(sm) {
				flex-direction: row;

				button {
					width: auto;
					margin-bottom: 0;

					&:first-of-type {
						margin-right: 10px;
						margin-bottom: 0;
					}
				}
			}

			@include mediaquery(md) {
				flex-direction: column;

				button {
					width: 100%;

					&:first-of-type {
						margin-bottom: 10px;
					}
				}
			}

			@include mediaquery(lg) {
				flex-direction: row;

				button {
					width: auto;
					margin-bottom: 0;

					&:first-of-type {
						margin-right: 10px;
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
</style>
