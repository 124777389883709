<template>
  <div class="notification" v-if="notification.visible">
    <div class="notification__info">
      <div class="notification__title">{{ notification.title }}</div>
      <div class="notification__text">{{ notification.text }}</div>
    </div>
    <div class="notification__control" v-if="notification.control">
      <button class="notification__ok">Ок</button>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: "Notification",
    computed: {
      ...mapGetters({
        notification: "getCommonNotification",
      }),
    },
  };
</script>

<style lang="scss" scoped>
  .notification {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 6px;
    background-color: #ffcb3b;
    box-shadow: $shadowLight;

    &__title {
      font-size: 14px;
      font-weight: 700;
      color: #2b1a0a;
      text-transform: uppercase;
      margin-bottom: 5px;
    }

    &__text {
      font-size: 12px;
      color: #2b1a0a;
    }

    &__strong {
      font-weight: 700;
    }

    &__control {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;

      @include mediaquery(sm) {
        flex-direction: row;
      }

      @include mediaquery(md) {
        flex-direction: column;
        width: 27%;
      }

      @include mediaquery(lg) {
        flex-direction: row;
        width: auto;
      }
    }

    &__link {
      text-decoration: none;
      background-color: #222325;
      font-size: 12px;
      font-weight: 500;
      color: #fff;
      border-radius: 6px;
      margin-bottom: 10px;
      height: 36px;
      padding: 10px 13px;
      width: 100%;
      text-align: center;

      @include mediaquery(sm) {
        width: 50%;
        margin-right: 10px;
        margin-bottom: 0;
      }

      @include mediaquery(md) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }

      @include mediaquery(lg) {
        width: auto;
        margin-right: 10px;
        margin-bottom: 0;
      }
    }

    &__ok {
      width: 100%;
      height: 36px;
      background-color: #fff;
      border-radius: 6px;
      border: 0;
      font-size: 12px;
      color: #e8b423;
      cursor: pointer;

      @include mediaquery(sm) {
        width: 50%;
      }

      @include mediaquery(md) {
        width: 100%;
      }

      @include mediaquery(lg) {
        width: 36px;
      }
    }
  }
</style>
