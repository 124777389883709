<template>
  <div class="slider" :data-type="type">
    <input
      class="slider__range"
      type="range"
      :min="min"
      :max="max"
      :name="name"
      :value="value"
      :disabled="this.disabled"
    />
  </div>
</template>

<script>
  export default {
    name: "Slider",
    props: {
      type: String,
      min: String,
      max: String,
      name: String,
      value: Number,
      disabled: Boolean,
    },
  };
</script>

<style lang="scss" scoped>
  .slider {
    $sliderMinSize: 80px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: inset 0px 5px 8px #dcdcdc;

    &[data-type="horizontal"] {
      display: flex;
      align-items: center;
      height: 16px;
      padding: 5px 8px;
    }

    &[data-type="vertical"] {
      display: inline-block;
      width: 16px;
      min-height: $sliderMinSize + 10px;
      padding: 2px 5px;

      .slider__range {
        transform-origin: ($sliderMinSize / 2 - 4) ($sliderMinSize / 2 - 4);
        transform: rotate(-90deg);
        width: 100%;
      }
    }

    &__range {
      -webkit-appearance: none;
      min-width: $sliderMinSize;
      width: 100%;
      height: 3px;
      margin: 0;
      border-radius: 1.5px;
      background-color: #b3b3b3;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 30px;
        width: 30px;
        border: 12px solid #fff;
        border-radius: 50%;
        background: #b3b3b3;
        cursor: pointer;
        box-shadow: 0 0 1px 0 #555;
        transition: box-shadow 0.3s ease-in-out;

        @include mediaquery(md) {
          height: 25px;
          width: 25px;
          border: 10px solid #fff;
        }

        &:hover {
          box-shadow: 0 0 4px 0 #555;
        }
      }

      &::-moz-range-thumb {
        -webkit-appearance: none;
        height: 30px;
        width: 30px;
        border: 12px solid #fff;
        border-radius: 50%;
        background: #b3b3b3;
        cursor: pointer;
        box-shadow: 0 0 1px 0 #555;
        transition: box-shadow 0.3s ease-in-out;
        box-sizing: border-box !important;

        &:hover {
          box-shadow: 0 0 4px 0 #555;
        }
      }

      &:-ms-thumb {
        -webkit-appearance: none;
        height: 30px;
        width: 30px;
        border: 12px solid #fff;
        border-radius: 50%;
        background: #b3b3b3;
        cursor: pointer;
        box-shadow: 0 0 1px 0 #555;
        transition: box-shadow 0.3s ease-in-out;

        &:hover {
          box-shadow: 0 0 4px 0 #555;
        }
      }

      &::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
      }

      &::-moz-range-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
      }

      &::-ms-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
      }
    }
  }
</style>
