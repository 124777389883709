<template>
  <div class="invite">
    <div class="invite__wrapper">
      <img class="invite__logo" src="../assets/images/logo.svg" alt="" />
      <div class="invite__title">Приглашение</div>
      <div class="invite__forms">
        <form class="invite__form" @submit.prevent>
          <div class="invite__form-body">
            <div class="invite__form-headline">
              При регистраци была указана электронная почта:
              <span class="invite__form-value">{{ email.value }}</span>
            </div>
            <div class="invite__form-text">
              Введите четырехзначный код из письма, пришедшего на вашу
              электронную почту для подтверждения.
            </div>
            <div class="invite__form-block">
              <div
                class="invite__form-code"
                name="code"
                @input="handleChange($event, 'email')"
              >
                <Singleinput
                  v-for="input in email.code.numbers"
                  :key="input.id"
                  :data-id="input.id"
                  :value="input.value"
                  :status="input.status"
                  :disabled="email.code.disabled"
                  @blur="handleChange($event, 'email')"
                />
              </div>
              <span class="invite__form-error" v-if="email.code.error">{{
                email.code.error
              }}</span>
            </div>
          </div>
          <div class="invite__form-footer">
            <Button
              :type="'text'"
              :value="'Подтвердить'"
              :paint="'blue'"
              :disabled="!email.validation"
              @click="handleFormOneSumbit"
            />
          </div>
        </form>
        <form class="invite__form" @submit.prevent>
          <div class="invite__form-body">
            <div class="invite__form-headline">
              При регистраци был указан номер телефона:
              <span class="invite__form-value">{{ phone.value }}</span>
            </div>
            <div class="invite__form-text">
              На указанный номер телефона поступит звонок. Введите последние 4
              цифры номера телефона входящего вызова для подтверждения.
            </div>
            <div class="invite__form-block">
              <div
                class="invite__form-code"
                name="code"
                @input="handleChange($event, 'phone')"
              >
                <Singleinput
                  v-for="input in phone.code.numbers"
                  :key="input.id"
                  :data-id="input.id"
                  :value="input.value"
                  :status="input.status"
                  :disabled="phone.code.disabled"
                  @blur="handleChange($event, 'phone')"
                />
              </div>
              <span class="invite__form-error" v-if="phone.code.error">{{
                phone.code.error
              }}</span>
            </div>
            <div class="invite__form-text" v-if="countdown.show">
              если вам не поступил звонок, попробуйте
              <button
                class="invite__form-button"
                :disabled="countdown.timer > 0"
                @click.prevent="repeatPhoneCall"
              >
                еще раз
              </button>
              через {{ countdown.timer }} сек.
            </div>
          </div>
          <div class="invite__form-footer">
            <Button
              :type="'text'"
              :value="'Подтвердить'"
              :paint="'blue'"
              :disabled="!phone.validation"
              @click="handleFormTwoSumbit"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from "vuex";

  import Button from "../components/Button";
  import Singleinput from "../components/Singleinput";

  export default {
    name: "Invite",
    components: {
      Button,
      Singleinput,
    },
    computed: {
      ...mapGetters({
        email: "getInviteEmail",
        phone: "getInvitePhone",
        countdown: "getInviteСountdown",
      }),
    },
    methods: {
      ...mapMutations(["handleInviteChanges"]),
      ...mapActions([
        "fetchInvite",
        "confirmInviteEmail",
        "confirmInvitePhone",
        "repeatInviteCall",
        "logoutAuthorization",
      ]),
      handleChange(event, element) {
        let payload = {};

        let current = event.target;
        let next = current.nextElementSibling;

        if (current.value.length > 0) {
          if (current.value) current.blur();
          if (next !== null) next.focus();
        }

        payload = {
          element: element,
          id: +event.target.getAttribute("data-id"),
          event: event.type,
          name: event.target.closest(".invite__form-code").getAttribute("name"),
          value: event.target.value,
        };

        this.handleInviteChanges(payload);
      },
      handleFormOneSumbit() {
        this.confirmInviteEmail();
      },
      handleFormTwoSumbit() {
        this.confirmInvitePhone();
      },
      repeatPhoneCall() {
        this.repeatInviteCall();
      },
    },
    created() {
      this.logoutAuthorization();
      this.fetchInvite();
    },
  };
</script>

<style lang="scss" scoped>
  .invite {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;

    @include mediaquery(md) {
      min-height: calc(100vh - 20px);
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    &__logo {
      width: 50px;
      margin-bottom: 24px;

      @include mediaquery(sm) {
        margin-bottom: 44px;
      }
    }

    &__title {
      width: 100%;
      margin-bottom: 20px;
      text-transform: uppercase;
      text-align: center;
      font-size: 24px;
    }

    &__forms {
      @include base;

      display: flex;
      flex-direction: column;
      padding: 20px;

      @include mediaquery(md) {
        flex-direction: row;
        width: 720px;
        padding: 46px 40px 25px;
      }
    }

    &__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include mediaquery(md) {
        width: 50%;
      }

      &:first-of-type {
        padding-bottom: 20px;
        border-bottom: 1px solid #e1e1e1;

        @include mediaquery(md) {
          padding-right: 40px;
          padding-bottom: 0;
          border-right: 1px solid #e1e1e1;
          border-bottom: 0;
        }

        .invite__form-body {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .invite__form-footer {
          justify-content: center;
        }
      }

      &:last-of-type {
        padding-top: 20px;

        @include mediaquery(md) {
          padding-left: 40px;
          padding-top: 0;
        }

        .invite__form-body {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .invite__form-footer {
          justify-content: center;
        }
      }

      &-headline {
        font-size: 12px;
        text-align: center;
        color: #222325;
        margin-bottom: 20px;
      }

      &-value {
        font-weight: 500;
      }

      &-block {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 10px;
        position: relative;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &-subblock {
        display: flex;
        align-items: center;
      }

      &-title {
        @include title;
      }

      &-code {
        display: flex;
        justify-content: space-between;
        width: 180px;
      }

      &-error {
        position: absolute;
        top: 100%;
        font-size: 9px;
        color: #ff8068;
      }

      &-button {
        color: #41c6eb;
        border: 0;
        background: none;
        padding: 0;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        &:disabled {
          pointer-events: none;
        }
      }

      &-text {
        font-size: 12px;
        text-align: center;
        color: #222325;
        margin-bottom: 40px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 27px;

        button {
          width: 100%;
        }
      }
    }

    &__notice {
      font-size: 7px;
      margin-top: 16px;

      &-link {
        color: #41c6eb;
      }
    }
  }
</style>