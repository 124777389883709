<template>
  <label class="switch" :data-paint="paint">
    <input
      class="switch__checkbox"
      type="checkbox"
      :name="name"
      :checked="status"
      :disabled="this.disabled"
    />
    <span class="switch__slider"></span>
  </label>
</template>

<script>
  export default {
    name: "Switch",
    props: {
      status: Boolean,
      paint: String,
      name: String,
      disabled: Boolean,
    },
  };
</script>

<style lang="scss">
  .switch {
    position: relative;
    display: inline-block;
    width: $switchSize;
    min-width: $switchSize;
    height: $switchSize / 2;
    -webkit-tap-highlight-color: transparent;

    &[data-paint="blue"] {
      .switch__checkbox {
        &:checked + .switch__slider {
          background-color: $colorHum;
        }

        &:focus + .switch__slider {
          box-shadow: 0 0 1px $colorHum;
        }
      }
    }

    &[data-paint="orange"] {
      .switch__checkbox {
        &:checked + .switch__slider {
          background-color: $colorTemp;
        }

        &:focus + .switch__slider {
          box-shadow: 0 0 1px $colorTemp;
        }
      }
    }

    &[data-paint="dark"] {
      .switch__checkbox {
        &:checked + .switch__slider {
          background-color: #363636;
        }

        &:focus + .switch__slider {
          box-shadow: 0 0 1px #363636;
        }
      }
    }

    &[data-paint="light"] {
      .switch__checkbox {
        &:checked + .switch__slider {
          background-color: #ffffff;

          &::before {
            background-color: #a6a6a6;
          }
        }

        &:focus + .switch__slider {
          box-shadow: 0 0 1px #ffffff;
        }
      }
    }

    &__checkbox {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .switch__slider:before {
        -webkit-transform: translateX($switchSize / 2);
        -ms-transform: translateX($switchSize / 2);
        transform: translateX($switchSize / 2);
      }
    }

    &__slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: $switchSize / 2;

      &:before {
        position: absolute;
        content: "";
        height: $switchSize / 2 - 10;
        width: $switchSize / 2 - 10;
        left: 4px;
        bottom: 5px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
      }
    }
  }
</style>
