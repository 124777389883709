<template>
	<div
		:class="{
			dropdown: true,
			dropdown_valid: this.status === 'valid',
			dropdown_invalid: this.status === 'invalid',
		}"
		:data-paint="paint"
		:data-type="type"
		v-click-outside="hideList"
	>
		<div class="dropdown__field" @click="toggleList">
			<img v-if="paint === 'blue'" class="dropdown__field-icon" :src="require(`../assets/images/hum.svg`)" />
			<img v-if="paint === 'orange'" class="dropdown__field-icon" :src="require(`../assets/images/temp.svg`)" />
			<img v-if="paint === 'black'" class="dropdown__field-icon" :src="require(`../assets/images/carb.svg`)" />
			<img v-if="system === 'mir'" class="dropdown__field-icon" :src="require(`../assets/images/mir.svg`)" />
			<img v-if="system === 'visa'" class="dropdown__field-icon" :src="require(`../assets/images/visa.svg`)" />
			<span class="dropdown__field-text">{{ value }}</span>
			<div class="dropdown__field-placeholder" v-show="placeholderVisible">
				{{ placeholder }}
			</div>
		</div>
		<ul class="dropdown__list" v-show="listVisible">
			<perfect-scrollbar>
				<li
					class="dropdown__item"
					v-for="item in items"
					:key="item.id"
					:data-name="item.name"
					:data-id="item.id"
					@click="selectItem"
				>
					<div class="dropdown__wrapper" v-if="type === 'card'">
						<img
							v-if="item.system === 'mir'"
							class="dropdown__field-icon"
							:src="require(`../assets/images/mir.svg`)"
						/>
						<img
							v-if="item.system === 'visa'"
							class="dropdown__field-icon"
							:src="require(`../assets/images/visa.svg`)"
						/>
						<span class="dropdown__title">{{ item.title }}</span>
					</div>
					<div class="dropdown__wrapper" v-if="type === 'difficult'">
						<span class="dropdown__title">{{ item.title }}</span>
						<Switch :status="item.status" :paint="paint" />
					</div>
					<div class="dropdown__title" v-if="type === 'multi'">
						{{ item.title }}
					</div>
					<span class="dropdown__title" v-if="type === 'simple'">{{ item.title }}</span>
				</li>
			</perfect-scrollbar>
		</ul>
		<button class="dropdown__button" @click="toggleList">
			<span v-bind:class="[listVisible ? className.active : className.common]"></span>
		</button>
	</div>
</template>

<script>
import Switch from "../components/Switch";

export default {
	name: "Dropdown",
	components: {
		Switch,
	},
	props: {
		type: String,
		paint: String,
		system: String,
		placeholder: String,
		items: Array,
		status: String,
		value: String,
	},
	data() {
		return {
			listVisible: false,
			placeholderVisible: this.value === "" ? true : false,
			className: {
				common: "dropdown__icon",
				active: "dropdown__icon dropdown__icon_rotate",
			},
			selected: [],
		};
	},
	methods: {
		toggleList() {
			this.listVisible = !this.listVisible;
		},
		hideList() {
			this.listVisible = false;
		},
		selectItem(event) {
			if (!event.target.closest(".switch")) {
				if (this.type === "multi") {
					let field = event.target.closest(".dropdown").querySelector(".dropdown__field-text");
					let item = event.target.closest(".dropdown__item");
					let title = item.querySelector(".dropdown__title");

					if (item.className === "dropdown__item") {
						item.className = "dropdown__item dropdown__item_selected";
						this.selected.push(title.innerHTML);
					} else {
						item.className = "dropdown__item";
						this.selected = this.selected.filter((elem) => elem !== title.innerHTML);
					}

					field.innerHTML = this.selected.join(", ");
				} else {
					this.listVisible = false;
					this.placeholderVisible = false;
				}
			}
		},
	},
};
</script>

<style lang="scss">
.dropdown {
	width: 100%;
	height: 36px;
	position: relative;
	border: 1px solid transparent;
	box-shadow: $shadowLight;
	border-radius: 6px;
	background-color: $colorMainLight;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&[data-type="card"] {
		.dropdown__wrapper {
			justify-content: flex-start;
		}
	}

	&_valid {
		border-color: #41c6eb;

		.dropdown__list {
			border: 1px solid #41c6eb;
			border-top: 0;
		}
	}

	&_invalid {
		border-color: #ff8068;

		.dropdown__list {
			border: 1px solid #ff8068;
			border-top: 0;
		}
	}

	&[data-paint="blue"] {
		.dropdown__field-text {
			color: $colorHum;
			font-weight: 500;
		}
	}

	&[data-paint="orange"] {
		.dropdown__field-text {
			color: $colorTemp;
			font-weight: 500;
		}

		.dropdown__item {
			&:hover {
				color: $colorTemp;
			}
		}
	}

	&[data-paint="black"] {
		.dropdown__field-text {
			color: $colorCarb;
			font-weight: 500;
		}

		.dropdown__item {
			&:hover {
				color: $colorCarb;
			}
		}
	}

	&__field {
		width: calc(100% - 24px);
		height: 20px;
		line-height: 20px;
		cursor: pointer;
		z-index: $zIndexDropdown - 10;
		font-size: 12px;
		position: relative;
		display: flex;
		align-items: center;
		padding-left: 13px;
		padding-right: 15px;

		&-placeholder {
			position: absolute;
			left: 0;
			width: 100%;
			height: 20px;
			line-height: 20px;
			color: #9b9b9b;
			z-index: 1;
			font-size: 12px;
			padding-left: 13px;
		}

		&-icon {
			margin-right: 5px;
			width: 15px;
		}

		&-text {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&__list {
		position: absolute;
		top: 25px;
		left: -1px;
		margin: 0;
		padding: 0 13px;
		list-style: none;
		width: calc(100% + 2px);
		background-color: $colorMainLight;
		z-index: $zIndexDropdown + 10;
		box-shadow: 4px 4px 4px #dcdcdc, -4px 4px 4px #f7f7f9eb;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		padding-top: 10px;

		&_invisible {
			display: none;
		}
	}

	.ps {
		max-height: 155px;
		padding: 0 10px;
	}

	&__item {
		display: flex;
		align-items: center;
		cursor: pointer;
		border-bottom: 1px solid #d8d8d8;
		padding: 8px 0;
		font-size: 12px;
		color: #2b1a0a;

		&:hover {
			color: #41c6eb;
		}

		&:first-of-type {
			border-top: 1px solid #d8d8d8;
		}

		&:last-of-type {
			border-bottom: 0;
		}

		&_selected {
			color: #41c6eb;
			font-weight: 500;
		}
	}

	&__wrapper {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.dropdown__title {
			font-weight: 500;
		}
	}

	&__button {
		height: 100%;
		width: 24px;
		min-width: 24px;
		background: none;
		padding: 0;
		border: 0;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: $zIndexDropdown;
	}

	&__icon {
		position: absolute;
		left: 2px;
		top: 11px;
		width: 7px;
		height: 7px;
		border: solid #bcbcbc;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);

		&_rotate {
			border-color: #222325;
			top: 14px;
			transform: rotate(225deg);
		}
	}
}
</style>
