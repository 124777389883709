import {
  createStore
} from 'vuex';

import authorization from './authorization';
import common from './common';
import extend from './extend';
import invite from './invite';
import messenger from './messenger';
import objects from './objects';
import profile from './profile';
import recover from './recover';
import register from './register';
import reports from './reports';
import reset from './reset';
import set from './set';
import system from './system';
import systems from './systems';

export const store = createStore({
  modules: {
    authorization,
    common,
    extend,
    invite,
    messenger,
    objects,
    profile,
    recover,
    register,
    reports,
    reset,
    set,
    system,
    systems,
  },
})