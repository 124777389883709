<template>
	<div class="register">
		<div class="register__wrapper">
			<img class="register__logo" src="../assets/images/logo.svg" alt="" />
			<div class="register__title">Регистрация</div>
			<div class="register__forms">
				<form class="register__form" @submit.prevent>
					<div class="register__form-body">
						<div class="register__form-block">
							<div class="register__form-title">Укажите актуальный адрес электронной почты</div>
							<Input
								:type="email.type"
								:name="email.name"
								:value="email.value"
								:status="email.status"
								@input="handleChange($event, email.name)"
								@paste="handleChange($event, email.name)"
								@change="handleChange($event, email.name)"
							/>
							<span class="register__form-error" v-if="email.error">{{ email.error }}</span>
						</div>
						<div class="register__form-block">
							<div class="register__form-title">Укажите актуальный номер телефона</div>
							<Input
								:type="phone.type"
								:name="phone.name"
								:value="phone.value"
								:status="phone.status"
								@focus="handleChange($event, phone.name)"
								@input="handleChange($event, phone.name)"
								@paste="handleChange($event, phone.name)"
								@change="handleChange($event, phone.name)"
							/>
							<span class="register__form-error" v-if="phone.error">{{ phone.error }}</span>
						</div>
					</div>
					<div class="register__form-footer">
						<Button
							:type="'text'"
							:value="'Позвонить мне'"
							:paint="'blue'"
							:disabled="!validationOne"
							@click="handleFormOneSumbit"
						/>
					</div>
				</form>
				<form class="register__form" @submit.prevent>
					<div class="register__form-body">
						<div class="register__form-text">
							На указанный вами номер телефона поступит звонок. Введите последние 4 цифры номера телефона
							входящего вызова.
						</div>
						<div class="register__form-block">
							<div class="register__form-code" name="code" @input="handleChange($event, 'code')">
								<Singleinput
									v-for="input in code.numbers"
									:key="input.id"
									:data-id="input.id"
									:value="input.value"
									:status="input.status"
									:disabled="code.disabled"
									@blur="handleChange($event, 'code')"
								/>
							</div>
							<span class="register__form-error" v-if="code.error">{{ code.error }}</span>
						</div>
						<div class="register__form-text" v-if="countdown.show">
							если вам не поступил звонок, попробуйте
							<button
								class="register__form-button"
								:disabled="countdown.timer > 0"
								@click.prevent="handleFormOneSumbit"
							>
								еще раз
							</button>
							через {{ countdown.timer }} сек.
						</div>
					</div>
					<div class="register__form-footer">
						<Button
							:type="'text'"
							:value="'Продолжить'"
							:paint="'blue'"
							:disabled="!validationTwo"
							@click="handleFormTwoSumbit"
						/>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import Button from "../components/Button";
import Input from "../components/Input";
import Singleinput from "../components/Singleinput";

export default {
	name: "Register",
	components: {
		Button,
		Input,
		Singleinput,
	},
	computed: {
		...mapGetters({
			email: "getRegisterEmail",
			phone: "getRegisterPhone",
			code: "getRegisterCode",
			countdown: "getRegisterCountdown",
			validationOne: "getRegisterValidationOne",
			validationTwo: "getRegisterValidationTwo",
		}),
	},
	methods: {
		...mapMutations(["handleRegisterChanges"]),
		...mapActions(["receiveRegisterCode", "sendRegisterInfo", "logoutAuthorization"]),
		handleChange(event, element) {
			let payload = {};

			if (element === "email") {
				payload = {
					event: event.type,
					name: event.target.getAttribute("name"),
					value: event.target.value,
				};
			}

			if (element === "phone") {
				if (event.type === "focus" && event.target.value == "") {
					event.target.value = "+7 ";
				} else if (event.type === "paste") {
					let value = event.clipboardData.getData("text/plain");

					let pattern = /(\+7|8)\s?[\s(]?(\d{3})[\s)]?\s?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;
					let phoneNumbers = value.match(pattern);

					if (phoneNumbers !== null) {
						let correctNumber = phoneNumbers[0].replace(pattern, "+7 ($2) $3-$4-$5");

						event.target.value = correctNumber;
					}
				} else if (event.type === "keydown" || event.type === "input") {
					let keyCode;
					event.keyCode && (keyCode = event.keyCode);

					if (event.target.selectionStart < 3) {
						event.target.value = `+7 ${event.data}`;
					}

					let mask = "+7 (___) ___-__-__";
					let index = 0;
					let def = mask.replace(/\D/g, "");
					let val = event.target.value.replace(/\D/g, "");
					let newVal = mask.replace(/[_\d]/g, (arg) =>
						index < val.length ? val.charAt(index++) || def.charAt(index) : arg
					);

					index = newVal.indexOf("_");

					if (index !== -1) {
						index < 5 && (index = 3);
						newVal = newVal.slice(0, index);
					}

					let reg = mask
						.substr(0, event.target.value.length)
						.replace(/_+/g, (arg) => `\\d{1,${arg.length}}`)
						.replace(/[+()]/g, "\\$&");

					reg = new RegExp(`^${reg}$`);

					if (!reg.test(event.target.value) || event.target.value.length < 5 || (keyCode > 47 && keyCode < 58)) {
						event.target.value = newVal;
					}
				}

				payload = {
					event: event.type,
					name: event.target.getAttribute("name"),
					value: event.target.value,
				};
			}

			if (element === "code") {
				let current = event.target;
				let next = current.nextElementSibling;

				if (current.value.length > 0) {
					if (current.value) current.blur();
					if (next !== null) next.focus();
				}

				payload = {
					id: +event.target.getAttribute("data-id"),
					event: event.type,
					name: event.target.closest(".register__form-code").getAttribute("name"),
					value: event.target.value,
				};
			}

			payload.element = element;

			this.handleRegisterChanges(payload);
		},
		handleFormOneSumbit() {
			this.receiveRegisterCode();
		},
		handleFormTwoSumbit() {
			this.sendRegisterInfo();
		},
	},
	created() {
		this.logoutAuthorization();
	},
};
</script>

<style lang="scss" scoped>
.register {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	padding-bottom: 20px;

	@include mediaquery(md) {
		min-height: calc(100vh - 20px);
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	&__logo {
		width: 50px;
		margin-bottom: 24px;

		@include mediaquery(sm) {
			margin-bottom: 44px;
		}
	}

	&__title {
		width: 100%;
		margin-bottom: 20px;
		text-transform: uppercase;
		text-align: center;
		font-size: 24px;
	}

	&__forms {
		@include base;

		display: flex;
		flex-direction: column;
		padding: 20px;

		@include mediaquery(md) {
			flex-direction: row;
			width: 720px;
			padding: 46px 40px 25px;
		}
	}

	&__form {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@include mediaquery(md) {
			width: 50%;
		}

		&:first-of-type {
			padding-bottom: 20px;
			border-bottom: 1px solid #e1e1e1;

			@include mediaquery(md) {
				padding-right: 40px;
				padding-bottom: 0;
				border-right: 1px solid #e1e1e1;
				border-bottom: 0;
			}
		}

		&:last-of-type {
			padding-top: 20px;

			@include mediaquery(md) {
				padding-left: 40px;
				padding-top: 0;
			}

			.register__form-body {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}

			.register__form-footer {
				justify-content: center;
			}
		}

		&-block {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin-bottom: 10px;
			position: relative;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		&-subblock {
			display: flex;
			align-items: center;
		}

		&-title {
			@include title;
		}

		&-code {
			display: flex;
			justify-content: space-between;
			width: 180px;
		}

		&-error {
			position: absolute;
			top: 100%;
			font-size: 9px;
			color: #ff8068;
		}

		&-button {
			font-size: 9px;
			color: #41c6eb;
			border: 0;
			background: none;
			padding: 0;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}

			&:disabled {
				pointer-events: none;
			}
		}

		&-text {
			font-size: 12px;
			text-align: center;
			color: #222325;
			margin-bottom: 40px;

			&:last-of-type {
				margin-bottom: -11px;
			}
		}

		&-footer {
			display: flex;
			justify-content: space-between;
			margin-top: 27px;

			button {
				width: 100%;
			}
		}
	}

	&__notice {
		font-size: 7px;
		margin-top: 16px;

		&-link {
			color: #41c6eb;
		}
	}
}
</style>
