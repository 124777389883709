<template>
	<div class="modal" v-if="modal.visible" :data-type="modal.current.type">
		<div class="modal__window">
			<button class="modal__close" @click="handleClick">
				<img class="modal__close-icon" :src="require(`../assets/images/close.svg`)" />
			</button>
			<div class="modal__header">{{ modal.current.header }}</div>
			<div class="modal__body">
				<div
					class="modal__block"
					v-for="block in modal.current.body"
					:id="block.id"
					:key="block.id"
					:data-kind="block.kind"
				>
					<template v-if="block.kind === 'single'">
						<div class="modal__title">{{ block.title }}</div>
						<Dropdown
							v-if="block.dropdown"
							:type="block.dropdown.type"
							:items="block.dropdown.categories"
							:value="block.dropdown.value"
							:status="block.dropdown.status"
							:system="block.dropdown.system"
							@click="handleChange($event, 'dropdown')"
						/>
						<Input
							v-else-if="block.name === 'phone'"
							:type="'tel'"
							:name="block.name"
							:value="block.value"
							:status="block.status"
							:disabled="block.disabled"
							@focus="handleChange($event, 'phone')"
							@input="handleChange($event, 'phone')"
							@paste="handleChange($event, 'phone')"
							@change="handleChange($event, 'phone')"
						/>
						<Input
							v-else-if="block.name === 'email'"
							:type="'email'"
							:name="block.name"
							:value="block.value"
							:status="block.status"
							:disabled="block.disabled"
							@paste="handleChange($event, 'email')"
							@input="handleChange($event, 'email')"
							@change="handleChange($event, 'email')"
						/>
						<Input
							v-else-if="block.name === 'number'"
							:type="'tel'"
							:name="block.name"
							:value="block.value"
							:status="block.status"
							:disabled="block.disabled"
							@focus="handleChange($event, 'number')"
							@input="handleChange($event, 'number')"
							@paste="handleChange($event, 'number')"
							@change="handleChange($event, 'number')"
						/>
						<Input
							v-else-if="block.name === 'holder'"
							:type="'text'"
							:name="block.name"
							:value="block.value"
							:status="block.status"
							:disabled="block.disabled"
							@focus="handleChange($event, 'holder')"
							@input="handleChange($event, 'holder')"
							@paste="handleChange($event, 'holder')"
							@change="handleChange($event, 'holder')"
						/>
						<Input
							v-else-if="block.name === 'password'"
							:type="'text'"
							:name="block.name"
							:value="modal.params.password"
							:status="block.status"
							:disabled="block.disabled"
							@focus="handleChange($event, 'password')"
							@input="handleChange($event, 'password')"
							@paste="handleChange($event, 'password')"
							@change="handleChange($event, 'password')"
						/>
						<Input
							v-else
							:type="'text'"
							:name="block.name"
							:value="block.value"
							:status="block.status"
							:disabled="block.disabled"
							@input="handleChange($event, 'input')"
							@paste="handleChange($event, 'input')"
							@change="handleChange($event, 'input')"
							@blur="handleChange($event, 'input')"
						/>
						<span v-if="block.error" class="modal__error">{{ block.errorMessage }}</span>
					</template>
					<template v-if="block.kind === 'double'">
						<div class="modal__subblock" data-part="first">
							<div class="modal__title">{{ block.first.title }}</div>
							<template v-if="block.first.type === 'datepicker'">
								<div ref="date" @change="handleChange($event, 'datepicker')">
									<Datepicker v-model="date" :format="format" autoApply hideInputIcon></Datepicker>
								</div>
							</template>
							<template v-if="block.first.type === 'input'">
								<Input
									:type="'text'"
									:name="block.first.name"
									:value="block.first.value"
									:status="block.first.status"
									:disabled="block.first.disabled"
									@input="handleChange($event, 'input')"
									@paste="handleChange($event, 'input')"
									@change="handleChange($event, 'input')"
									@blur="handleChange($event, 'input')"
								/>
							</template>
							<template v-if="block.first.name === 'phone'">
								<Input
									:type="'tel'"
									:name="block.first.name"
									:value="block.first.value"
									:status="block.first.status"
									:disabled="block.first.disabled"
									@focus="handleChange($event, 'phone')"
									@input="handleChange($event, 'phone')"
									@paste="handleChange($event, 'phone')"
									@change="handleChange($event, 'phone')"
								/>
							</template>
							<span v-if="block.first.error" class="modal__error">{{ block.first.errorMessage }}</span>
						</div>
						<div class="modal__subblock" data-part="last">
							<div class="modal__title">{{ block.last.title }}</div>
							<template v-if="block.last.type === 'datepicker'">
								<div ref="date" @change="handleChange($event, 'datepicker')">
									<Datepicker v-model="date" :format="format" autoApply hideInputIcon></Datepicker>
								</div>
							</template>
							<template v-if="block.last.type === 'input'">
								<Input
									:type="'text'"
									:name="block.last.name"
									:value="block.last.value"
									:status="block.last.status"
									:disabled="block.last.disabled"
									@input="handleChange($event, 'input')"
									@paste="handleChange($event, 'input')"
									@change="handleChange($event, 'input')"
									@blur="handleChange($event, 'input')"
								/>
							</template>
							<template v-if="block.last.name === 'phone'">
								<Input
									:type="'tel'"
									:name="block.last.name"
									:value="block.last.value"
									:status="block.last.status"
									:disabled="block.last.disabled"
									@focus="handleChange($event, 'phone')"
									@input="handleChange($event, 'phone')"
									@paste="handleChange($event, 'phone')"
									@change="handleChange($event, 'phone')"
								/>
							</template>
							<span v-if="block.last.error" class="modal__error">{{ block.last.errorMessage }}</span>
						</div>
					</template>
					<template v-if="block.kind === 'triple'">
						<div class="modal__subblock" data-part="first">
							<div class="modal__title">{{ block.first.title }}</div>
							<Picker
								v-if="block.first.type === 'picker'"
								:period="block.first.name"
								:value="block.first.value"
								:status="block.first.status"
								@click="handleChange($event, 'picker')"
							/>
							<span v-if="block.first.error" class="modal__error">{{ block.first.errorMessage }}</span>
						</div>
						<div class="modal__subblock" data-part="middle">
							<div class="modal__title">{{ block.middle.title }}</div>
							<Picker
								v-if="block.middle.type === 'picker'"
								:period="block.middle.name"
								:value="block.middle.value"
								:status="block.middle.status"
								@click="handleChange($event, 'picker')"
							/>
							<span v-if="block.middle.error" class="modal__error">{{ block.middle.errorMessage }}</span>
						</div>
						<div class="modal__subblock" data-part="last">
							<div class="modal__title">{{ block.last.title }}</div>
							<Input
								v-if="block.last.name === 'cvc'"
								:type="'tel'"
								:name="block.last.name"
								:value="block.last.value"
								:status="block.last.status"
								:data-align="'center'"
								@focus="handleChange($event, 'cvc')"
								@paste="handleChange($event, 'cvc')"
								@input="handleChange($event, 'cvc')"
								@change="handleChange($event, 'cvc')"
							/>
							<span v-if="block.last.error" class="modal__error">{{ block.last.errorMessage }}</span>
						</div>
					</template>
					<template v-if="block.kind === 'setup'">
						<div class="modal__subblock" data-part="first" v-if="block.first.visible">
							<div class="modal__title">{{ block.first.title }}</div>
							<div class="modal__wrapper">
								<Input
									:type="'text'"
									:name="block.first.name"
									:value="!block.first.disabled ? block.first.value + block.first.unit : null"
									:status="block.first.status"
									:disabled="block.first.disabled"
									@focus="handleChange($event, 'input')"
									@input="handleChange($event, 'input')"
									@paste="handleChange($event, 'input')"
									@change="handleChange($event, 'input')"
									@blur="handleChange($event, 'input')"
								/>
								<span v-if="block.first.error" class="modal__error">{{ block.first.errorMessage }}</span>
								<Switch :status="!block.first.disabled" paint="blue" @change="handleChange($event, 'switch')" />
							</div>
						</div>
						<div class="modal__subblock" data-part="middle" v-if="block.middle.visible">
							<div class="modal__title">{{ block.middle.title }}</div>
							<div class="modal__wrapper">
								<Input
									:type="'text'"
									:name="block.middle.name"
									:value="!block.middle.disabled ? block.middle.value + block.middle.unit : null"
									:status="block.middle.status"
									:disabled="block.middle.disabled"
									@focus="handleChange($event, 'input')"
									@input="handleChange($event, 'input')"
									@paste="handleChange($event, 'input')"
									@change="handleChange($event, 'input')"
									@blur="handleChange($event, 'input')"
								/>
								<span v-if="block.middle.error" class="modal__error">{{ block.middle.errorMessage }}</span>
								<Switch
									:status="!block.middle.disabled"
									paint="orange"
									@change="handleChange($event, 'switch')"
								/>
							</div>
						</div>
						<div class="modal__subblock" data-part="last" v-if="block.last.visible">
							<div class="modal__title">{{ block.last.title }}</div>
							<div class="modal__wrapper">
								<Input
									:type="'text'"
									:name="block.last.name"
									:value="!block.last.disabled ? block.last.value + block.last.unit : null"
									:status="block.last.status"
									:disabled="block.last.disabled"
									@focus="handleChange($event, 'input')"
									@input="handleChange($event, 'input')"
									@paste="handleChange($event, 'input')"
									@change="handleChange($event, 'input')"
									@blur="handleChange($event, 'input')"
								/>
								<span v-if="block.last.error" class="modal__error">{{ block.last.errorMessage }}</span>
								<Switch :status="!block.last.disabled" paint="dark" @change="handleChange($event, 'switch')" />
							</div>
						</div>
					</template>
					<template v-if="block.kind === 'module' && block.visible">
						<div class="modal__subblock" data-part="first">
							<div v-if="block.dropdown.title !== null" class="modal__title">
								{{ block.dropdown.title }}
							</div>
							<Dropdown
								:type="block.dropdown.type"
								:items="block.dropdown.categories"
								:value="block.dropdown.value"
								:status="block.dropdown.status"
								@click="handleChange($event, 'dropdown')"
							/>
							<span v-if="block.dropdown.error" class="modal__error">{{ block.first.errorMessage }}</span>
						</div>
						<div class="modal__subblock" data-part="last">
							<div v-if="block.input.title !== null" class="modal__title">
								{{ block.input.title }}
							</div>
							<Input
								:type="'text'"
								:name="block.input.name"
								:value="block.input.value"
								:status="block.input.status"
								@input="handleChange($event, 'input')"
								@paste="handleChange($event, 'input')"
								@change="handleChange($event, 'input')"
							/>
							<span v-if="block.input.error" class="modal__error">{{ block.input.errorMessage }}</span>
						</div>
						<Button
							:type="'action'"
							:value="'add'"
							:paint="'gray'"
							:disabled="block.button.disabled"
							@click="handleChange($event, 'add')"
						/>
						<div v-if="block.modules.length > 0" class="modal__modules">
							<div class="modal__module" v-for="(elem, index) in block.modules" :data-id="index" :key="index">
								<div class="modal__subblock">
									<template v-if="elem.first.type === 'input'">
										<Input :type="'text'" :value="elem.first.value" :disabled="true" />
									</template>
									<template v-if="elem.first.type === 'dropdown'">
										<Dropdown type="simple" :items="elem.first.categories" :value="elem.first.value" />
									</template>
								</div>
								<div class="modal__subblock">
									<Input :type="'text'" :value="elem.last.value" :disabled="true" />
								</div>
								<Button
									:type="'action'"
									:value="'delete'"
									:paint="'gray'"
									@click="handleChange($event, 'delete')"
								/>
							</div>
						</div>
					</template>
					<template v-if="block.kind === 'set'">
						<div class="modal__title">{{ block.title }}</div>
						<Dropdown
							:type="block.dropdown.type"
							:items="block.dropdown.categories"
							:value="block.dropdown.value"
							:status="block.dropdown.status"
							@click="handleChange($event, 'dropdown')"
						/>
						<span class="modal__error" v-if="block.dropdown.error">{{ block.first.errorMessage }}</span>
					</template>
					<template v-if="block.kind === 'file'">
						<div class="modal__subblock" data-part="first">
							<div class="modal__title">{{ block.input.title }}</div>
							<Input
								:type="'text'"
								:name="block.input.name"
								:value="block.input.value"
								:status="block.input.status"
								@change="handleChange($event, 'input')"
							/>
							<span v-if="block.input.error" class="modal__error">{{ block.input.errorMessage }}</span>
						</div>
						<label class="modal__file" @change="handleChange($event, 'file')">
							<input class="modal__file-input" type="file" />
							<span class="modal__file-action" data-color="blue">
								<font-awesome-icon icon="fa-plus" />
							</span>
							<!--  <img
                class="modal__file-icon"
                :src="require(`../assets/images/${block.button.value}.svg`)"
              /> -->
						</label>
					</template>
				</div>
			</div>
			<div class="modal__footer">
				<Button
					v-for="button in modal.current.footer"
					:id="button.id"
					:key="button.id"
					:type="button.type"
					:value="button.value"
					:paint="button.paint"
					:action="button.action"
					:disabled="button.disabled"
					@click="handleClick(button.action)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import Datepicker from "@vuepic/vue-datepicker";

import Button from "../components/Button";
import Dropdown from "../components/Dropdown";
import Input from "../components/Input";
import Picker from "../components/Picker";
import Switch from "../components/Switch";

export default {
	name: "Modal",
	data() {
		return {
			format: "dd.MM.yyyy",
		};
	},
	components: {
		Button,
		Datepicker,
		Dropdown,
		Input,
		Picker,
		Switch,
	},
	computed: {
		...mapGetters({
			modal: "getCommonModal",
		}),
		date: {
			get() {
				if (
					this.$store.state.common.modal.current.type === "object" &&
					this.$store.state.common.modal.current.body[3].first &&
					this.$store.state.common.modal.current.body[3].first.name == "date"
				) {
					if (this.$store.state.common.modal.current.body[3].first.value !== null) {
						return new Date(this.$store.state.common.modal.current.body[3].first.value * 1000);
					} else return null;
				} else if (
					this.$store.state.common.modal.current.type === "object" &&
					this.$store.state.common.modal.current.body[4].first &&
					this.$store.state.common.modal.current.body[4].first.name == "date"
				) {
					if (this.$store.state.common.modal.current.body[4].first.value !== null) {
						return new Date(this.$store.state.common.modal.current.body[4].first.value * 1000);
					} else return null;
				} else return false;
			},
			set(value) {
				let event = new Event("change");
				event.value = Math.round(Date.parse(value) / 1000);

				this.$refs.date[0].dispatchEvent(event);
			},
		},
	},
	methods: {
		...mapMutations(["destroyModal", "changeModal", "validateModal"]),
		...mapActions([
			"addProfileContact",
			"addProfileUser",
			"addProfileCard",
			"changeSystemOwner",
			"addSystemDocument",
			"addSystemZone",
			"addSystemEntrance",
			"createSystemsObject",
			"editSystemsObject",
			"changeProfilePassword",
			"getCommonCallback",
		]),
		generatePassword(length) {
			let alpha = "abcdefghijklmnopqrstuvwxyz";
			let caps = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
			let numeric = "0123456789";

			let options = [alpha, caps, numeric];

			let password = "";
			let passwordArray = Array(length);

			for (let i = 0; i < length; i++) {
				let currentOption = options[Math.floor(Math.random() * options.length)];
				let randomChar = currentOption.charAt(Math.floor(Math.random() * currentOption.length));
				password += randomChar;
				passwordArray.push(randomChar);
			}

			checkPassword();

			function checkPassword() {
				let missingValueArray = [];
				let containsAll = true;

				options.forEach(function (e, a) {
					let hasValue = false;
					passwordArray.forEach(function (e1) {
						if (e.indexOf(e1) > -1) {
							hasValue = true;
						}
					});

					if (!hasValue) {
						missingValueArray = a;
						containsAll = false;
					}
				});

				if (!containsAll) {
					passwordArray[Math.floor(Math.random() * passwordArray.length)] = missingValueArray.charAt(
						Math.floor(Math.random() * missingValueArray.length)
					);
					password = "";
					passwordArray.forEach(function (e) {
						password += e;
					});
					checkPassword();
				}
			}

			return password;
		},
		handleChange(event, source) {
			let payload = {};
			let kind = event.target.closest(".modal__block").getAttribute("data-kind");

			if (source === "input" || source === "email") {
				payload = {
					name: event.target.name,
					value: event.target.value,
					event: event.type,
				};

				if (kind === "setup" || kind === "double") {
					payload.part = event.target.closest(".modal__subblock").getAttribute("data-part");
				}
			}

			if (source === "phone") {
				if (event.type === "focus" && event.target.value == "") {
					event.target.value = "+7 ";
				} else if (event.type === "paste") {
					let value = event.clipboardData.getData("text/plain");

					let pattern = /(\+7|8)\s?[\s(]?(\d{3})[\s)]?\s?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;
					let phoneNumbers = value.match(pattern);

					if (phoneNumbers !== null) {
						let correctNumber = phoneNumbers[0].replace(pattern, "+7 ($2) $3-$4-$5");

						event.target.value = correctNumber;
					}
				}

				if (event.type === "keydown" || event.type === "input") {
					let keyCode;
					event.keyCode && (keyCode = event.keyCode);

					if (event.target.selectionStart < 3) {
						event.target.value = `+7 ${event.data}`;
					}

					let mask = "+7 (___) ___-__-__";
					let index = 0;
					let def = mask.replace(/\D/g, "");
					let val = event.target.value.replace(/\D/g, "");
					let newVal = mask.replace(/[_\d]/g, (arg) =>
						index < val.length ? val.charAt(index++) || def.charAt(index) : arg
					);

					index = newVal.indexOf("_");

					if (index !== -1) {
						index < 5 && (index = 3);
						newVal = newVal.slice(0, index);
					}

					let reg = mask
						.substr(0, event.target.value.length)
						.replace(/_+/g, (arg) => `\\d{1,${arg.length}}`)
						.replace(/[+()]/g, "\\$&");

					reg = new RegExp(`^${reg}$`);

					if (!reg.test(event.target.value) || event.target.value.length < 5 || (keyCode > 47 && keyCode < 58)) {
						event.target.value = newVal;
					}
				}

				payload = {
					name: event.target.name,
					value: event.target.value,
					event: event.type,
				};

				if (kind === "setup" || kind === "double") {
					payload.part = event.target.closest(".modal__subblock").getAttribute("data-part");
				}
			}

			if (source === "number") {
				if (event.type === "keydown" || event.type === "input") {
					let keyCode;
					event.keyCode && (keyCode = event.keyCode);

					let mask = "____ ____ ____ ____";
					let index = 0;
					let def = mask.replace(/\D/g, "");
					let val = event.target.value.replace(/\D/g, "");
					let newVal = mask.replace(/[_\d]/g, (arg) =>
						index < val.length ? val.charAt(index++) || def.charAt(index) : arg
					);

					index = newVal.indexOf("_");

					if (index !== -1) {
						newVal = newVal.slice(0, index);
					}

					let reg = mask
						.substr(0, event.target.value.length)
						.replace(/_+/g, (arg) => `\\d{1,${arg.length}}`)
						.replace(/[+()]/g, "\\$&");
					reg = new RegExp(`^${reg}$`);

					if (!reg.test(event.target.value) || (keyCode > 47 && keyCode < 58)) {
						event.target.value = newVal;
					}
				}

				payload = {
					name: event.target.name,
					value: event.target.value,
					event: event.type,
				};
			}

			if (source === "password") {
				if (event.type === "focus" && event.target.value === "") {
					event.target.value = this.generatePassword(10);
				}

				payload = {
					name: event.target.name,
					value: event.target.value,
					event: "change",
				};
			}

			if (source === "cvc") {
				if (event.type === "keydown" || event.type === "input") {
					let keyCode;
					event.keyCode && (keyCode = event.keyCode);

					let mask = "___";
					let index = 0;
					let def = mask.replace(/\D/g, "");
					let val = event.target.value.replace(/\D/g, "");
					let newVal = mask.replace(/[_\d]/g, (arg) =>
						index < val.length ? val.charAt(index++) || def.charAt(index) : arg
					);

					index = newVal.indexOf("_");

					if (index !== -1) {
						newVal = newVal.slice(0, index);
					}

					let reg = mask
						.substr(0, event.target.value.length)
						.replace(/_+/g, (arg) => `\\d{1,${arg.length}}`)
						.replace(/[+()]/g, "\\$&");
					reg = new RegExp(`^${reg}$`);

					if (!reg.test(event.target.value) || (keyCode > 47 && keyCode < 58)) {
						event.target.value = newVal;
					}
				}

				payload = {
					name: event.target.name,
					value: event.target.value,
					part: event.target.closest(".modal__subblock").getAttribute("data-part"),
					event: event.type,
				};
			}

			if (source === "holder") {
				if (event.type === "keydown" || event.type === "input") {
					let val = event.target.value.replace(/[^a-zA-Z\s]/g, "");
					event.target.value = val.toUpperCase();
				}

				payload = {
					name: event.target.name,
					value: event.target.value,
					event: event.type,
				};
			}

			if (source === "datepicker") {
				payload = {
					name: "date",
					value: event.value,
					event: "change",
				};

				if (kind === "setup" || kind === "double") {
					payload.part = event.target.closest(".modal__subblock").getAttribute("data-part");
				}
			}

			if (source === "dropdown") {
				if (event.target.closest(".modal").getAttribute("data-type") === "zone") {
					if (event.target.closest(".dropdown__button") || event.target.closest(".dropdown__field")) {
						payload = {
							event: "change",
							open: true,
						};
					} else if (event.target.closest(".dropdown__item")) {
						payload = {
							name: event.target.closest(".dropdown__item").getAttribute("data-name"),
							value: event.target.closest(".dropdown__item").querySelector(".dropdown__title").innerHTML,
							event: "change",
							open: false,
						};
					} else return false;
				} else if (event.target.closest(".dropdown").getAttribute("data-type") === "multi") {
					if (event.target.closest(".dropdown__item")) {
						payload = {
							name: event.target.closest(".dropdown__item").getAttribute("data-name"),
							value: event.target.closest(".dropdown").querySelector(".dropdown__field-text").innerHTML,
							event: "change",
							multi: true,
							open: false,
						};
					} else return false;
				} else {
					if (event.target.closest(".dropdown__item")) {
						payload = {
							name: event.target.closest(".dropdown__item").getAttribute("data-name"),
							value: event.target.closest(".dropdown__item").querySelector(".dropdown__title").innerHTML,
							event: "change",
							open: false,
						};
					} else return false;
				}
			}

			if (source === "picker") {
				if (event.target.closest(".picker__item")) {
					payload = {
						value: event.target.closest(".picker__item").innerHTML,
						part: event.target.closest(".modal__subblock").getAttribute("data-part"),
						event: "change",
					};
				} else return false;
			}

			if (source === "file") {
				if (event.target.closest(".modal__file")) {
					payload = {
						value: event.target.files[0],
						event: event.type,
					};
				} else return false;
			}

			if (source === "switch") {
				payload = {
					value: event.target.checked,
					part: event.target.closest(".modal__subblock").getAttribute("data-part"),
					event: event.type,
				};
			}

			if (source === "add") {
				payload = {
					index: +event.target.closest(".modal__block").getAttribute("id"),
					event: "change",
				};
			}

			if (source === "delete") {
				payload = {
					index: +event.target.closest(".modal__module").getAttribute("data-id"),
					event: "change",
				};
			}

			payload.type = event.target.closest(".modal").getAttribute("data-type");
			payload.kind = kind;
			payload.source = source;
			payload.id = +event.target.closest(".modal__block").id;

			this.changeModal(payload);
		},
		handleClick(action) {
			if (action === "addContact") {
				this.addProfileContact(this.modal.params);
			}

			if (action === "addUser") {
				this.addProfileUser(this.modal.params);
			}

			if (action === "addCard") {
				this.addProfileCard(this.modal.params);
			}

			if (action === "changeOwner") {
				this.changeSystemOwner(this.modal.params);
			}

			if (action === "addDocument") {
				this.addSystemDocument(this.modal.params);
			}

			if (action === "addEntrance") {
				this.addSystemEntrance(this.modal.params);
			}

			if (action === "addZone") {
				this.addSystemZone(this.modal.params);
			}

			if (action === "createObject") {
				this.createSystemsObject(this.modal.params);
			}

			if (action === "editObject") {
				this.editSystemsObject(this.modal.params);
			}

			if (action === "changePassword") {
				this.changeProfilePassword(this.modal.params);
			}

			if (action === "getCallback") {
				this.getCommonCallback(this.modal.params);
			}

			this.destroyModal();
		},
	},
};
</script>

<style lang="scss" scoped>
.modal {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow-y: scroll;
	background-color: rgba(240, 240, 240, 0.8);
	z-index: $zIndexModal;
	padding: 50px 0;

	&__window {
		@include base;

		position: relative;
		width: calc(100% - 60px);
		margin: 0 auto;
		padding: 20px;

		@include mediaquery(sm) {
			width: 400px;
			padding: 50px;
		}
	}

	&__close {
		position: absolute;
		top: 25px;
		right: 25px;
		display: flex;
		border: 0;
		background: none;
		padding: 0;
		cursor: pointer;
	}

	&__header {
		font-weight: 500;
		font-size: 14px;
		color: #2b1a0a;
		text-align: center;
	}

	&__body {
		font-size: 14px;
		margin: 20px 0;
		color: #2b1a0a;
	}

	&__block {
		margin-bottom: 10px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		position: relative;

		&[data-kind="triple"] {
			.modal__subblock {
				width: calc(33.333333% - 8px);
			}
		}

		&[data-kind="setup"] {
			.modal__subblock {
				width: 100%;
				margin-bottom: 10px;

				&:last-of-type {
					margin-bottom: 0;
				}

				input {
					margin-right: 10px;
				}
			}
		}

		&[data-kind="module"] {
			align-items: flex-end;
			.modal__subblock {
				width: calc(50% - 28px);
			}
		}

		&[data-kind="file"] {
			align-items: flex-end;
			.modal__subblock {
				width: calc(100% - 46px);
			}
		}
	}

	&__modules {
		width: 100%;
	}

	&__module {
		margin-top: 10px;
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	&__subblock {
		width: calc(50% - 5px);
		position: relative;
	}

	&__file {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 36px;
		min-width: 36px;
		padding: 0;
		border-radius: 6px;
		box-shadow: $shadowLight;
		border: 0;
		cursor: pointer;
		overflow-x: hidden;
		font-size: 18px;

		&-input {
			position: absolute;
			width: 0;
			height: 0;
			opacity: 0;
			overflow: hidden;
			z-index: -1;
		}

		&-action {
			color: #41c6eb;
		}
	}

	&__wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__title {
		font-size: 9px;
		margin-bottom: 6px;
		color: #9b9b9b;
	}

	&__error {
		position: absolute;
		left: 0;
		top: 100%;
		font-size: 9px;
		color: #ff8068;
	}

	&__footer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		button {
			width: 100%;
			margin-bottom: 10px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		@include mediaquery(sm) {
			flex-direction: row;

			button {
				margin-bottom: 0;

				&:first-of-type {
					width: 180px;
				}

				&:last-of-type {
					width: 110px;
				}

				&:only-child {
					width: 100%;
				}
			}
		}
	}
}
</style>
