<template>
	<div class="system__children">
		<div class="system__children-data">
			<perfect-scrollbar>
				<table class="system__children-table">
					<thead class="system__children-thead">
						<tr class="system__children-row">
							<th class="system__children-th system__children-th_date" data-id="date">
								<div class="system__children-wrapper">
									<div class="system__children-content">
										Дата
										<button class="system__children-sort" @click="sortTable">
											<span class="system__children-up"></span>
											<span class="system__children-down"></span>
										</button>
									</div>
								</div>
							</th>
							<th class="system__children-th system__children-th_contract">
								<div class="system__children-wrapper">
									<div class="system__children-content">Договор</div>
								</div>
							</th>
							<th class="system__children-th system__children-th_type" data-id="type">
								<div class="system__children-wrapper">
									<div class="system__children-content">
										Тип
										<button class="system__children-sort" @click="sortTable">
											<span class="system__children-up"></span><span class="system__children-down"></span>
										</button>
									</div>
								</div>
							</th>
							<th class="system__children-th">
								<div class="system__children-wrapper">
									<div class="system__children-content">Дилер</div>
								</div>
							</th>
							<th class="system__children-th system__children-th_name">
								<div class="system__children-wrapper">
									<div class="system__children-content">Имя объекта</div>
								</div>
							</th>
							<th class="system__children-th system__children-th_contacts">
								<div class="system__children-wrapper">
									<div class="system__children-content">Контакты</div>
								</div>
							</th>
							<th class="system__children-th system__children-th_state" data-id="state">
								<div class="system__children-wrapper">
									<div class="system__children-content">
										Статус
										<button class="system__children-sort" @click="sortTable">
											<span class="system__children-up"></span><span class="system__children-down"></span>
										</button>
									</div>
								</div>
							</th>
							<th class="system__children-th system__children-th_action">
								<div class="system__children-wrapper">
									<div class="system__children-content">Действия</div>
								</div>
							</th>
						</tr>
					</thead>
					<tbody class="system__children-tbody">
						<tr
							class="system__children-row"
							v-for="row in children.list"
							:id="row.id"
							:key="row.id"
							@click="selectChild($event, row.id)"
						>
							<td class="system__children-td system__children-td_date">
								<div class="system__children-wrapper">
									{{ convertDate(row.contract.date) }}
								</div>
							</td>
							<td class="system__children-td system__children-td_contract">
								<div class="system__children-wrapper">
									{{ row.contract.value }}
								</div>
							</td>
							<td class="system__children-td system__children-td_type">
								<div class="system__children-wrapper">
									<span
										:class="[
											row.type === 'pro'
												? 'system__children-type system__children-type_pro'
												: 'system__children-type'
										]"
										>{{ row.type }}</span
									>
								</div>
							</td>
							<td class="system__children-td">
								<div class="system__children-wrapper">Климат Тех</div>
							</td>
							<td class="system__children-td system__children-td_name">
								<div class="system__children-wrapper">
									{{ row.name }}
								</div>
							</td>
							<td class="system__children-td">
								<div class="system__children-wrapper">
									{{ row.contract.phone }}
								</div>
							</td>
							<td class="system__children-td">
								<div class="system__children-wrapper">
									<span :class="`system__children-state system__children-state_${row.state.value}`">
										{{ row.state.text }}</span
									>
								</div>
							</td>
							<td class="system__children-td">
								<button class="system__children-edit">
									<font-awesome-icon icon="fa-pen-to-square" />
								</button>
								<button class="system__children-delete">
									<font-awesome-icon icon="trash-can" />
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</perfect-scrollbar>
		</div>
		<Pagination @click="handlePagination" v-if="false" />
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import Pagination from "../layout/Pagination";

export default {
	name: "SystemChildren",
	components: {
		Pagination
	},
	computed: {
		...mapGetters({
			children: "getSystemChildren"
		})
	},
	methods: {
		...mapMutations(["createPopup", "createModal"]),
		...mapActions(["fetchSystem"]),
		convertDate(timestamp, type) {
			if (timestamp !== null) {
				let newDate = new Date(timestamp * 1000);
				let year = newDate.getFullYear();
				let month = newDate.getMonth() + 1;
				if (month < 10) month = "0" + month;
				let day = newDate.getDate();
				if (day < 10) day = "0" + day;
				let hour = newDate.getHours();
				if (hour < 10) hour = "0" + hour;
				let min = newDate.getMinutes();
				if (min < 10) min = "0" + min;
				let sec = newDate.getSeconds();
				if (sec < 10) sec = "0" + sec;

				if (type && type === "full") return `${day}.${month}.${year} ${hour}:${min}:${sec}`;
				else return `${day}.${month}.${year}`;
			}
		},
		selectChild(event, id) {
			if (event.target.closest(".system__children-delete")) {
				let payload = {
					type: "system",
					params: {
						page: "system",
						from_system: +event.target.closest(".system").getAttribute("data-id"),
						system: +event.target.closest(".system__children-row").id
					}
				};

				this.createPopup(payload);
			} else if (event.target.closest(".system__children-edit")) {
				let objects = this.children.list;

				let payload = {
					type: "object",
					action: "edit",
					params: {
						system: +event.target.closest(".system__children-row").id
					}
				};

				for (let i = 0; i < objects.length; i++) {
					if (objects[i].id === payload.params.system) {
						payload.params.type = objects[i].type;
						payload.params.control_channels = objects[i].control_channels;
						payload.params.name = objects[i].name;
						payload.params.pro_system_id = event.target.closest(".system").getAttribute("data-id");
						payload.params.date = objects[i].contract.date;
						payload.params.contract = objects[i].contract.value;
						payload.params.contractID = objects[i].contract.id;

						objects[i].from_users.forEach(user => {
							if (user.issuingAccess === true) {
								payload.params.email = user.email;
								payload.params.user = user.id;
							}
						});
					}
				}

				this.createModal(payload);
			} else {
				localStorage.setItem("system__previous-id", event.target.closest(".system").getAttribute("data-id"));
				localStorage.removeItem("system__navbar-current");

				this.$router.push(`/system/${id}`);
				this.fetchSystem(id);
			}
		},
		handlePagination(event) {
			if (event.target.closest(".pagination__button") || event.target.closest(".pagination__page")) {
				this.fetchSystemTasks();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.system {
	&__children {
		&-data {
			@include base;
		}

		.ps {
			width: 100%;
			padding: 0 10px 10px;
		}

		&-table {
			width: 100%;
			min-width: 1100px;
			border-collapse: collapse;
		}

		&-thead {
			.system__children-wrapper {
				min-height: 36px;
				border-right: 0;
			}
		}

		&-tbody {
			@include mediaquery(md) {
				.system__children-row {
					&:hover {
						cursor: pointer;
						outline: 2px solid #41c6eb;
					}
				}
			}
		}

		&-row {
			background-color: $colorMainLight;
		}

		&-th,
		&-td {
			padding: 8px 0;
			border-top: 1px solid #dddddd;
			border-bottom: 1px solid #dddddd;
			text-align: left;

			&:last-of-type {
				.system__children-wrapper {
					border-right: 0;
				}
			}
		}

		&-th {
			border: 0;
			padding: 10px 5px;

			&:first-of-type {
				padding-left: 0;
			}

			&:last-of-type {
				padding-right: 0;
			}

			&_date {
				width: 80px;
			}

			&_type {
				max-width: 80px;
			}

			&_name {
				max-width: 120px;
			}

			&_address {
				max-width: 200px;
			}

			&_contacts {
				width: 150px;
			}

			&_state {
				max-width: 140px;
			}

			&_action {
				max-width: 90px;
			}
		}

		&-td {
			position: relative;

			.system__children-wrapper {
				padding-left: 19px;
			}

			&:first-of-type {
				.system__children-wrapper {
					padding-left: 15px;
				}
			}

			&:last-of-type {
				text-align: center;
			}

			&_name {
				max-width: 100px;

				.system__children-wrapper {
					display: block;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					padding-right: 15px;
				}
			}

			&_address {
				max-width: 200px;

				.system__children-wrapper {
					display: block;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					padding-right: 15px;
				}
			}
		}

		&-wrapper {
			border-right: 1px solid #dddddd;
			font-weight: 400;
			font-size: 12px;
			color: #222325;
			display: flex;
			align-items: center;
		}

		&-content {
			@include base;

			padding: 7px 15px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 36px;
			width: 100%;
			text-align: center;
		}

		&-sort {
			position: relative;
			background: none;
			padding: 0;
			display: flex;
			border: 0;
			height: 16px;
			width: 17px;
			cursor: pointer;
			margin-left: 20px;
		}

		&-up {
			position: absolute;
			left: 5px;
			top: 2px;
			width: 7px;
			height: 7px;
			border: solid #bcbcbc;
			border-width: 1.5px 0 0 1.5px;
			transform: rotate(45deg);
		}

		&-down {
			position: absolute;
			left: 5px;
			bottom: 1px;
			width: 7px;
			height: 7px;
			border: solid #bcbcbc;
			border-width: 1.5px 0 0 1.5px;
			transform: rotate(225deg);
		}

		&-type {
			background-color: #41c6eb;
			color: #fff;
			border-radius: 10px;
			padding: 3px 10px;
			font-size: 10px;

			&_pro {
				background-color: #ffc96f;
				color: #222325;
			}
		}

		&-kind {
			background-color: #41c6eb;
			color: #fff;
			border-radius: 10px;
			padding: 3px 10px;
			font-size: 10px;

			&_error {
				background-color: #ff7156;
			}

			&_success {
				background-color: #7ed321;
			}
		}

		&-state {
			@include state;
			margin: 0;
			font-size: 11px;
			min-width: auto;
		}

		&-edit {
			font-size: 16px;
			color: #787878;
			padding: 0;
			margin: 0 5px;
			cursor: pointer;
			border: 0;
			background: none;

			@include mediaquery(md) {
				&:hover {
					color: #3c3c3c;
				}
			}
		}

		&-delete {
			font-size: 16px;
			color: #ff7156;
			padding: 0;
			margin: 0 5px;
			cursor: pointer;
			border: 0;
			background: none;

			@include mediaquery(md) {
				&:hover {
					color: #fc302e;
				}
			}
		}
	}
}
</style>
