<template>
  <main class="systems">
    <div class="systems__content">
      <div class="systems__header">
        <div class="systems__navbar">
          <ul class="systems__navbar-list">
            <li
              v-for="item in navbar.list"
              :class="[
                item.isActive
                  ? 'systems__navbar-item systems__navbar-item_current'
                  : 'systems__navbar-item',
              ]"
              :id="item.id"
              :key="item.id"
              @click="setActiveItem"
            >
              <span class="systems__navbar-quantity">{{ item.quantity }}</span>
              <div class="systems__navbar-info">
                <span class="systems__navbar-name">{{ item.name }}</span>
                <span class="systems__navbar-feature">{{ item.feature }}</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="systems__wrapper">
          <div class="systems__search">
            <input
              class="systems__search-input"
              type="text"
              :value="navbar.search.query"
              placeholder="Поиск"
              @input="changeSearchQuery"
              @keypress="searchObjects"
            />
            <span
              class="systems__search-clear"
              v-if="
                navbar.search.query !== null && navbar.search.query.length > 2
              "
              @click="clearSearch"
              >&times;</span
            >
            <button
              class="systems__search-button"
              :disabled="!navbar.search.validation"
              @click="searchObjects"
            >
              <font-awesome-icon icon="fa-magnifying-glass" />
            </button>
          </div>
          <Button
            :type="'text'"
            :value="'Создать объект'"
            :paint="'blue'"
            @click="createObject"
          />
        </div>
      </div>
      <div class="systems__data" data-id="objects" v-if="navbar.current === 1">
        <div class="systems__holder">
          <perfect-scrollbar>
            <table
              class="systems__table"
              :data-param="objects.sort.param"
              :data-order="objects.sort.order"
            >
              <thead class="systems__table-thead">
                <tr class="systems__table-row">
                  <th
                    class="systems__table-th systems__table-th_date"
                    data-id="date"
                  >
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">
                        Дата
                        <button class="systems__table-sort" @click="sortTable">
                          <span class="systems__table-up"></span>
                          <span class="systems__table-down"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                  <th class="systems__table-th systems__table-th_contract">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">Договор</div>
                    </div>
                  </th>
                  <th
                    class="systems__table-th systems__table-th_type"
                    data-id="type"
                  >
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">
                        Тип
                        <button class="systems__table-sort" @click="sortTable">
                          <span class="systems__table-up"></span
                          ><span class="systems__table-down"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">Дилер</div>
                    </div>
                  </th>
                  <th class="systems__table-th systems__table-th_name">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">Имя объекта</div>
                    </div>
                  </th>
                  <th
                    class="systems__table-th systems__table-th_address"
                    data-id="address"
                  >
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">
                        Адрес
                        <button class="systems__table-sort" @click="sortTable">
                          <span class="systems__table-up"></span
                          ><span class="systems__table-down"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                  <th class="systems__table-th systems__table-th_contacts">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">Контакты</div>
                    </div>
                  </th>
                  <th
                    class="systems__table-th systems__table-th_state"
                    data-id="state"
                  >
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">
                        Статус
                        <button class="systems__table-sort" @click="sortTable">
                          <span class="systems__table-up"></span
                          ><span class="systems__table-down"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                  <th class="systems__table-th systems__table-th_action">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">Действия</div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody class="systems__table-tbody">
                <tr
                  class="systems__table-row"
                  v-for="row in objects.list"
                  :id="row.id"
                  :key="row.id"
                  @click="selectSystem($event, row.id)"
                >
                  <td class="systems__table-td systems__table-td_date">
                    <div class="systems__table-wrapper">
                      {{ convertDate(row.contract.date) }}
                    </div>
                  </td>
                  <td class="systems__table-td systems__table-td_contract">
                    <div class="systems__table-wrapper">
                      {{ row.contract.value }}
                    </div>
                  </td>
                  <td class="systems__table-td systems__table-td_type">
                    <div class="systems__table-wrapper">
                      <span
                        :class="[
                          row.type === 'pro'
                            ? 'systems__table-type systems__table-type_pro'
                            : 'systems__table-type',
                        ]"
                        >{{ row.type }}</span
                      >
                    </div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">Климат Тех</div>
                  </td>
                  <td class="systems__table-td systems__table-td_name">
                    <div class="systems__table-wrapper">{{ row.name }}</div>
                  </td>
                  <td class="systems__table-td systems__table-td_address">
                    <div class="systems__table-wrapper">
                      {{ row.address }}
                    </div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">
                      {{ row.contract.phone }}
                    </div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">
                      <span
                        :class="`systems__table-state systems__table-state_${row.state.value}`"
                      >
                        {{ row.state.text }}</span
                      >
                    </div>
                  </td>
                  <td class="systems__table-td">
                    <button class="systems__table-edit">
                      <font-awesome-icon icon="fa-pen-to-square" />
                    </button>
                    <button class="systems__table-delete">
                      <font-awesome-icon icon="trash-can" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </perfect-scrollbar>
        </div>
        <Pagination v-if="true" @click="handlePagination" />
      </div>
      <div class="systems__data" data-id="tasks" v-if="navbar.current === 2">
        <div class="systems__holder">
          <perfect-scrollbar>
            <table class="systems__table">
              <thead class="systems__table-thead">
                <tr class="systems__table-row">
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">
                        Срок
                        <button class="systems__table-sort">
                          <span class="systems__table-up"></span
                          ><span class="systems__table-down"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">Номер задачи</div>
                    </div>
                  </th>
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <Dropdown
                        :placeholder="'Тип задачи'"
                        :value="'Тип задачи'"
                      />
                    </div>
                  </th>
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">Договор</div>
                    </div>
                  </th>
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">Тип</div>
                    </div>
                  </th>
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">
                        Адрес
                        <button class="systems__table-sort">
                          <span class="systems__table-up"></span
                          ><span class="systems__table-down"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">Исполнитель</div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody class="systems__table-tbody">
                <tr
                  class="systems__table-row"
                  v-for="row in tasks.list"
                  :id="row.id"
                  :key="row.id"
                  @click="selectSystem(row.id)"
                >
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">{{ row.period }}</div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">{{ row.number }}</div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">
                      <span
                        :class="[
                          row.kind.name === 'default'
                            ? `systems__table-kind`
                            : `systems__table-kind systems__table-kind_${row.kind.name}`,
                        ]"
                        >{{ row.kind.text }}</span
                      >
                    </div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">{{ row.contract }}</div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">
                      <span
                        :class="[
                          row.type === 'pro'
                            ? 'systems__table-type systems__table-type_pro'
                            : 'systems__table-type',
                        ]"
                        >{{ row.type }}</span
                      >
                    </div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">{{ row.address }}</div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">{{ row.executor }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </perfect-scrollbar>
        </div>
      </div>
      <div class="systems__data" data-id="personal" v-if="navbar.current === 3">
        <div class="systems__holder">
          <perfect-scrollbar>
            <table class="systems__table">
              <thead class="systems__table-thead">
                <tr class="systems__table-row">
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">
                        Срок
                        <button class="systems__table-sort">
                          <span class="systems__table-up"></span
                          ><span class="systems__table-down"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">Номер задачи</div>
                    </div>
                  </th>
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">
                        Тип задачи
                        <button class="systems__table-sort">
                          <span class="systems__table-up"></span
                          ><span class="systems__table-down"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">Договор</div>
                    </div>
                  </th>
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">Тип</div>
                    </div>
                  </th>
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">
                        Адрес
                        <button class="systems__table-sort">
                          <span class="systems__table-up"></span
                          ><span class="systems__table-down"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">Статус</div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody class="systems__table-tbody">
                <tr
                  class="systems__table-row"
                  v-for="row in personal.list"
                  :id="row.id"
                  :key="row.id"
                  @click="selectSystem(row.id)"
                >
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">{{ row.period }}</div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">{{ row.number }}</div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">
                      <span
                        :class="[
                          row.kind.name === 'default'
                            ? `systems__table-kind`
                            : `systems__table-kind systems__table-kind_${row.kind.name}`,
                        ]"
                        >{{ row.kind.text }}</span
                      >
                    </div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">{{ row.contract }}</div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">
                      <span
                        :class="[
                          row.type === 'pro'
                            ? 'systems__table-type systems__table-type_pro'
                            : 'systems__table-type',
                        ]"
                        >{{ row.type }}</span
                      >
                    </div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">{{ row.address }}</div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">
                      <span
                        :class="[
                          row.status.name === 'default'
                            ? `systems__table-status`
                            : `systems__table-status systems__table-status_${row.status.name}`,
                        ]"
                        >{{ row.status.text }}</span
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </perfect-scrollbar>
        </div>
      </div>
      <div class="systems__data" data-id="staff" v-if="navbar.current === 4">
        <div class="systems__holder">
          <perfect-scrollbar>
            <table class="systems__table">
              <thead class="systems__table-thead">
                <tr class="systems__table-row">
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">
                        Компания
                        <button class="systems__table-sort">
                          <span class="systems__table-up"></span
                          ><span class="systems__table-down"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">
                        Исполнитель
                        <button class="systems__table-sort">
                          <span class="systems__table-up"></span
                          ><span class="systems__table-down"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">Тип</div>
                    </div>
                  </th>
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">
                        Город
                        <button class="systems__table-sort">
                          <span class="systems__table-up"></span
                          ><span class="systems__table-down"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">
                        Телефон
                        <button class="systems__table-sort">
                          <span class="systems__table-up"></span
                          ><span class="systems__table-down"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">
                        Эл.почта
                        <button class="systems__table-sort">
                          <span class="systems__table-up"></span
                          ><span class="systems__table-down"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody class="systems__table-tbody">
                <tr
                  class="systems__table-row"
                  v-for="row in staff.list"
                  :id="row.id"
                  :key="row.id"
                  @click="selectSystem(row.id)"
                >
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">{{ row.company }}</div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">{{ row.executor }}</div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">
                      <span
                        :class="[
                          row.type.name === 'pro'
                            ? `systems__table-type systems__table-type_pro`
                            : `systems__table-type`,
                        ]"
                        >{{ row.type.text }}</span
                      >
                    </div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">{{ row.city }}</div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">{{ row.phone }}</div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">{{ row.email }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </perfect-scrollbar>
        </div>
      </div>
      <div
        class="systems__data"
        data-id="applications"
        v-if="navbar.current === 5"
      >
        <div class="systems__holder">
          <perfect-scrollbar>
            <table class="systems__table">
              <thead class="systems__table-thead">
                <tr class="systems__table-row">
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">
                        Дата
                        <button class="systems__table-sort">
                          <span class="systems__table-up"></span
                          ><span class="systems__table-down"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">
                        Договор
                        <button class="systems__table-sort">
                          <span class="systems__table-up"></span
                          ><span class="systems__table-down"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">Тип</div>
                    </div>
                  </th>
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">
                        Комментарий
                        <button class="systems__table-sort">
                          <span class="systems__table-up"></span
                          ><span class="systems__table-down"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">
                        Статус
                        <button class="systems__table-sort">
                          <span class="systems__table-up"></span
                          ><span class="systems__table-down"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                  <th class="systems__table-th">
                    <div class="systems__table-wrapper">
                      <div class="systems__table-content">
                        Действия
                        <button class="systems__table-sort">
                          <span class="systems__table-up"></span
                          ><span class="systems__table-down"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody class="systems__table-tbody">
                <tr
                  class="systems__table-row"
                  v-for="row in applications.list"
                  :id="row.id"
                  :key="row.id"
                  @click="selectSystem(row.id)"
                >
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">{{ row.date }}</div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">{{ row.contract }}</div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">
                      <span
                        :class="[
                          row.type.name === 'data'
                            ? `systems__table-type systems__table-type_data`
                            : `systems__table-type`,
                        ]"
                        >{{ row.type.text }}</span
                      >
                    </div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">{{ row.comment }}</div>
                  </td>
                  <td class="systems__table-td">
                    <div class="systems__table-wrapper">{{ row.status }}</div>
                  </td>
                  <td class="systems__table-td">
                    <button class="systems__table-delete">
                      <font-awesome-icon icon="trash-can" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </perfect-scrollbar>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from "vuex";

  import Pagination from "../layout/Pagination";

  import Button from "../components/Button";
  import Dropdown from "../components/Dropdown";

  export default {
    name: "Systems",
    components: {
      Pagination,
      Button,
      Dropdown,
    },
    computed: {
      ...mapGetters({
        navbar: "getSystemsNavbar",
        objects: "getSystemsObjects",
        tasks: "getSystemsTasks",
        personal: "getSystemsPersonal",
        staff: "getSystemsStaff",
        applications: "getSystemsApplications",
      }),
    },
    methods: {
      ...mapMutations([
        "setSystemsActiveNavbarItem",
        "changeSystemsObjectsSearch",
        "sortSystemsTable",
        "clearSystemsState",
        "createPopup",
        "createModal",
      ]),
      ...mapActions([
        "fetchSystemsObjects",
        "searchSystemsObjects",
        "clearSystemsObjects",
      ]),
      setActiveItem(event) {
        let item = event.target.closest(".systems__navbar-item");

        this.setSystemsActiveNavbarItem(item);
      },
      convertDate(timestamp) {
        let newDate = new Date(timestamp * 1000);
        let year = newDate.getFullYear();
        let month = newDate.getMonth() + 1;
        if (month < 10) month = "0" + month;
        let day = newDate.getDate();
        if (day < 10) day = "0" + day;
        let date = `${day}.${month}.${year}`;
        return date;
      },
      sortTable(event) {
        let payload = {
          tab: event.target.closest(".systems__data").getAttribute("data-id"),
          param: event.target
            .closest(".systems__table-th")
            .getAttribute("data-id"),
          order: event.target
            .closest(".systems__table")
            .getAttribute("data-order"),
        };

        this.sortSystemsTable(payload);
      },
      selectSystem(event, id) {
        if (event.target.closest(".systems__table-delete")) {
          let payload = {
            type: "system",
            params: {
              system: +event.target.closest(".systems__table-row").id,
            },
          };

          this.createPopup(payload);
        } else if (event.target.closest(".systems__table-edit")) {
          let objects = this.objects.list;

          let payload = {
            type: "object",
            action: "edit",
            params: {
              system: +event.target.closest(".systems__table-row").id,
            },
          };

          for (let i = 0; i < objects.length; i++) {
            if (objects[i].id === payload.params.system) {
              payload.params.type = objects[i].type;
              payload.params.control_channels = objects[i].control_channels;
              payload.params.address = objects[i].address;
              payload.params.name = objects[i].name;
              payload.params.phone = objects[i].contract.phone;
              payload.params.date = objects[i].contract.date;
              payload.params.contract = objects[i].contract.value;
              payload.params.contractID = objects[i].contract.id;
              payload.params.owner = "exist";

              objects[i].from_users.forEach((user) => {
                if (user.issuingAccess === true) {
                  payload.params.email = user.email;
                  payload.params.user = user.id;
                }
              });
            }
          }

          this.createModal(payload);
        } else {
          this.$router.push(`/system/${id}`);
          this.clearSystemsState();
        }
      },
      createObject() {
        let payload = {
          type: "object",
          params: {
            type: null,
            control_channels: null,
            pro_system_id: null,
            address: null,
            name: null,
            phone: null,
            date: null,
            contract: null,
            dealer: null,
            first_name: null,
            last_name: null,
            middle_name: null,
            username: null,
            owner: null,
            email: null,
            password: null,
          },
        };

        this.createModal(payload);
      },
      changeSearchQuery(event) {
        this.changeSystemsObjectsSearch(event.target.value);
      },
      searchObjects(event) {
        if (event.type === "keypress") {
          if (this.navbar.search.validation && event.key === "Enter") {
            this.searchSystemsObjects(this.navbar.search.query);
          }
        } else {
          this.searchSystemsObjects(this.navbar.search.query);
        }
      },
      handlePagination(event) {
        if (
          event.target.closest(".pagination__button") ||
          event.target.closest(".pagination__page")
        ) {
          this.fetchSystemsObjects();
        }
      },
      clearSearch() {
        this.clearSystemsObjects();
      },
    },
    created() {
      this.fetchSystemsObjects();
    },
  };
</script>

<style lang="scss" scoped>
  .systems {
    position: relative;
    width: 100%;

    @include mediaquery(sm) {
      width: calc(100% - 75px);
    }

    &__content {
      display: flex;
      flex-direction: column;
      padding-bottom: 50px;

      @include mediaquery(sm) {
        padding-bottom: 30px;
      }
    }

    &__header {
      width: 100%;
    }

    &__navbar {
      width: 100%;
      margin-bottom: 20px;

      &-list {
        padding: 0;
        margin: 0;
        list-style: none;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        @include mediaquery(md) {
          flex-direction: row;
          margin-left: -5px;
          margin-right: -5px;
          margin-top: -5px;
          margin-bottom: -10px;
          width: calc(100% + 10px);
        }
      }

      &-item {
        @include base;

        display: flex;
        width: 100%;
        border: 2px solid $colorMainLight;
        padding: 10px;
        margin-bottom: 10px;
        cursor: pointer;

        &:last-of-type {
          margin-bottom: 0;
        }

        @include mediaquery(md) {
          width: calc(50% - 10px);
          margin: 5px;

          &:last-of-type {
            margin-bottom: 5px;
          }
        }

        @include mediaquery(lg) {
          width: calc(33.333333% - 10px);
        }

        @include mediaquery(xl) {
          width: calc(25% - 10px);
        }

        &_current {
          border: 2px solid #41c6eb;

          .systems__navbar-quantity {
            color: #41c6eb;
            background-color: #fff;
          }
        }
      }

      &-quantity {
        width: 40px;
        height: 40px;
        border-radius: 2px;
        background-color: #dddddd;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
      }

      &-info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
      }

      &-name {
        font-weight: 500;
        font-size: 14px;
        color: #222325;
        text-transform: uppercase;
      }

      &-feature {
        margin-top: 5px;
        font-size: 10px;
        color: #9b9b9b;
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 20px;

      @include mediaquery(sm) {
        flex-direction: row;
      }
    }

    &__search {
      @include base;

      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;

      @include mediaquery(sm) {
        margin-bottom: 0;
        width: 300px;
      }

      @include mediaquery(md) {
        width: calc(50% - 5px);
      }

      @include mediaquery(lg) {
        width: calc(33.333333% - 7px);
      }

      @include mediaquery(xl) {
        width: calc(25% - 7px);
      }

      &-input {
        height: 36px;
        width: 100%;
        padding-left: 15px;
        background: none;
        border: transparent;
        font-family: "Avenir";
        font-size: 12px;

        &::placeholder {
          color: #9b9b9b;
        }
      }

      &-button {
        border: 0;
        height: 20px;
        width: 36px;
        min-width: 36px;
        border-left: 1px solid #ddd;
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.3s ease-in;

        &:disabled {
          cursor: auto;
          pointer-events: none;
          color: #9b9b9b;
        }
      }

      &-clear {
        color: #000;
        font-size: 16px;
        margin: 0 10px;
        cursor: pointer;
      }
    }

    &__create {
      background-color: #41c6eb;
      color: #fff;
      padding: 10px;
      border: 0;
      border-radius: 10px;
    }

    &__holder {
      @include base;
      min-height: 420px;
      max-height: 420px;
    }

    &__pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;

      &-button {
        @include base;

        position: relative;
        height: 36px;
        min-width: 36px;
        padding: 0;
        border: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child {
          .systems__pagination-icon {
            transform: rotate(135deg);
          }
        }

        &:last-child {
          .systems__pagination-icon {
            transform: rotate(-45deg);
          }
        }

        &_current {
          .systems__pagination-icon {
            border-color: #000;
          }
        }
      }

      &-icon {
        width: 7px;
        height: 7px;
        border: solid #bcbcbc;
        border-width: 0 2px 2px 0;
      }

      &-pages {
        margin: 0;
        padding: 0 10px;
        list-style: none;
        display: flex;
        align-items: center;
      }

      &-page {
        font-size: 14;
        margin: 0 5px;
        color: #9b9b9b;

        &_current {
          color: #000;
        }
      }
    }

    .ps {
      width: 100%;
      padding: 0 10px 10px;
    }

    &__table {
      width: 100%;
      min-width: 1100px;
      border-collapse: collapse;

      &-thead {
        .systems__table-wrapper {
          min-height: 36px;
          border-right: 0;
        }
      }

      &-tbody {
        @include mediaquery(md) {
          .systems__table-row {
            &:hover {
              cursor: pointer;
              outline: 2px solid #41c6eb;
            }
          }
        }
      }

      &-row {
        background-color: $colorMainLight;
      }

      &-th,
      &-td {
        padding: 8px 0;
        border-top: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        text-align: left;

        &:last-of-type {
          .systems__table-wrapper {
            border-right: 0;
          }
        }
      }

      &-th {
        border: 0;
        padding: 10px 5px;

        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
        }

        &_date {
          width: 80px;
        }

        &_type {
          max-width: 80px;
        }

        &_name {
          max-width: 120px;
        }

        &_address {
          max-width: 200px;
        }

        &_contacts {
          width: 150px;
        }

        &_state {
          max-width: 140px;
        }

        &_action {
          max-width: 90px;
        }
      }

      &-td {
        position: relative;

        .systems__table-wrapper {
          padding-left: 19px;
        }

        &:first-of-type {
          .systems__table-wrapper {
            padding-left: 15px;
          }
        }

        &:last-of-type {
          text-align: center;
        }

        &_name {
          max-width: 100px;

          .systems__table-wrapper {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 15px;
          }
        }

        &_address {
          max-width: 200px;

          .systems__table-wrapper {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 15px;
          }
        }
      }

      &-wrapper {
        border-right: 1px solid #dddddd;
        font-weight: 400;
        font-size: 12px;
        color: #222325;
        display: flex;
        align-items: center;
      }

      &-content {
        @include base;

        padding: 7px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 36px;
        width: 100%;
        text-align: center;
      }

      &-sort {
        position: relative;
        background: none;
        padding: 0;
        display: flex;
        border: 0;
        height: 16px;
        width: 17px;
        cursor: pointer;
        margin-left: 20px;
      }

      &-up {
        position: absolute;
        left: 5px;
        top: 2px;
        width: 7px;
        height: 7px;
        border: solid #bcbcbc;
        border-width: 1.5px 0 0 1.5px;
        transform: rotate(45deg);
      }

      &-down {
        position: absolute;
        left: 5px;
        bottom: 1px;
        width: 7px;
        height: 7px;
        border: solid #bcbcbc;
        border-width: 1.5px 0 0 1.5px;
        transform: rotate(225deg);
      }

      &-type {
        background-color: #41c6eb;
        color: #fff;
        border-radius: 10px;
        padding: 3px 10px;
        font-size: 10px;

        &_pro {
          background-color: #ffc96f;
          color: #222325;
        }
      }

      &-kind {
        background-color: #41c6eb;
        color: #fff;
        border-radius: 10px;
        padding: 3px 10px;
        font-size: 10px;

        &_error {
          background-color: #ff7156;
        }

        &_success {
          background-color: #7ed321;
        }
      }

      &-state {
        @include state;
        margin: 0;
        font-size: 11px;
        min-width: auto;
      }

      &-edit {
        font-size: 16px;
        color: #787878;
        padding: 0;
        margin: 0 5px;
        cursor: pointer;
        border: 0;
        background: none;

        @include mediaquery(md) {
          &:hover {
            color: #3c3c3c;
          }
        }
      }

      &-delete {
        font-size: 16px;
        color: #ff7156;
        padding: 0;
        margin: 0 5px;
        cursor: pointer;
        border: 0;
        background: none;

        @include mediaquery(md) {
          &:hover {
            color: #fc302e;
          }
        }
      }
    }
  }
</style>