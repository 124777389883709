<template>
  <div class="confirm">
    <div class="confirm__wrapper">
      <img class="confirm__logo" src="../assets/images/logo.svg" alt="" />
      <div class="confirm__title">Подтверждение почты</div>
      <form class="confirm__form" @submit.prevent>
        <div class="confirm__form-body">Ваша почта подтверждена!</div>
        <div class="confirm__form-footer">
          <Button
            :type="'text'"
            :value="'Понятно'"
            :paint="'blue'"
            @click="goToLogin"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import Button from "../components/Button";

  export default {
    name: "Confirm",
    components: {
      Button,
    },
    methods: {
      ...mapActions(["logoutAuthorization"]),
      goToLogin() {
        this.$router.push("/login");
      },
    },
    created() {
      this.logoutAuthorization();
    },
  };
</script>

<style lang="scss" scoped>
  .confirm {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% - 60px);

      @include mediaquery(sm) {
        width: 400px;
      }
    }

    &__logo {
      width: 50px;
      margin-bottom: 24px;

      @include mediaquery(sm) {
        margin-bottom: 44px;
      }
    }

    &__title {
      width: 100%;
      margin-bottom: 20px;
      text-transform: uppercase;
      text-align: center;
      font-size: 24px;
    }

    &__form {
      @include base;

      width: 100%;
      padding: 20px;

      @include mediaquery(sm) {
        padding: 46px 40px 25px;
      }

      &-body {
        text-align: center;
      }

      &-footer {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        @include mediaquery(sm) {
          flex-direction: row;
          justify-content: space-between;
          margin-top: 50px;
        }

        button {
          width: 100%;
        }
      }
    }
  }
</style>