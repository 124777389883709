import axios from 'axios';
import router from '@/router';

export default {
  state: {
    email: {
      type: 'email',
      name: "email",
      value: null,
      error: null,
      status: 'default',
    },
    validation: false,
  },
  getters: {
    getRecoverEmail: state => state.email,
    getRecoverValidation: state => state.validation,
  },
  mutations: {
    handleRecoverChanges(state, payload) {
      if (payload.event === 'change' || payload.event === 'paste' || payload.event === 'input') {
        let module = {
          state: state,
          payload: payload,
        }

        this.commit("handleValidation", module);
      }
    },
  },
  actions: {
    sendRecoverChanges(context) {
      context.commit("showPreloader");

      axios
        .post("https://api.airwet.app/api/auth/forgot-password", {
          email: context.state.email.value,
        })
        .then((response) => {
          context.commit("hidePreloader");

          if (response.status === 200) {
            router.push("/login");

            let payload = {
              type: "recover",
            };

            this.commit("createPopup", payload);
          }
        })
        .catch((error) => context.commit("handleError", error));
    }
  },
}