<template>
  <div class="popup" v-if="popup.visible" :data-type="popup.current.type">
    <div class="popup__window">
      <div class="popup__header">{{ popup.current.header }}</div>
      <div class="popup__body">{{ popup.current.body }}</div>
      <div class="popup__footer">
        <Button
          v-for="button in popup.current.footer"
          :id="button.id"
          :key="button.id"
          :type="button.type"
          :value="button.value"
          :paint="button.paint"
          :action="button.action"
          @click="handleClick(button.action)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from "vuex";
  import Button from "../components/Button";
  export default {
    name: "Popup",
    components: {
      Button,
    },
    computed: {
      ...mapGetters({
        popup: "getCommonPopup",
      }),
    },
    methods: {
      ...mapMutations(["destroyPopup"]),
      ...mapActions([
        "deleteProfileAvatar",
        "deleteProfileContact",
        "deleteProfileUser",
        "deleteProfileCard",
        "deleteSystemsObject",
        "deleteSystemDocument",
        "deleteSystemZone",
        "confirmSystemLogs",
        "deleteSystemEntrance",
      ]),
      handleClick(action) {
        if (action === "confirmLogs") {
          this.confirmSystemLogs(this.popup.params);
        }

        if (action === "deleteZone") {
          this.deleteSystemZone(this.popup.params);
        }

        if (action === "deleteEntrance") {
          this.deleteSystemEntrance(this.popup.params);
        }

        if (action === "deleteAvatar") {
          this.deleteProfileAvatar(this.popup.params);
        }

        if (action === "deleteContact") {
          this.deleteProfileContact(this.popup.params);
        }

        if (action === "deleteUser") {
          this.deleteProfileUser(this.popup.params);
        }

        if (action === "deleteSystem") {
          this.deleteSystemsObject(this.popup.params);
        }

        if (action === "deleteDocument") {
          this.deleteSystemDocument(this.popup.params);
        }

        if (action === "deleteCard") {
          this.deleteProfileCard(this.popup.params);
        }

        this.destroyPopup();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .popup {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    background-color: rgba(240, 240, 240, 0.8);
    z-index: $zIndexPopup;
    opacity: 1;

    &__window {
      @include base;

      position: relative;
      top: 100px;
      width: calc(100% - 60px);
      margin: 0 auto;
      padding: 20px;
      transform: scale(1);
      transition: transform 0.5s ease-in-out;

      @include mediaquery(sm) {
        width: 400px;
        padding: 50px;
      }
    }

    &__header {
      font-weight: 500;
      font-size: 14px;
      color: #2b1a0a;
      text-align: center;
    }

    &__body {
      font-size: 14px;
      margin: 20px 0;
      color: #2b1a0a;
      text-align: center;
    }

    &__footer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      button {
        width: 100%;
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      @include mediaquery(sm) {
        flex-direction: row;

        button {
          margin-bottom: 0;

          &:first-of-type {
            width: 180px;
          }

          &:last-of-type {
            width: 110px;
          }

          &:only-child {
            width: 100%;
          }
        }
      }
    }
  }
</style>
